import * as Sentry from '@sentry/react';
import AppRoutes from 'AppRoutes';
import AppWrapper from 'components/SubComponents/AppWrapper';
import ForbiddenPage from 'pages/forbidden';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppContext } from './auth';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App(): JSX.Element {
  return (
    <Router>
      <SentryRoutes>
        <Route
          path="/*"
          element={
            <AppContext>
              <AppWrapper>
                <AppRoutes />
              </AppWrapper>
            </AppContext>
          }
        />
        <Route path="/forbidden" element={<ForbiddenPage />} />
      </SentryRoutes>
    </Router>
  );
}

export default App;
