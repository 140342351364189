import { Close } from '@mui/icons-material';
import { humanizer } from 'helpers/string';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import styles from '../../styles/components/fields/selected-fields.module.scss';

export type Field = {
  field: string;
  format: DataFormatType;
  name: string;
  type?: string;
};

interface SelectedFieldsProps {
  onDragEnd: (result: DropResult) => void;
  onClickRemove: (e: any) => void;
  onSelectedFieldClick: (field: Field) => void;
  fields: string[];
}

const SelectedFields = ({
  onDragEnd,
  onClickRemove,
  onSelectedFieldClick,
  fields,
}: SelectedFieldsProps): JSX.Element => {
  function getItemStyle(
    isDragging: boolean,
    index: number,
    style?: DraggingStyle | NotDraggingStyle
  ): React.CSSProperties {
    return {
      userSelect: 'none',
      ...style,
    };
  }

  return (
    <div className={styles.selectedFields}>
      <h2>Selected Fields:</h2>
      <div className={styles.fields}>
        <DragDropContext onDragEnd={onDragEnd}>
          {typeof fields !== 'undefined' && fields.length ? (
            <Droppable droppableId="droppable">
              {(provided: any, snapshot: any) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.droppable}
                >
                  {fields.map((valueField: any, index: number) => (
                    <div
                      style={{ backgroundColor: 'transparent' }}
                      key={`${valueField.name.toLowerCase()}`}
                    >
                      <Draggable
                        draggableId={`${valueField.field}-id`}
                        index={index}
                      >
                        {(_provided: any, _snapshot: any) => (
                          <div
                            data-field={valueField.field}
                            data-name={valueField.name}
                            data-source="value"
                            data-type={valueField.type}
                            key={index}
                            ref={_provided.innerRef}
                            {..._provided.draggableProps}
                            {..._provided.dragHandleProps}
                            style={getItemStyle(
                              _snapshot.isDragging,
                              index,
                              _provided.draggableProps.style
                            )}
                          >
                            <span
                              // Providing the item you want to deal with onClick
                              // in context avoids having to find it in the click handler
                              onClick={() => onSelectedFieldClick(valueField)}
                            >
                              {humanizer(valueField.field)}
                            </span>
                            <span onClick={onClickRemove}>
                              <Close />
                            </span>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : null}
        </DragDropContext>
      </div>
    </div>
  );
};

export default SelectedFields;
