import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { generateRandomString, humanizer } from 'helpers/string';
import useChartConfig from 'hooks/useChartConfig';

import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/query-list.module.scss';

interface QueryListProps {
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
}

const QueryList = ({ onChange, value }: QueryListProps): JSX.Element => {
  const { getQueryList: graphqlQueryList } = useChartConfig();

  const randomString = generateRandomString();

  return (
    <div className={styles.queryList}>
      {graphqlQueryList.queries.length ? (
        <FormControl variant="outlined" className={muStyles.select}>
          <label htmlFor={`query-list-selection-${randomString}`}>
            Query List:
          </label>{' '}
          <Select
            native
            value={value}
            onChange={onChange}
            inputProps={{
              name: `query-list-selection-${randomString}`,
              id: `query-list-selection-${randomString}`,
            }}
          >
            <option value="0">Select Query</option>
            {graphqlQueryList.queries.map((queryName, index) => {
              return (
                <option key={index} value={queryName}>
                  {humanizer(queryName)}
                </option>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </div>
  );
};

export default QueryList;
