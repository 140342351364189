import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import styles from '../../styles/components/sidebar/side-nav.module.scss';
import Drawer from './Drawer';
import ProductLink from './ProductLink';

const SideNav = (): JSX.Element => {
  const { hideNav } = useSelector((state: RootState) => state.query);
  const accessibleProducts = useSelector(
    (state: RootState) => state.product.accessibleDashboardProducts!
  );

  const location = useLocation();
  const currentProductName = location.pathname.split('/')[1];

  return (
    <>
      {!hideNav ? (
        <section className={styles.sideNav}>
          <nav>
            <ul>
              {accessibleProducts.map(
                (product: SFDashboardProduct, index: number) => {
                  return (
                    <li key={index}>
                      <ProductLink
                        active={product.code === currentProductName}
                        product={product.code}
                        toolTipClass={styles.toolTip}
                      />
                    </li>
                  );
                }
              )}
            </ul>
          </nav>
          <Drawer />
        </section>
      ) : null}
    </>
  );
};

export default SideNav;
