import { CssBaseline } from '@mui/material';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { addThemeClass, removeThemeClass } from '../helpers/classes';
import { RootState } from '../store';

interface SFThemeProviderProps {
  children: JSX.Element;
}

/**
 * Provides many CSS defaults and theme classes.
 */
const ThemeProvider: React.FC<SFThemeProviderProps> = ({ children }) => {
  const selectedTheme = useSelector(
    (state: RootState) => state.theme.selectedTheme
  );

  useLayoutEffect(() => {
    removeThemeClass();
    if (selectedTheme) {
      addThemeClass(selectedTheme);
    }
  }, [selectedTheme]);

  // CssBaseline sets a bunch of CSS defaults for UI, a lot like normalize.css
  // https://mui.com/components/css-baseline/
  // https://github.com/necolas/normalize.css
  return <CssBaseline>{children}</CssBaseline>;
};

export default ThemeProvider;
