import { createSelector } from '@reduxjs/toolkit';
import deepEqual from 'deep-equal';
import { RootState } from './../index';

/** Select the current dashboard ***being displayed to the user***.
 * If not editing/creating a dashboard, this will be the currently
 * selected dashboard (by id). If editing a dashboard, this
 * will be a mutable copy of the source dashboard being edited. If
 * creating a new dashboard, this will be a new dashboard object
 * altogether. */
const selectCurrentDashboard = createSelector(
  (state: RootState) => state.dashboard,
  (dashboardState) => {
    const { dashboards, currentDashboardId, isEditing, isCreating } =
      dashboardState;

    // If there are no dashboards and one isn't being currently created,
    // there is no current dashboard
    if ((dashboards === null || dashboards.length === 0) && !isCreating) {
      return null;
    }

    if (isEditing || isCreating) {
      return dashboardState.dashboardDiff;
    }

    return (
      dashboards?.find((_dashboard) => _dashboard.id === currentDashboardId) ||
      null
    );
  }
);

const selectDashboardsForProduct = createSelector(
  [
    (state: RootState) => state.dashboard.dashboards,
    (state: RootState, productCode: ProductCode) => productCode,
  ],
  (dashboards, productCode) => {
    if (dashboards === null) {
      return [];
    }

    return dashboards.filter(
      (dashboard) =>
        dashboard.productCode === productCode ||
        dashboard.productName.toLowerCase() === productCode
    );
  }
);
/** Return boolean for whether or not edits have been made to the current/new dashboard */
const selectDashboardHasBeenEdited = createSelector(
  (state: RootState) => state.dashboard,
  (dashboardState) => {
    const {
      isEditing,
      isCreating,
      dashboardDiff,
      dashboards,
      currentDashboardId,
    } = dashboardState;

    // If editing, this is the original, immutable dashboard. If creating,
    // the same is true, it's just irrelevant.
    const currentSourceDashboard =
      dashboards?.find((_dashboard) => _dashboard.id === currentDashboardId) ||
      null;

    if (isEditing && currentSourceDashboard === null) {
      throw new Error('Cannot edit when current dashboard is null.');
    }

    if (dashboardDiff === null) {
      return false;
    }

    if (isEditing) {
      // True if dashboardDiff is not deeply-equal to currentDashboard
      return !deepEqual(currentSourceDashboard, dashboardDiff);
    }

    if (isCreating) {
      // New dashboard has been edited if components have been added
      return dashboardDiff.layout.length > 0;
    }

    return false;
  }
);

const selectUserDashboards = createSelector(
  (state: RootState) => state.dashboard,
  (dashboardState) => {
    const { dashboards } = dashboardState;

    if (dashboards === null) {
      return [];
    }

    return dashboards.filter((dashboard) => dashboard.scope === 'user') || [];
  }
);

const selectSharedDashboards = createSelector(
  (state: RootState) => state.dashboard,
  (dashboardState) => {
    const { dashboards } = dashboardState;

    if (dashboards === null) {
      return [];
    }

    return dashboards.filter((dashboard) => dashboard.scope === 'shared') || [];
  }
);

const selectDashboardItem = createSelector(
  [
    selectCurrentDashboard,
    (state: RootState, dashboardItemID: Maybe<string>) => dashboardItemID,
  ],
  (
    currentDashboard: Maybe<SFDashboard | SFDashboardInit>,
    itemID: Maybe<string>
  ) => {
    if (currentDashboard === null || itemID === null) {
      return null;
    }

    const layout = currentDashboard.layout;

    if (layout.length === 0) {
      return null;
    }

    const dashboardItem = layout.find((item) => item.id === itemID);

    if (dashboardItem === undefined) {
      return null;
    }

    return dashboardItem;
  }
);

export {
  selectCurrentDashboard,
  selectDashboardsForProduct,
  selectDashboardHasBeenEdited,
  selectSharedDashboards,
  selectUserDashboards,
  selectDashboardItem,
};
