import { Spinner } from 'auth';
import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export,
  Font,
  Label,
  Legend,
  Series,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import { renameObjectKey } from 'helpers/object';
import useColorPalette from 'hooks/useColorPalette';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { monthListShort } from '../../../helpers/date';
import { removeHoverHashing } from '../../../helpers/hashing';
import { formatToDollar } from '../../../helpers/format';
import { PaletteConfig } from '../../../palette';
import { RootState } from '../../../store';

import styles from '../../../styles/components/charts/range-inventory.module.scss';
import '../../../styles/dev-extreme/select-box.scss';

const YearlyQuantity = ({
  redrawKey,
}: DashboardComponentProps): JSX.Element => {
  const calendar = useSelector((state: RootState) => state.calendar);
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);

  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );
  // const currentDashboard = useSelector(selectCurrentDashboard);
  // const layoutKey = currentDashboard?.layoutHash || '';

  const [chartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    argField: 'month',
    month: calendar.props.month,
    query: 'monthlySalesOld',
    week: calendar.props.week,
    valueFields: [
      { field: 'tyRetail' },
      { field: 'lyRetail' },
      { field: 'tyRetailOnHand' },
      { field: 'lyRetailOnHand' },
    ],
  });

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendar.loaded && retailerLoaded) {
      setDataQuery(chartSettings);
    }
  }, [calendar.loaded, retailerLoaded, chartSettings]);

  /**
   * Set data here.
   */
  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    let updatedData: any[] = [];
    data.forEach((dataItem: any) => {
      const object = renameObjectKey(chartSettings.argField, 'arg', dataItem);
      object.arg = monthListShort[object.arg - 1];
      updatedData = [...updatedData, object];
    });

    setChartData(updatedData);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId]);

  const customizeText = (amount: any): string => {
    return formatToDollar(amount.value);
  };

  const customizeToolTip = (arg: any): CustomToolTipType => {
    return {
      text: `<div>${formatToDollar(arg.valueText)}</div>`,
    };
  };

  const colorPalette = useMemo(() => {
    return themePalette.barChart as PaletteConfig;
  }, [themePalette]);

  return (
    <div className={styles.rangeInventory}>
      <div className={styles.heading}>
        <h2>Yearly Quantity</h2>
      </div>

      {!loading ? (
        hasData ? (
          <Chart
            id="yearly-quantity-chart"
            key={redrawKey}
            dataSource={chartData}
            customizePoint={removeHoverHashing}
            rotated={false}
            animation={animate}
            palette={colorPalette.chart}
          >
            <CommonAxisSettings allowDecimals={false} />
            <CommonSeriesSettings
              argumentField="arg"
              type="bar"
              hoverMode="allArgumentPoints"
              selectionMode="allArgumentPoints"
            />
            <Series
              type="bar"
              argumentField="arg"
              axis="onHand"
              valueField="tyRetailOnHand"
              name="OnHand TY"
            />
            <Series
              type="bar"
              argumentField="arg"
              axis="onHand"
              valueField="lyRetailOnHand"
              name="OnHand LY"
            />

            <Series
              type="line"
              argumentField="arg"
              axis="sales"
              valueField="lyRetail"
              name="Sales LY"
              width={4}
            />
            <Series
              type="line"
              argumentField="arg"
              axis="sales"
              valueField="tyRetail"
              name="Sales TY"
              width={4}
            />

            <ArgumentAxis>
              <Tick visible={false} />
              <Label>
                <Font color={colorPalette.font} weight={700} />
              </Label>
            </ArgumentAxis>

            <ValueAxis
              name="sales"
              position="right"
              valueMarginsEnabled={false}
            >
              <Title text="Sales (Line Chart)">
                <Font color={colorPalette.secondaryFont} />
              </Title>
              <Label customizeText={customizeText}>
                <Font color={colorPalette.font} weight={700} />
              </Label>
              <Tick visible={false} />
            </ValueAxis>

            <ValueAxis name="onHand" showZero={true} position="left">
              <Title text="On Hand Dollars (Bar Chart)">
                <Font color={colorPalette.secondaryFont} />
              </Title>
              <Label customizeText={customizeText}>
                <Font color={colorPalette.font} weight={700} />
              </Label>
              <Tick visible={false} />
            </ValueAxis>

            <Legend visible={false} />
            <Export enabled={false} />
            <Tooltip
              enabled={true}
              location="edge"
              customizeTooltip={customizeToolTip}
            />
          </Chart>
        ) : (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )
      ) : (
        <Spinner active={true} background={'clear'} />
      )}
    </div>
  );
};

export default YearlyQuantity;
