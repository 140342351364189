import db from 'data/db';
import PageWrapper from 'pages/PageWrapper';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { setOpen } from 'store/slices/sidebarSlice';
import useAccessibleProducts from 'hooks/loaders/useAccessibleProducts';

function AppRoutes(): JSX.Element {
  const defaultPath = 'analyze';
  const accessibleDashboardProducts = useAccessibleProducts();

  const dispatch = useDispatch();
  const loadedRef = useRef(false);

  /**
   * Load dexie.js settings and set redux state...
   */
  const loadSettingsData = useCallback(async (): Promise<void> => {
    db.loadIndexDb();
    const drawerOpen = await db.settings.where({ name: 'drawerOpen' }).first();

    // Set Drawer Open State
    const isOpen =
      typeof drawerOpen?.storage === 'undefined' ? true : drawerOpen?.storage;
    dispatch(setOpen(isOpen));
  }, []);

  useLayoutEffect(() => {
    if (loadedRef.current === true) return;

    const loadSettings = async (): Promise<void> => {
      await loadSettingsData();
    };
    loadSettings();
    return () => {
      loadedRef.current = true;
    };
  }, [loadSettingsData, loadedRef]);

  return (
    <Routes>
      {accessibleDashboardProducts.products !== null &&
        accessibleDashboardProducts.products.map((product) => (
          <Route
            key={product.code}
            path={`/${product.code}`}
            element={<PageWrapper product={product} />}
          />
        ))}
      <Route
        path="*"
        element={
          <Navigate to={`/${defaultPath}${window.location.search}`} replace />
        }
      />
    </Routes>
  );
}

export default AppRoutes;
