import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ThemeState {
  selectedTheme: ProductCode;
  title: ProductName;
}

const initialState: ThemeState = {
  selectedTheme: 'analyze',
  title: 'ANALYZE',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeState: (state, action: PayloadAction<ThemeState>) => {
      const { selectedTheme, title } = action.payload;
      state.title = title;
      state.selectedTheme = selectedTheme;
    },
  },
});

export const { setThemeState } = themeSlice.actions;
export default themeSlice.reducer;
