import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Spinner } from 'auth';
import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  Export,
  Font,
  Label,
  Legend,
  Series,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import { renameObjectKey } from 'helpers/object';
import useColorPalette from 'hooks/useColorPalette';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { monthList } from '../../../helpers/date';
import { removeHoverHashing } from '../../../helpers/hashing';
import { formatToDollar } from '../../../helpers/format';
import { PaletteConfig } from '../../../palette';
import { RootState } from '../../../store';

import styles from '../../../styles/components/charts/range-inventory.module.scss';
import '../../../styles/dev-extreme/select-box.scss';

const argumentTitleText = 'Days of Month';

const MonthlyQuantity = ({
  redrawKey,
}: DashboardComponentProps): JSX.Element => {
  const calendar = useSelector((state: RootState) => state.calendar);
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [displayDateBackBtn, setDisplayDateBackBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  );

  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );
  // const currentDashboard = useSelector(selectCurrentDashboard);
  // const layoutKey = currentDashboard?.layoutHash || '';

  const [chartSettings, setChartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    argField: 'dayOfMonth',
    month: calendar.props.month,
    query: 'monthlySalesByDayOld',
    week: calendar.props.week,
    valueFields: [
      { field: 'tyRetail' },
      { field: 'lyRetail' },
      { field: 'tyRetailOnHand' },
      { field: 'lyRetailOnHand' },
    ],
  });

  /**
   * Handles forward button for moving date to next month.
   */
  const dateForward: MouseEventHandler<HTMLElement> = useCallback(() => {
    if (chartSettings.month === null) return;

    setLoading(true);
    setAnimate(true);

    let nextMonth: number = chartSettings.month + 1;
    nextMonth = nextMonth <= 12 ? nextMonth : 1;

    setChartSettings({
      ...chartSettings,
      month: nextMonth,
    });
  }, [chartSettings]);

  /**
   * Handles back button for moving date to previous sales.
   */
  const dateBack: MouseEventHandler<HTMLElement> = useCallback(() => {
    if (chartSettings.month === null) return;

    setLoading(true);
    setAnimate(true);

    let prevMonth: number = chartSettings.month - 1;
    prevMonth = prevMonth !== 0 ? prevMonth : 12;

    setChartSettings({
      ...chartSettings,
      month: prevMonth,
    });
  }, [chartSettings]);

  /**
   * Handles returning back to todays month.
   */
  const displayTodaysMonth: MouseEventHandler<HTMLElement> = useCallback(() => {
    setAnimate(true);
    setLoading(true);
    setChartSettings({
      ...chartSettings,
      month: currentMonth,
    });
  }, [currentMonth]);

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendar.loaded && retailerLoaded) {
      setDataQuery(chartSettings);
    }
  }, [calendar.loaded, retailerLoaded, chartSettings]);

  /**
   * Set data here.
   */
  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    setDisplayDateBackBtn(currentMonth !== chartSettings.month);

    let updatedData: any[] = [];
    data.forEach((dataItem: any) => {
      const object = renameObjectKey(chartSettings.argField, 'arg', dataItem);
      object.month = chartSettings.month
        ? monthList[chartSettings.month - 1]
        : null;
      updatedData = [...updatedData, object];
    });

    setChartData(updatedData);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId]);

  useEffect(() => {
    if (calendar.loaded) {
      setMonth(calendar.props.month);
      setCurrentMonth(calendar.props.month);
    }
  }, [calendar]);

  const customizeText = (amount: any): string => {
    return formatToDollar(amount.value);
  };

  const customizeToolTip = (arg: any): CustomToolTipType => {
    return {
      text: `<div>${formatToDollar(arg.valueText)}</div>`,
    };
  };

  const colorPalette = useMemo(() => {
    return themePalette.barChart as PaletteConfig;
  }, [themePalette]);

  return (
    <div className={styles.rangeInventory}>
      <div className={styles.heading}>
        <h2>
          Monthly Quantity<span>{monthList[month - 1]}</span>
        </h2>

        <div className={styles.controls}>
          {displayDateBackBtn ? (
            <div className={styles.today}>
              <button onClick={displayTodaysMonth}>
                Return to Current Month
              </button>
            </div>
          ) : null}

          <div className={styles.arrows}>
            <button
              onClick={dateBack}
              disabled={isLoading}
              className={isLoading ? styles.disabled : ''}
            >
              <ArrowBackIos />
            </button>
            <button
              onClick={dateForward}
              disabled={isLoading}
              className={isLoading ? styles.disabled : ''}
            >
              <ArrowForwardIos />
            </button>
          </div>
        </div>
      </div>

      {!loading ? (
        hasData ? (
          <Chart
            id="monthly-quantity-chart"
            key={redrawKey}
            dataSource={chartData}
            customizePoint={removeHoverHashing}
            rotated={false}
            animation={animate}
            palette={colorPalette.chart}
          >
            <CommonAxisSettings allowDecimals={false} />
            <Series
              type="bar"
              argumentField="arg"
              axis="onHand"
              valueField="tyRetailOnHand"
              name="OnHand TY"
            />
            <Series
              type="bar"
              argumentField="arg"
              axis="onHand"
              valueField="lyRetailOnHand"
              name="OnHand LY"
            />

            <Series
              type="line"
              argumentField="arg"
              axis="sales"
              valueField="lyRetail"
              name="Sales LY"
              width={4}
            />
            <Series
              type="line"
              argumentField="arg"
              axis="sales"
              valueField="tyRetail"
              name="Sales TY"
              width={4}
            />

            <ArgumentAxis>
              <Tick visible={false} />
              <Title text={argumentTitleText}>
                <Font color={colorPalette.secondaryFont} size={12} />
              </Title>
              <Label>
                <Font color={colorPalette.font} weight={700} />
              </Label>
            </ArgumentAxis>

            <ValueAxis
              name="sales"
              position="right"
              valueMarginsEnabled={false}
            >
              <Title text="Sales (Line Chart)">
                <Font color={colorPalette.secondaryFont} />
              </Title>
              <Label customizeText={customizeText}>
                <Font color={colorPalette.font} weight={700} />
              </Label>
              <Tick visible={false} />
            </ValueAxis>

            <ValueAxis name="onHand" showZero={true} position="left">
              <Title text="On Hand Dollars (Bar Chart)">
                <Font color={colorPalette.secondaryFont} />
              </Title>
              <Label customizeText={customizeText}>
                <Font color={colorPalette.font} weight={700} />
              </Label>
              <Tick visible={false} />
            </ValueAxis>

            <Legend visible={false} />
            <Export enabled={false} />
            <Tooltip
              enabled={true}
              location="edge"
              customizeTooltip={customizeToolTip}
            />
          </Chart>
        ) : (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )
      ) : (
        <Spinner active={true} background={'clear'} />
      )}
    </div>
  );
};

export default MonthlyQuantity;
