import { Paper } from '@mui/material';
import { useState } from 'react';
import styles from '../../styles/components/charts/production.module.scss';

const Production = (): JSX.Element => {
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-t-large',
  ]);

  return (
    <div className={styles.production}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <div className={styles.heading}>
          <h2>Weekly Production</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.topDisplay}>
            <div className={styles.amount}>
              <p>
                <span>859</span>
                <span>/</span>
                <span>wk</span>
              </p>
            </div>
            <div className={styles.indicator}>
              <div className={styles.up}></div>
            </div>
          </div>
          <div className={styles.bottomDisplay}>
            <div className={styles.total}>
              <p>
                <span>3.67k</span>
                <span>total</span>
              </p>
            </div>
            <span className={styles.divider}></span>
            <div className={styles.remaining}>
              <p>
                <span>19.9k</span>
                <span>remaining</span>
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Production;
