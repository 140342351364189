import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { generateRandomString, humanizer } from 'helpers/string';
import { useMemo, useState } from 'react';
import MonthSelect from '../MonthSelect';
import WeekDaySelect from '../WeekDaySelect';

import muStyles from '../../../styles/material-ui/select.module.scss';
import styles from '../../../styles/components/fields/filter-selection.module.scss';

interface FilterSelectionProps {
  filters: Maybe<string[]>;
  name: string;
  onConditionChange: (event: any, conditionValue: Condition) => void;
  onSelectChange: (event: SelectChangeEvent<string>) => void;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => void;
  conditionValue: string;
  selectValue: Maybe<string>;
  inputValue: Maybe<string>;
}

const FilterSelection = ({
  filters,
  name,
  onConditionChange,
  onSelectChange,
  onInputChange,
  conditionValue,
  selectValue,
  inputValue,
}: FilterSelectionProps): JSX.Element => {
  const randomString = generateRandomString();
  const conditionalSelectors = ['and', 'or'];
  const conditionValues = [
    'equal',
    'notEqual',
    'greaterThan',
    'lessThan',
    'greaterThanOrEqual',
    'lessThanOrEqual',
  ];

  /**
   * Change the condition value base on click event.
   */
  function onConditionValueChange(event: any): void {
    const currentCondition = event.target.dataset.type;
    const index = conditionValues.indexOf(currentCondition);
    const updatedIndex = index === 5 ? 0 : index + 1;
    const newCondition = conditionValues[updatedIndex] as Condition;

    onConditionChange(event, newCondition);
  }

  function filterInput(): JSX.Element {
    const componentName = `${name}-input`;
    const disabled = selectValue === null || selectValue === '0';
    const fieldValue = inputValue === null ? '' : inputValue;

    switch (selectValue) {
      case 'month':
        return (
          <MonthSelect
            disabled={disabled}
            name={componentName}
            onSelectChange={onInputChange}
            value={fieldValue}
          />
        );
      case 'dayOfWeek':
        return (
          <WeekDaySelect
            disabled={disabled}
            name={componentName}
            onSelectChange={onInputChange}
            value={fieldValue}
          />
        );
      case 'saleDate':
        return (
          <input
            disabled={disabled}
            type="date"
            name={`${name}-input`}
            onChange={onInputChange}
            pattern="\d{4}-\d{2}-\d{2}"
            value={fieldValue}
          />
        );
      default:
        return (
          <input
            disabled={disabled}
            type="text"
            name={`${name}-input`}
            onChange={onInputChange}
            value={fieldValue}
          />
        );
    }
  }

  return (
    <div
      id={`filterSelection-${name}-${randomString}`}
      className={styles.filterSelection}
    >
      {filters !== null && (
        <div className={styles.filterWrapper}>
          <FormControl variant="outlined" className={muStyles.select}>
            <Select
              native
              value={selectValue === null ? '0' : selectValue}
              onChange={onSelectChange}
              inputProps={{
                name,
                id: name,
              }}
            >
              <option value="0">-- Field --</option>
              {filters.map((filter: string, index: number) => {
                return (
                  <option key={index} value={filter}>
                    {humanizer(filter)}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          {!conditionalSelectors.includes(
            selectValue !== null ? selectValue : ''
          ) && (
            <>
              <button
                type="button"
                data-type={conditionValue}
                name={`${name}-condition`}
                onClick={onConditionValueChange}
              ></button>
              {filterInput()}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterSelection;
