import Navbar from 'components/SubComponents/Navbar';
import { useEffect, useState } from 'react';
import EditMenu from '../DashboardLayout/EditMenu';
import SideNav from '../Sidebar/SideNav';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styles from '../../styles/components/subcomponents/app-wrapper.module.scss';

type AppWrapperProps = {
  children: React.ReactNode;
};

function AppWrapper({ children }: AppWrapperProps): JSX.Element {
  const { hideNav } = useSelector((state: RootState) => state.query);
  const [contentClassList, setContentClassList] = useState<string[]>([
    styles.contentWrapper,
  ]);

  useEffect(() => {
    if (hideNav) {
      const classList = [...contentClassList, styles.fullWidth];
      setContentClassList(classList);
    }
  }, [hideNav]);

  return (
    <article className={styles.appWrapper}>
      <SideNav />
      {!hideNav && <EditMenu />}
      <div className={contentClassList.join(' ')}>
        <Navbar />
        {children}
      </div>
    </article>
  );
}

export default AppWrapper;
