import { useState } from 'react';
import styles from '../../../../styles/components/modals/content/chart-config/config-tab.module.scss';

interface DataTableConfigSettingsProps {
  itemConfiguration: DashboardItemConfig;
  didUpdateConfiguration: (config: DashboardItemConfig) => void;
}

const DataTableConfigSettings = ({
  itemConfiguration,
  didUpdateConfiguration,
}: DataTableConfigSettingsProps): JSX.Element => {
  return (
    <div className={styles.settingsTab}>
      <div className={styles.groupContent}></div>
      <div className={styles.groupContent}></div>
    </div>
  );
};

export default DataTableConfigSettings;
