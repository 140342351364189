import DashboardConfigEditor from '../DashboardConfigEditor';

interface DataTableConfigEditorProps {
  itemConfiguration: DashboardItemConfig;
  didUpdateConfiguration: (updatedConfig: DashboardItemConfig) => void;
}

const DataTableConfigEditor = ({
  itemConfiguration,
  didUpdateConfiguration,
}: DataTableConfigEditorProps): JSX.Element => {
  return (
    <DashboardConfigEditor
      enabledArgFields={false}
      itemConfiguration={itemConfiguration}
      didUpdateConfiguration={didUpdateConfiguration}
    />
  );
};

export default DataTableConfigEditor;
