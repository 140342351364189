export function parseGraphQLDashboard(
  remoteDashboard: GraphQLDashboard
): SFDashboard {
  let dashboardItems: SFDashboardItem[] = [];
  try {
    dashboardItems = JSON.parse(
      remoteDashboard.layoutJson
    ) as SFDashboardItem[];
  } catch (e) {
    console.error('Invalid layout JSON: ', e);
  }

  const sfDashboard: SFDashboard = {
    id: remoteDashboard.id,
    name: remoteDashboard.name,
    productID: remoteDashboard.productID,
    isDefault: remoteDashboard.isDefault,
    productCode: remoteDashboard.productCode,
    productName: remoteDashboard.productName,
    scope: remoteDashboard.scope.toLowerCase() as SFDashboardScope,
    layout: dashboardItems,
    layoutHash: remoteDashboard.layoutHash,
  };

  return sfDashboard;
}
