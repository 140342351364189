import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { selectDashboardItem } from 'store/selectors/dashboard';
import { setEditedWidgetID } from 'store/slices/widgetSlice';

import BarChartConfig from './Content/BarChartConfig';
import DataTableConfig from './Content/DataTableConfig';
import LineChartConfig from './Content/LineChartConfig';
import PieChartConfig from './Content/PieChartConfig';

interface ModalWrapperProps {
  itemID: string;
}

const ModalWrapper = ({ itemID }: ModalWrapperProps): JSX.Element => {
  const dispatch = useDispatch();
  const chartItem = useSelector((state: RootState) =>
    selectDashboardItem(state, itemID)
  );

  useEffect(() => {
    const type = chartItem!.type;
    if (type === null) return;
    dispatch(setEditedWidgetID({ editedItemID: itemID }));
  }, [itemID]);

  const renderContent: JSX.Element = useMemo(() => {
    const type = chartItem!.type;
    if (type === null) return <></>;

    switch (type) {
      case 'bar-chart':
        return <BarChartConfig itemID={itemID} />;
      case 'data-table':
        return <DataTableConfig itemID={itemID} />;
      case 'pie-chart':
        return <PieChartConfig itemID={itemID} />;
      case 'line-chart':
        return <LineChartConfig itemID={itemID} />;
      default:
        return <></>;
    }
  }, [itemID]);

  return renderContent;
};

export default ModalWrapper;
