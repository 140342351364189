import Toggle from 'components/Fields/Toggle';
import { ChangeEventHandler, useState } from 'react';

import styles from '../../../../styles/components/modals/content/chart-config/config-tab.module.scss';

interface DashboardItemConfigSettingsProps {
  itemConfiguration: DashboardItemConfig;
  didUpdateConfiguration: (config: DashboardItemConfig) => void;
}

const PieChartConfigSettings = ({
  itemConfiguration,
  didUpdateConfiguration,
}: DashboardItemConfigSettingsProps): JSX.Element => {
  const handleConfigUpdateForKey: (
    key: keyof DashboardItemConfig
  ) => ChangeEventHandler<HTMLInputElement> = (key) => (e) => {
    didUpdateConfiguration({
      ...itemConfiguration,
      [key]: e.target.value,
    });
  };

  function handleEnableToolTip(): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      enableToolTip: !itemConfiguration.enableToolTip,
    });
  }

  function handleRotateChart(): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      rotateChart: !itemConfiguration.rotateChart,
    });
  }

  return (
    <div className={styles.configTab}>
      <div className={styles.groupContent}>
        <div className={styles.contentColumn}>
          <label>Value Axis Title</label>
          <input
            type="text"
            id="valueAxisTitle"
            name="valueAxisTitle"
            value={itemConfiguration.valueAxisTitle}
            onChange={handleConfigUpdateForKey('valueAxisTitle')}
          />
        </div>
        <div className={styles.contentColumn}>
          <label>Arg Axis Title</label>
          <input
            type="text"
            id="argAxisTitle"
            name="argAxisTitle"
            value={itemConfiguration.argAxisTitle}
            onChange={handleConfigUpdateForKey('argAxisTitle')}
          />
        </div>
      </div>
      <div className={styles.groupContent}>
        <Toggle
          label="Enable Tool Tip"
          onClick={handleEnableToolTip}
          value={itemConfiguration.enableToolTip}
        />

        <Toggle
          label="Rotate Chart"
          onClick={handleRotateChart}
          value={itemConfiguration.rotateChart}
        />
      </div>
    </div>
  );
};

export default PieChartConfigSettings;
