import { useSnacks } from 'auth';
import db from 'data/db';
import { Settings } from 'data/models';
import { useLiveQuery } from 'dexie-react-hooks/dist/dexie-react-hooks';
import { currentPathArray } from 'helpers/url';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectDashboardsForProduct,
  selectSharedDashboards,
  selectUserDashboards,
} from 'store/selectors/dashboard';
import { setCurrentDashboard } from 'store/slices/dashboardSlice';
import { RootState } from '../../store';
import styles from '../../styles/components/sidebar/drawer.module.scss';
import AvailableChartList from './AvailableChartList';
import DashboardList from './DashboardList';
import ExpandIcon from './ExpandIcon';

interface DrawerSettings {
  menu: boolean;
  charts: boolean;
  layouts: boolean;
  sharedLayouts: boolean;
}

const Drawer = (): JSX.Element => {
  const dispatch = useDispatch();
  const { openSnack } = useSnacks();

  const { isEditing, isCreating } = useSelector(
    (state: RootState) => state.dashboard
  );

  const sidebarState = useSelector((state: RootState) => state.sidebar);

  const currentProductCode = currentPathArray()[0] as ProductCode;

  const dashboards = useSelector((state: RootState) =>
    selectDashboardsForProduct(state, currentProductCode)
  );

  const userDashboards = dashboards.filter((d) => d.scope === 'user');
  const sharedDashboards = dashboards.filter((d) => d.scope === 'shared');

  const { currentDashboardId } = useSelector(
    (state: RootState) => state.dashboard
  );

  function didSelectDashboard(dashboardId: string): void {
    if (isEditing || isCreating) {
      openSnack('Please save or cancel your changes first', 'warning');
      return;
    }
    if (dashboardId === currentDashboardId) return;
    dispatch(setCurrentDashboard(dashboardId));
  }

  const [settings, setSettings] = useState<DrawerSettings>({
    menu: false,
    charts: false,
    layouts: false,
    sharedLayouts: false,
  });

  const sidebarSettings: Settings = useLiveQuery(() =>
    db.getSetting('sidebarMenu')
  );

  const currentPath = useMemo(() => {
    const path: string = currentPathArray()[0];
    return path;
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      typeof currentPath === 'undefined' ||
      typeof sidebarSettings === 'undefined'
    )
      return;

    const currSettings: DrawerSectionDisplay =
      sidebarSettings.storage[currentPath];

    setSettings({ ...currSettings });
  }, [sidebarSettings, currentPath]);

  const handleDisplayDoubleClick = useCallback(
    async (e: any): Promise<void> => {
      e.preventDefault();
      const target = e.target.closest('div');
      const { path, label } = target.dataset;

      if (typeof path === 'undefined') return;
      const drawerSettings = await db.getSetting('sidebarMenu');
      const storage = drawerSettings.storage;
      const currentStorage = storage[path];

      const updatedStorage: DrawerSectionDisplay = Object.keys(
        currentStorage
      ).reduce((accumulator: any, key: any) => {
        const _currStorage = Boolean(currentStorage[key]);
        const value = label === key ? !_currStorage : _currStorage;

        return { ...accumulator, [key]: value };
      }, {});

      storage[path] = updatedStorage;
      db.updateSetting('sidebarMenu', { ...storage });
    },
    [currentPath]
  );

  return (
    <div className={`${styles.drawer} ${sidebarState.open ? styles.open : ''}`}>
      <div className={styles.container}>
        <div className={styles.scrollContent}>
          <DashboardList
            className={styles.drawerListItem}
            onDoubleClick={handleDisplayDoubleClick}
            didSelectDashboard={didSelectDashboard}
            isOpen={settings.layouts}
            title="Your Dashboards"
            dashboards={userDashboards}
            currentDashboardId={currentDashboardId}
          />

          <DashboardList
            className={styles.drawerListItem}
            onDoubleClick={handleDisplayDoubleClick}
            didSelectDashboard={didSelectDashboard}
            isOpen={settings.sharedLayouts}
            title="Shared Dashboards"
            dashboards={sharedDashboards}
            currentDashboardId={currentDashboardId}
          />

          <div
            className={`${styles.drawerListItem} ${
              !isEditing && !isCreating ? styles.hide : ''
            }`}
          >
            <div
              data-path={currentPath}
              data-open={settings.charts}
              data-label="charts"
              onDoubleClick={handleDisplayDoubleClick}
            >
              <ExpandIcon open={settings.charts} />
              <span>Charts</span>
            </div>
            <AvailableChartList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
