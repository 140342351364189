import { useEffect, useMemo } from 'react';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from './useData';

export type QueryList = {
  queries: string[];
  args: any;
};

export interface ChartConfigActions {
  getQueryList: QueryList;
}

const useChartConfig = (): ChartConfigActions => {
  const { data, setDataQuery } = useData();

  const graphqlQueryExcludeList = ['about', 'importlogs'];

  useEffect(() => {
    const config: GraphQLQueryConfig = {
      ...defaultGraphQLQueryConfig,
      query: 'loadQueries',
    };
    setDataQuery(config);
  }, []);

  /**
   * Acquires query list from instrospective graphql query.
   */
  const getQueryList: QueryList = useMemo(() => {
    const rawData = data.length ? data : [];
    const argItems: any = {};
    let queryItems: string[] = [];

    for (const item of rawData) {
      if (!graphqlQueryExcludeList.includes(item.name.toLowerCase())) {
        queryItems = [...queryItems, item.name];
        argItems[item.name] = item.args;
      }
    }

    const queryList = {
      args: argItems,
      queries: queryItems,
    };
    return queryList;
  }, [data]);

  return {
    getQueryList,
  };
};

export default useChartConfig;
