import { Close } from '@mui/icons-material';
import styles from '../../styles/components/modals/mini-modal.module.scss';

interface SFMiniModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

/** Shows provided `children` in a miniature modal display */
function SFMiniModal({
  open,
  onClose,
  children,
}: SFMiniModalProps): JSX.Element {
  return (
    <div className={`${styles.miniModal} ${open ? styles.open : ''}`}>
      <div className={styles.miniModalContent}>
        <div className={styles.closeBtn} onClick={onClose}>
          <Close />
        </div>
        {children}
      </div>
    </div>
  );
}

export default SFMiniModal;
