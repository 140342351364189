/**
 * Helps returns window.location.pathname into an
 * array of different bits of the route.
 */
export const currentPathArray = (): string[] => {
  const { pathname } = window.location;
  const items = pathname.split('/');
  return items.filter((n) => n);
};

export const rootPathFromUrl = (url: string): string => {
  const pathBits = url.split('/');
  return pathBits.length > 3 ? pathBits[3] : '';
};
