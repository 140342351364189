import GaugeComp from 'components/Charts/GaugeComp';
import Labor from 'components/Charts/Labor';
import OnHandDollars from 'components/Charts/OnHandDollars';
import Production from 'components/Charts/Production';
import ProductionToDate from 'components/Charts/ProductionToDate';
import QuantityRange from 'components/Charts/QuantityRange';
import RangeInventory from 'components/Charts/RangeInventory';
import RangeSales from 'components/Charts/RangeSales';
import SalesByRegion from 'components/Charts/SalesByRegion';
import SalesYTD from 'components/Charts/SalesYTD';
import SellThrough from 'components/Charts/SellThrough';
import SquareFootage from 'components/Charts/SquareFootage';
import WeeklyComp from 'components/Charts/WeeklyComp';
import WeeksOnHand from 'components/Charts/WeeksOnHand';
import BarChart from 'components/Custom/BarChart';
import LineChart from 'components/Custom/LineChart';
import DataTable from 'components/Custom/DataTable';
import PieChart from 'components/Custom/PieChart';
import { ComponentType } from 'react';

const prebuiltDashboardItemTypes = [
  'gauge-comp',
  'labor',
  'range-inventory',
  'range-sales',
  'on-hand-dollars',
  'production',
  'production-to-date',
  'quantity-range',
  'sales-by-region',
  'sales-ytd',
  'sell-through',
  'square-footage',
  'weekly-comp',
  'weeks-on-hand',
  // Need `as const` to help TS get the derived types right
] as const;

// Need `as const` to help TS get the derived types right
const customDashboardItemTypes = [
  'bar-chart',
  'data-table',
  'pie-chart',
  'line-chart',
] as const;

export const availableDashboardItemTypes = [
  ...prebuiltDashboardItemTypes,
  ...customDashboardItemTypes,
];

/** Map dashboard item identifiers to their respective titles and Components */
const dashboardComponentItemInfo: {
  [key in DashboardItemType]: SFDashboardItemInfo;
} = {
  'pie-chart': {
    title: 'Pie Chart',
    Component: PieChart,
    defaultGridLayout: {
      w: 3,
      h: 12,
      x: 0,
      y: 10,
    },
  },
  'bar-chart': {
    title: 'Bar Chart',
    Component: BarChart,
    defaultGridLayout: {
      w: 6,
      h: 16,
      x: 0,
      y: 0,
    },
  },
  'line-chart': {
    title: 'Line Chart',
    Component: LineChart,
    defaultGridLayout: {
      w: 5,
      h: 10,
      x: 0,
      y: 0,
    },
  },
  'data-table': {
    title: 'Data Table',
    Component: DataTable,
    defaultGridLayout: {
      w: 5,
      h: 10,
      x: 0,
      y: 0,
    },
  },
  'gauge-comp': {
    title: 'Gauge Comp',
    Component: GaugeComp,
    defaultGridLayout: {
      w: 6,
      h: 16,
      x: 0,
      y: 17,
    },
  },
  labor: {
    title: 'Labor',
    Component: Labor,
    defaultGridLayout: {
      w: 3,
      h: 10,
      x: 0,
      y: 0,
    },
  },
  'on-hand-dollars': {
    title: 'On Hand Dollars',
    Component: OnHandDollars,
    defaultGridLayout: {
      w: 4,
      h: 16,
      x: 0,
      y: 16,
    },
  },
  production: {
    title: 'Production',
    Component: Production,
    defaultGridLayout: {
      w: 3,
      h: 10,
      x: 3,
      y: 0,
    },
  },
  'production-to-date': {
    title: 'Production To Date',
    Component: ProductionToDate,
    defaultGridLayout: {
      w: 3,
      h: 10,
      x: 6,
      y: 0,
    },
  },
  'quantity-range': {
    title: 'Quantity Range',
    Component: QuantityRange,
    defaultGridLayout: {
      w: 12,
      h: 16,
      x: 0,
      y: 10,
    },
  },
  'range-inventory': {
    title: 'Range Inventory',
    Component: RangeInventory,
    defaultGridLayout: {
      w: 12,
      h: 16,
      x: 0,
      y: 0,
    },
  },
  'range-sales': {
    title: 'Range Sales',
    Component: RangeSales,
    defaultGridLayout: {
      w: 8,
      h: 17,
      x: 0,
      y: 0,
    },
  },
  'sales-by-region': {
    title: 'Sales By Region',
    Component: SalesByRegion,
    defaultGridLayout: {
      w: 6,
      h: 16,
      x: 6,
      y: 17,
    },
  },
  'sales-ytd': {
    title: 'Sales YTD',
    Component: SalesYTD,
    defaultGridLayout: {
      w: 4,
      h: 8,
      x: 8,
      y: 0,
    },
  },
  'sell-through': {
    title: 'Sell Through',
    Component: SellThrough,
    defaultGridLayout: {
      w: 4,
      h: 16,
      x: 4,
      y: 16,
    },
  },
  'square-footage': {
    title: 'Square Footage',
    Component: SquareFootage,
    defaultGridLayout: {
      w: 3,
      h: 10,
      x: 9,
      y: 0,
    },
  },
  'weekly-comp': {
    title: 'Weekly Comp',
    Component: WeeklyComp,
    defaultGridLayout: {
      w: 4,
      h: 9,
      x: 8,
      y: 8,
    },
  },
  'weeks-on-hand': {
    title: 'Weeks On Hand',
    Component: WeeksOnHand,
    defaultGridLayout: {
      w: 4,
      h: 16,
      x: 8,
      y: 16,
    },
  },
};

export function getComponentForDashboardItemType(
  id: DashboardItemType
): Maybe<ComponentType<any>> {
  return dashboardComponentItemInfo[id]?.Component || null;
}

export function getNameForDashboardItemType(id: DashboardItemType): string {
  return dashboardComponentItemInfo[id].title;
}

export function getTypeForDashboardItemType(
  itemType: DashboardItemType
): 'prebuilt' | 'custom' {
  if (prebuiltDashboardItemTypes.indexOf(itemType as any) > -1) {
    return 'prebuilt';
  } else if (customDashboardItemTypes.includes(itemType as any)) {
    return 'custom';
  } else {
    throw new Error(`Invalid dashboard item type: ${itemType}`);
  }
}

export function getAllItemNamesList(): string[] {
  return availableDashboardItemTypes.map((type) =>
    getNameForDashboardItemType(type)
  );
}

export function getCustomItemNamesList(): string[] {
  return customDashboardItemTypes.map((type) =>
    getNameForDashboardItemType(type)
  );
}

export function getPrebuiltItemNamesList(): string[] {
  return prebuiltDashboardItemTypes.map((type) =>
    getNameForDashboardItemType(type)
  );
}

export function isCustomDashboardItemType(
  itemType: DashboardItemType
): boolean {
  return customDashboardItemTypes.includes(itemType as any);
}

export function getDashboardItemTypeFromName(name: string): DashboardItemType {
  const id = availableDashboardItemTypes.find(
    (_id) => getNameForDashboardItemType(_id) === name
  );
  if (!id) {
    throw new Error(`Invalid dashboard item name: ${name}`);
  }
  return id;
}

export function getDefaultGridLayoutFromDashboardName(
  name: string
): GridLayoutPosition {
  const id = getDashboardItemTypeFromName(name);
  return dashboardComponentItemInfo[id].defaultGridLayout;
}
