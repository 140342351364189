import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalType = 'editConfig' | 'deleteLayout' | 'saveLayout';

export interface ModalState {
  dashboardItemID: Maybe<string>;
  open?: boolean;
  response?: any;
  type: Maybe<ModalType>;
}

const initialState: ModalState = {
  dashboardItemID: null,
  open: false,
  type: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalState: (state, action: PayloadAction<ModalState>) => {
      const items = action.payload;
      if (Object.keys(items).length) {
        Object.entries(items).forEach((item: any[]) => {
          const key = item[0];
          const value = item[1];

          switch (key) {
            case 'dashboardItemID':
              state.dashboardItemID = value;
              break;
            case 'open':
              state.open = value;
              break;
            case 'response':
              state.response = value;
              break;
            case 'type':
              state.type = value;
              break;
          }
        });
      }
    },
  },
});

export const { setModalState } = modalSlice.actions;
export default modalSlice.reducer;
