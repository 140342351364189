import React, { useEffect, useMemo, useState } from 'react';
import {
  PieChart as DXPieChart,
  Series,
  Label,
  Connector,
  Tooltip,
} from 'devextreme-react/pie-chart';
import { NativeEventInfo } from 'devextreme/events';
import dxPieChart from 'devextreme/viz/pie_chart';
import { PointInteractionInfo } from 'devextreme/viz/chart_components/base_chart';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import useColorPalette from 'hooks/useColorPalette';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { selectDashboardItem } from 'store/selectors/dashboard';
import { renameObjectKey } from 'helpers/object';
import { PaletteConfig } from 'palette';
import { Paper } from '@mui/material';
import styles from '../../styles/components/custom/pie-chart.module.scss';
import '../../styles/material-ui/paper.scss';
import { Spinner } from 'auth';
import QueryGenerator from 'data/graph/queryGenerator';
import { generateRandomString } from 'helpers/string';
import usePrevious from 'hooks/usePrevious';

type PieChartPointClickEvent = NativeEventInfo<
  dxPieChart,
  MouseEvent | PointerEvent
> &
  PointInteractionInfo;

interface PieChartProps extends DashboardComponentProps {
  itemID: string;
}

function PieChart({ redrawKey, itemID }: PieChartProps): JSX.Element {
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const calendar = useSelector((state: RootState) => state.calendar);
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>(null);

  const chartItem = useSelector((state: RootState) =>
    selectDashboardItem(state, itemID)
  );
  const chartConfig = chartItem?.chartConfigurations || null;
  const [loading, setLoading] = useState(true);

  const previousData = usePrevious(data);
  console.log('data === previousData: ', data === previousData);

  useEffect(() => {
    if (chartConfig === null) return;

    // Class that builds out query from tableConfiguration.
    const buildQuery = new QueryGenerator(chartConfig, calendar);
    const generatedQuery = buildQuery.result();

    const query: GraphQLQueryConfig = {
      ...defaultGraphQLQueryConfig,
      argField: chartConfig.selectedArgField,
      query: 'generatedQuery',
      comparisonFuzzyDate: chartConfig.comparisonFuzzyDate,
      generatedQueryString: generatedQuery,
    };

    setDataQuery(query);
    console.log('setDataQuery');
  }, [chartConfig, itemID]);

  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    if (chartConfig === null) {
      return;
    }

    // Load and configure data to display with correct arg field.
    const argField = chartConfig.selectedArgField;
    let updatedData: any[] = [];
    data.forEach((dataItem: any) => {
      const object = renameObjectKey(argField, 'arg', dataItem);
      updatedData = [...updatedData, object];
    });

    setChartData(data);
    setLoading(isLoading);
  }, [data]);

  function contentTemplate(templateData: any): any {
    return templateData.argumentText;
  }

  function onPointClick(e: PieChartPointClickEvent): void {
    const point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  }

  return (
    <div className={styles.pieChart}>
      <Paper
        square
        elevation={0}
        className={`${styles.box} paper-box paper-box-l`}
      >
        {(!hasData || isLoading) && (
          <div className={styles.spinnerWrapper}>
            <Spinner active={isLoading} background={'clear'} />
          </div>
        )}
        <div className={styles.heading}>
          <h2>{chartConfig?.chartTitle}</h2>
        </div>
        {!hasData && !isLoading && (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )}
        {!isLoading && hasData && data.length === 0 && (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )}
        {!isLoading && hasData && data.length > 0 && (
          <DXPieChart
            key={redrawKey}
            dataSource={[...data]}
            type="pie"
            onPointClick={onPointClick}
          >
            {chartConfig !== null &&
              chartConfig.selectedFields.map((selectedField, index) => {
                return (
                  <Series
                    key={`${index}`}
                    argumentField={'arg'}
                    valueField={selectedField.field}
                  >
                    <Label
                      visible={true}
                      position="columns"
                      // customizeText={customizeText}
                    >
                      <Connector visible={true}></Connector>
                    </Label>
                  </Series>
                );
              })}
            <Tooltip enabled={true} contentTemplate={contentTemplate}></Tooltip>
          </DXPieChart>
        )}
      </Paper>
    </div>
  );
}

export default PieChart;
