import { useEffect, useMemo, useState } from 'react';
import analyzeColorLogoWhite from '../../assets/logos/analyze/analyze-white.svg';
import analyzeColorLogo from '../../assets/logos/analyze/analyze.svg';
import forecastColorLogoWhite from '../../assets/logos/forecast/forecast-white.svg';
import forecastColorLogo from '../../assets/logos/forecast/forecast.svg';
import fulfillColorLogoWhite from '../../assets/logos/fulfill/fulfill-white.svg';
import fulfillColorLogo from '../../assets/logos/fulfill/fulfill.svg';
import produceColorLogoWhite from '../../assets/logos/produce/produce-white.svg';
import produceColorLogo from '../../assets/logos/produce/produce.svg';
import restockColorLogoWhite from '../../assets/logos/restock/restock-white.svg';
import restockColorLogo from '../../assets/logos/restock/restock.svg';

interface ProductLogoProps {
  product: ProductCode;
}

type LogoVariations = {
  standard: string;
  white: string;
};

const ProductLogo = ({ product }: ProductLogoProps): JSX.Element => {
  const altText = useMemo((): LogoVariations => {
    return {
      standard: `${product}-logo`,
      white: `${product}-logo-white`,
    };
  }, [product]);

  const logo = useMemo((): LogoVariations => {
    let _logoStandard: string;
    let _logoWhite: string;

    switch (product) {
      case 'analyze':
        _logoStandard = analyzeColorLogo;
        _logoWhite = analyzeColorLogoWhite;
        break;
      case 'produce':
        _logoStandard = produceColorLogo;
        _logoWhite = produceColorLogoWhite;
        break;
      case 'forecast':
        _logoStandard = forecastColorLogo;
        _logoWhite = forecastColorLogoWhite;
        break;
      case 'fulfill':
        _logoStandard = fulfillColorLogo;
        _logoWhite = fulfillColorLogoWhite;
        break;
      case 'restock':
        _logoStandard = restockColorLogo;
        _logoWhite = restockColorLogoWhite;
        break;
      default:
        _logoStandard = '';
        _logoWhite = '';
        break;
    }

    return {
      standard: _logoStandard,
      white: _logoWhite,
    };
  }, [product]);

  return (
    <>
      <img alt={altText.standard} src={logo.standard} />
      <img alt={altText.white} src={logo.white} />
    </>
  );
};

export default ProductLogo;
