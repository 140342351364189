import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProduceState {
  worksuiteList: string[];
}

const initialState: ProduceState = {
  worksuiteList: [],
};

export const worksuiteSlice = createSlice({
  name: 'worksuite',
  initialState,
  reducers: {
    setWorksuiteList: (state, action: PayloadAction<any>) => {
      if (action.payload !== null && action.payload.length) {
        state.worksuiteList = action.payload;
      } else {
        state.worksuiteList = [];
      }
    },
  },
});

export const { setWorksuiteList } = worksuiteSlice.actions;
export default worksuiteSlice.reducer;
