import { getObjectArrayIndex } from './object';
import { humanizer } from './string';

export function setComparisonFields(
  dataKeys: string[],
  fields: any[],
  suffix: string | false
): any[] {
  const mutableFields = [...fields];
  let previousKey = '';
  for (const i in dataKeys) {
    const key = dataKeys[i];

    const checkKey = getObjectArrayIndex('field', key, mutableFields);
    if (checkKey === null || checkKey !== -1) {
      previousKey = key;
      continue;
    }

    const index = getObjectArrayIndex('field', previousKey, mutableFields);
    if (index === null) {
      previousKey = key;
      continue;
    }

    const columnField = mutableFields[index];
    const isComparison = columnField.comparison;

    if (!isComparison) {
      previousKey = key;
      continue;
    }

    // Grab previous selectedField, check if is comparison
    // then add the suffix to the name.
    const insertIndex = index + 1;
    const format = columnField.format;

    let name = isComparison ? `${columnField.name}${suffix}` : columnField.name;
    name = name.charAt(0).toLowerCase() + name.slice(1);
    name = name.replace(/\s/g, '');

    mutableFields.splice(insertIndex as number, 0, {
      field: key,
      name: humanizer(name),
      format: typeof format !== 'undefined' ? format : 'default',
    });

    previousKey = key;
  }

  return mutableFields;
}
