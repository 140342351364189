import { exportDataGrid } from 'devextreme/excel_exporter';
import { fuzzyDateText } from 'helpers/query';
import { getObjectArrayIndex } from 'helpers/object';
import { humanizer } from 'helpers/string';
import { Paper } from '@mui/material';
import { RootState } from '../../store';
import { saveAs } from 'file-saver-es';
import { selectDashboardItem } from 'store/selectors/dashboard';
import {
  formatDataGridData,
  setDataGridDataType,
  setDataGridFormatType,
} from 'helpers/format';
import { Spinner } from 'auth';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Workbook } from 'exceljs';
import DataGrid, {
  Column,
  ColumnFixing,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  HeaderFilter,
  LoadPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  SortByGroupSummaryInfo,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import QueryGenerator from 'data/graph/queryGenerator';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';

import styles from '../../styles/components/custom/data-table.module.scss';
import '../../styles/material-ui/paper.scss';
import { setComparisonFields } from 'helpers/dashboard';

interface ChartProps extends DashboardComponentProps {
  itemID: string;
}

type DataGridFormatType = {
  type: string;
  precision: number;
};

const DataTable = ({
  itemID,
  redrawKey,
  onCustomStateChange,
}: ChartProps): JSX.Element => {
  const { data, hasData, isLoading, setDataQuery } = useData();
  const [chartData, setChartData] = useState<any>(null);
  const calendar = useSelector((state: RootState) => state.calendar);
  const tableItem = useSelector((state: RootState) =>
    selectDashboardItem(state, itemID)
  );

  // Data set from popup configuration modal.
  const dashboardConfiguration = tableItem?.chartConfigurations || null;

  const [columns, setColumns] = useState<any[]>([]);
  const [displayHeaderFilter] = useState<boolean>(true);
  const [displayLoadPanel] = useState<boolean>(true);
  const [displayPaging, setDisplayPaging] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-l',
  ]);

  useEffect(() => {
    if (dashboardConfiguration === null) return;

    // Class that builds out query from dashboardConfiguration.
    const buildQuery = new QueryGenerator(dashboardConfiguration, calendar);
    const generatedQuery = buildQuery.result();

    const query: GraphQLQueryConfig = {
      ...defaultGraphQLQueryConfig,
      query: 'generatedQuery',
      comparisonFuzzyDate: dashboardConfiguration.comparisonFuzzyDate,
      generatedQueryString: generatedQuery,
    };

    setDataQuery(query);
  }, [dashboardConfiguration, itemID]);

  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    // Apply any comparision fields for use in columns.
    const selectedFields = dashboardConfiguration?.selectedFields;
    if (typeof selectedFields === 'undefined') return;
    const comparisonDateOffset =
      dashboardConfiguration?.comparisonFuzzyDate as string;
    const comparisonSuffix: string | boolean =
      comparisonDateOffset !== '0' && fuzzyDateText[comparisonDateOffset];

    const dataKeys = Object.keys(data[0]);
    const mutableSelectedFields = setComparisonFields(
      dataKeys,
      selectedFields,
      comparisonSuffix
    );

    setColumns(mutableSelectedFields);
    setDisplayPaging(data.length > 10);

    const formattedData = formatDataGridData(mutableSelectedFields, data);
    setChartData(formattedData);
    setLoading(isLoading);
  }, [data]);

  const handleExporting = (e: any): void => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DataTableExport.xlsx'
        );
      });
    });

    e.cancel = true;
  };

  const customStateRef = useRef<any>(null);

  const customStateSave = useCallback((state: any) => {
    if (onCustomStateChange === undefined) {
      throw new Error('No custom state change handler provided.');
    }

    onCustomStateChange(state);
  }, []);

  const customStateLoad = useCallback(() => {
    const customState = dashboardConfiguration?.customState;
    if (customState === undefined) {
      return;
    }

    customStateRef.current = customState;

    // Data grid modifies the object returned by this function,
    // which redux doesn't allow. Clone before passing to data grid.
    return structuredClone(customState);
  }, []);

  return (
    <div className={styles.dataTable}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <div className={styles.heading}>
          <h2>{dashboardConfiguration?.chartTitle}</h2>
        </div>

        {!loading ? (
          hasData ? (
            <>
              <DataGrid
                id={itemID}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                dataSource={chartData}
                filterBuilder={filterBuilder}
                key={redrawKey}
                rowAlternationEnabled={true}
                onExporting={handleExporting}
                showBorders={true}
              >
                <ColumnFixing enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup
                  position={filterBuilderPopupPosition}
                  height="350px"
                  width="500px"
                />
                <HeaderFilter visible={displayHeaderFilter} />
                <LoadPanel
                  enabled={displayLoadPanel}
                  text=""
                  shading={true}
                  showPane={false}
                />
                <Pager
                  allowedPageSizes={allowedPageSizes}
                  displayMode={'compact'}
                  showInfo={true}
                  showNavigationButtons={true}
                  showPageSizeSelector={true}
                  visible={displayPaging}
                />
                <Paging enabled={displayPaging} defaultPageSize={10} />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={customStateLoad}
                  customSave={customStateSave}
                />
                <Sorting mode="multiple">
                  <SortByGroupSummaryInfo summaryItem="count" />
                </Sorting>
                {columns.length
                  ? columns.map((item, index) => {
                      return (
                        <Column
                          key={index}
                          alignment="right"
                          dataField={item.field}
                          caption={humanizer(item.name)}
                          dataType={setDataGridDataType(item.format)}
                          format={setDataGridFormatType(item.format)}
                        />
                      );
                    })
                  : null}
              </DataGrid>
            </>
          ) : (
            <div className={styles.notFound}>
              <p>No data found.</p>
            </div>
          )
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner active={loading} background={'clear'} />
          </div>
        )}
      </Paper>
    </div>
  );
};

const allowedPageSizes = [10, 20, 30];

const filterBuilder = {
  allowHierarchicalFields: true,
};

const filterBuilderPopupPosition = {
  of: window,
  at: 'center',
  my: 'center',
  offset: { y: -150 },
};

export default DataTable;
