import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { fuzzyComparisonDates } from 'helpers/query';
import { generateRandomString } from 'helpers/string';
import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/comparison-fuzzy-dates.module.scss';

interface ComparisonFuzzyDatesProps {
  disabled: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: string[];
  value: FuzzyDate;
}

const ComparisonFuzzyDates = ({
  disabled = true,
  onChange,
  options,
  value = '0',
}: ComparisonFuzzyDatesProps): JSX.Element => {
  const randomString = generateRandomString();
  return (
    <div className={styles.comparisonFuzzyDates}>
      {options.length > 0 && (
        <FormControl variant="outlined" className={muStyles.select}>
          <label htmlFor={`comparison-selection-${randomString}`}>
            Comparison Date
          </label>
          <Select
            native
            disabled={disabled}
            value={disabled ? '0' : value}
            onChange={onChange}
            inputProps={{
              name: `comparison-selection-${randomString}`,
              id: `comparison-selection-${randomString}`,
            }}
          >
            <option value="0"> --- </option>
            {options.map((option, index) => {
              return (
                <option value={option} key={index}>
                  {fuzzyComparisonDates(option)}
                </option>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default ComparisonFuzzyDates;
