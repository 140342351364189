import { humanizer } from 'helpers/string';
import { SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import FormatSelection from 'components/Fields/FormatSelection';
import Toggle from 'components/Fields/Toggle';
import styles from '../../styles/components/modals/content/chart-config/selected-value-field-editor.module.scss';

interface SelectedFieldEditorProps {
  field: Field;
  onFieldEdit: (updatedField: Field) => void;
  onSubmit: () => void;
}

function SelectedFieldEditor({
  field,
  onFieldEdit,
  onSubmit,
}: SelectedFieldEditorProps): JSX.Element {
  const [name, setName] = useState<string>('');

  function onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setName(e.target.value);
    onFieldEdit({
      ...field,
      name: e.target.value,
    });
  }

  function onFormatChange(e: SelectChangeEvent<string>): void {
    onFieldEdit({
      ...field,
      format: e.target.value as DataFormatType,
    });
  }

  function onComparisonToggle(): void {
    onFieldEdit({
      ...field,
      comparison: !field.comparison,
    });
  }

  return (
    <div className={styles.valueFieldEditor}>
      <div className={styles.content}>
        <div className={styles.splitSection}>
          <div className={styles.name}>
            <label htmlFor="name">Label:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={humanizer(field.name)}
              value={name}
              onChange={onNameChange}
            />
          </div>

          <FormatSelection onChange={onFormatChange} value={field.format} />
        </div>

        <Toggle
          label="Add Comparison:"
          onClick={onComparisonToggle}
          value={
            typeof field?.comparison !== 'undefined' ? field.comparison : false
          }
        />
      </div>

      <div className={styles.bottom}>
        <button type="submit" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default SelectedFieldEditor;
