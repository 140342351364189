import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: DashboardItemConfig = {
  argAxisTitle: '',
  argFields: [],
  chartTitle: '',
  comparisonFuzzyDate: '0',
  comparisonOptions: [],
  displayLimit: 'top|10',
  enableToolTip: false,
  hasComparison: false,
  fields: [],
  introspectiveQueryName: '',
  limit: '0|',
  order: 'DESC',
  rotateChart: false,
  selectedArgField: '',
  selectedFields: [],
  selectedQuery: '0',
  valueAxisTitle: '',
  whereFilter: {
    count: 0,
    items: [],
  },
};

export const chartConfigSlice = createSlice({
  name: 'chartConfig',
  initialState,
  reducers: {
    setChartConfigState: (
      state,
      action: PayloadAction<DashboardItemConfig>
    ) => {
      const items = action.payload;

      if (Object.keys(items).length) {
        Object.entries(items).forEach((item: any[]) => {
          const key = item[0];
          const value = item[1];

          switch (key) {
            case 'argAxisTitle':
              state.argAxisTitle = value;
              break;
            case 'argFields':
              state.argFields = value;
              break;
            case 'chartTitle':
              state.chartTitle = value;
              break;
            case 'comparisonFuzzyDate':
              state.comparisonFuzzyDate = value;
              break;
            case 'comparisonOptions':
              state.comparisonOptions = value;
              break;
            case 'displayLimit':
              state.displayLimit = value;
              break;
            case 'enableToolTip':
              state.enableToolTip = value;
              break;
            case 'fields':
              state.fields = value;
              break;
            case 'hasComparison':
              state.fields = value;
              break;
            case 'limit':
              state.limit = value;
              break;
            case 'order':
              state.order = value;
              break;
            case 'rotateChart':
              state.rotateChart = value;
              break;
            case 'selectedArgField':
              state.selectedArgField = value;
              break;
            case 'selectedFields':
              state.selectedFields = value;
              break;
            case 'selectedQuery':
              state.selectedQuery = value;
              break;
            case 'valueAxisTitle':
              state.valueAxisTitle = value;
              break;
            case 'whereFilters':
              state.whereFilter = value;
              break;
          }
        });
      }
    },
  },
});

export const { setChartConfigState } = chartConfigSlice.actions;
export default chartConfigSlice.reducer;
