import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Title,
  Subtitle,
  Tooltip,
  Grid,
} from 'devextreme-react/chart';
import { NativeEventInfo } from 'devextreme/events';
import { PointInteractionInfo } from 'devextreme/viz/chart_components/base_chart';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import useColorPalette from 'hooks/useColorPalette';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { selectDashboardItem } from 'store/selectors/dashboard';
import { renameObjectKey } from 'helpers/object';
import { PaletteConfig } from 'palette';
import { Paper } from '@mui/material';
import styles from '../../styles/components/custom/line-chart.module.scss';
import '../../styles/material-ui/paper.scss';
import { Spinner } from 'auth';
import QueryGenerator from 'data/graph/queryGenerator';
import { humanizer } from 'helpers/string';

interface LineChartProps extends DashboardComponentProps {
  itemID: string;
}

function LineChart({ redrawKey, itemID }: LineChartProps): JSX.Element {
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const calendar = useSelector((state: RootState) => state.calendar);
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>(null);

  const chartItem = useSelector((state: RootState) =>
    selectDashboardItem(state, itemID)
  );
  const chartConfig = chartItem?.chartConfigurations || null;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (chartConfig === null) return;

    // Class that builds out query from tableConfiguration.
    const buildQuery = new QueryGenerator(chartConfig, calendar);
    const generatedQuery = buildQuery.result();

    const query: GraphQLQueryConfig = {
      ...defaultGraphQLQueryConfig,
      argField: chartConfig.selectedArgField,
      query: 'generatedQuery',
      comparisonFuzzyDate: chartConfig.comparisonFuzzyDate,
      generatedQueryString: generatedQuery,
    };

    setDataQuery(query);
  }, [chartConfig, itemID]);

  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    if (chartConfig === null) {
      return;
    }

    // Load and configure data to display with correct arg field.
    const argField = chartConfig.selectedArgField;
    let updatedData: any[] = [];
    data.forEach((dataItem: any) => {
      const object = renameObjectKey(argField, 'arg', dataItem);
      updatedData = [...updatedData, object];
    });

    setChartData(data);
    setLoading(isLoading);
  }, [data]);

  const colorPalette = useMemo(() => {
    return themePalette.customLineChart as PaletteConfig;
  }, [themePalette]);

  function contentTemplate(templateData: any): any {
    return templateData.argumentText;
  }

  return (
    <div className={styles.lineChart}>
      <Paper
        square
        elevation={0}
        className={`${styles.box} paper-box paper-box-l`}
      >
        {(!hasData || isLoading) && (
          <div className={styles.spinnerWrapper}>
            <Spinner active={isLoading} background={'clear'} />
          </div>
        )}
        <div className={styles.heading}>
          <h2>{chartConfig?.chartTitle}</h2>
        </div>
        {!hasData && !isLoading && (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )}
        {!isLoading && hasData && data.length === 0 && (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )}
        {!isLoading && hasData && data.length > 0 && (
          <>
            <Chart key={redrawKey} palette="Dark Violet" dataSource={[...data]}>
              <CommonSeriesSettings argumentField="arg" type="line" />
              <Margin bottom={20} />
              {data.length > 0 &&
                chartConfig?.selectedFields.map((key, index: number) => {
                  if (key.field === chartConfig.selectedArgField) return null;
                  return (
                    <Series
                      key={key.field}
                      valueField={key.field}
                      name={key.name}
                    />
                  );
                })}
              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                {/* <Grid visible={true} /> */}
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              {/* <Export enabled={true} /> */}
              <Tooltip enabled={true} />
            </Chart>
          </>
        )}
      </Paper>
    </div>
  );
}

export default LineChart;
