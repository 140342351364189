import { SnackbarProvider } from 'notistack';
import styles from '../../styles/auth/components/providers/snack-provider.module.scss';

interface SnackbarProviderProps {
  children: JSX.Element;
}

const SnackProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <SnackbarProvider
      dense
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      maxSnack={4}
      classes={{
        root: styles.snackProvider,
        variantSuccess: styles.success,
        variantInfo: styles.info,
        variantError: styles.error,
        variantWarning: styles.warning,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackProvider;
