/**
 * Search array object
 */
export function searchObject(key: string, value: string, content: any): any[] {
  const foundItems: any[] = [];

  if (typeof content === 'undefined') {
    return foundItems;
  }

  const isArray = content instanceof Array;

  if (isArray) {
    for (let i = 0; i < content.length; i++) {
      if (content[i][key] === value) {
        foundItems.push(content[i]);
      }
    }
  } else {
    const _keys: string[] = Object.keys(content);
    const _values: any[] = Object.values(content);

    for (let i = 0; i < _values.length; i++) {
      const storeMap: any[] = [];
      const _value = _values[i];
      const primaryKey = _keys[i];
      storeMap.push(primaryKey);
      for (let k = 0; k <= _value.length - 1; k++) {
        if (_value[k][key] === value) {
          storeMap.push(k);
        }
      }
      foundItems.push(storeMap);
    }
  }

  return foundItems;
}

/**
 * Returns the index for the array item that contains specified key value pair.
 */
export function getObjectArrayIndex(
  key: string,
  value: string,
  content: any
): number | null {
  const isArray = content instanceof Array;
  if (!isArray) {
    return null;
  }

  const foundIndex = content.findIndex((element: any) => {
    if (typeof element === 'undefined' || element[key] !== value) {
      return false;
    }
    return true;
  });

  return foundIndex;
}

/**
 * Rename a specific object key.
 */
export function renameObjectKey(
  oldProp: string,
  newProp: string,
  object: any
): any {
  if (typeof object === undefined) return null;

  const value = object[oldProp];
  object[newProp] = value;
  delete object[oldProp];

  return object;
}

/**
 * Checks array of objects to check for a specific property value.
 */
export function hasValue(
  arrayOfObjects: any[] | null,
  value: string,
  property = 'name'
): boolean {
  if (typeof arrayOfObjects === 'undefined' || arrayOfObjects === null)
    return false;

  const hasProperty = arrayOfObjects.some((object: any) => {
    if (object === null || typeof object === undefined) return false;
    return object[property] === value;
  });
  return hasProperty;
}

/**
 * Update an array of objects based on property value.
 */
export function updateObjectArrayByProperty(
  property: string,
  value: string,
  object: any,
  array: any[]
): any[] {
  if (typeof array === 'undefined' || array === null) return [];
  const newArray = [...array];
  const index = getObjectArrayIndex(property, value, newArray);

  if (index === null || index === -1) return [...newArray, object];
  newArray[index] = object;
  return newArray;
}

/**
 * Remove an array of objects based on property value.
 */
export function removeObjectArrayByProperty(
  property: string,
  value: string,
  array: any[]
): any[] {
  if (typeof array === 'undefined' || array === null) return [];
  const newArray = [...array];
  const index = getObjectArrayIndex(property, value, newArray);

  if (index === null || index === -1) return newArray;
  newArray.splice(index, 1);
  return newArray;
}

/**
 * Convert object property values into key value pairs.
 * Only works for objects with two properties.
 */
export function convertObjectToKeyValuePairs(array: any): any[] {
  let newArray: any[] = [];

  for (const object of array) {
    let updatedObject: { [x: string]: string }[] = [];
    for (const item of object) {
      const itemBit = Object.entries(item);
      const key = itemBit[0][1] as string;
      const value = itemBit[1][1] as string;
      updatedObject = [
        ...updatedObject,
        {
          [key]: value,
        },
      ];
    }
    newArray = [...newArray, updatedObject];
  }

  return newArray;
}

/**
 * Get object property keys from an array of objects.
 */
export function getObjectPropertyKeys(array: any): string[] {
  const propertyKeys: string[] = [];

  for (const object of array) {
    const objectKeys = Object.keys(object);
    propertyKeys.push(...objectKeys);
  }

  return propertyKeys;
}

/**
 * Flatten an array of objects into a single object.
 */
export function flattenObjectArray(array: any): any {
  const flattenedObject: any = {};

  for (const object of array) {
    const objectKeys = Object.keys(object);
    for (const key of objectKeys) {
      flattenedObject[key] = object[key];
    }
  }

  return flattenedObject;
}
