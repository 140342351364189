import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import muStyles from '../../styles/material-ui/select.module.scss';
import { monthListShort } from 'helpers/date';

interface MonthSelectProps {
  disabled: boolean;
  name: string;
  onSelectChange: (event: SelectChangeEvent<string>) => void;
  value: string;
}

const MonthSelect = ({
  disabled,
  name,
  onSelectChange,
  value,
}: MonthSelectProps): JSX.Element => {
  return (
    <FormControl variant="outlined" className={muStyles.select}>
      <Select
        disabled={disabled}
        native
        value={value === null ? '0' : value}
        onChange={onSelectChange}
        inputProps={{
          name,
          id: name,
        }}
      >
        <option value="0">-- Month --</option>
        {monthListShort.map((month, index) => (
          <option key={index} value={index + 1}>
            {month}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default MonthSelect;
