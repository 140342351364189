export interface PaletteConfig {
  chart: string[];
  background?: string;
  font: string;
  secondaryFont?: string;
}

export interface PaletteDetail {
  customBarChart?: PaletteConfig;
  customPieChart?: PaletteConfig;
  customLineChart?: PaletteConfig;
  barChart?: PaletteConfig;
  drilldown?: PaletteConfig;
  gauge?: PaletteConfig;
  inventoryRange?: PaletteConfig;
  salesRange?: PaletteConfig;
  salesYTD?: PaletteConfig;
}

export interface ThemePalette {
  analyze: PaletteDetail;
  produce: PaletteDetail;
  forecast?: PaletteDetail;
  fulfill?: PaletteDetail;
  restock?: PaletteDetail;
}

const currentYear = '#98c23e';

const Palette: ThemePalette = {
  analyze: {
    barChart: {
      chart: ['#6296BC', currentYear],
      font: '#6296BC',
    },
    customBarChart: {
      chart: ['#6296BC', currentYear],
      font: '#6296BC',
      secondaryFont: '#69936c',
    },
    customPieChart: {
      chart: ['#6296BC', currentYear],
      font: '#6296BC',
      secondaryFont: '#69936c',
    },
    customLineChart: {
      chart: ['#6296BC', currentYear],
      font: '#6296BC',
      secondaryFont: '#69936c',
    },
    drilldown: {
      chart: ['#6296BC', currentYear],
      font: '#6296BC',
      secondaryFont: '#69936c',
    },
    gauge: {
      chart: ['#6296BC', '#8BAEC7', '#BCCAD4'],
      background: '#dddddd',
      font: '',
    },
    inventoryRange: {
      chart: ['#6296BC', currentYear, '#4E944F', '#4f7c9c'],
      font: '#6296BC',
      secondaryFont: '#69936c',
    },
    salesRange: {
      chart: ['#6296BC', currentYear],
      font: '#6296BC',
      secondaryFont: '#69936c',
    },
    salesYTD: {
      chart: [currentYear, '#6296BC'],
      font: '#6296bc',
    },
  },
  forecast: {
    barChart: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    customBarChart: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    customPieChart: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    customLineChart: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    drilldown: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    gauge: {
      chart: ['#734e84', '#8f64a3', '#8e759a'],
      font: '#8f64a3',
      background: '#dddddd',
    },
    inventoryRange: {
      chart: ['#8f64a3', currentYear, '#4E944F', '#734e84'],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    salesRange: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
      secondaryFont: '#734e84',
    },
    salesYTD: {
      chart: ['#8f64a3', currentYear],
      font: '#8f64a3',
    },
  },
  fulfill: {
    barChart: {
      chart: ['#ba3636', currentYear],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    customBarChart: {
      chart: ['#ba3636', currentYear],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    customPieChart: {
      chart: ['#ba3636', currentYear],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    customLineChart: {
      chart: ['#ba3636', currentYear],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    drilldown: {
      chart: ['#ba3636', currentYear],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    gauge: {
      chart: ['#ba3636', '#c45555', '#be6363'],
      font: '#ba3636',
      background: '#dddddd',
    },
    inventoryRange: {
      chart: ['#ba3636', currentYear, '#4E944F', '#af5810'],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    salesRange: {
      chart: ['#ba3636', currentYear],
      font: '#ba3636',
      secondaryFont: '#952b2b',
    },
    salesYTD: {
      chart: ['#ba3636', currentYear],
      font: '#952b2b',
    },
  },
  produce: {
    barChart: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    customBarChart: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    customPieChart: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    customLineChart: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    drilldown: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    gauge: {
      chart: ['#db6e14', '#d37a31', '#d89761'],
      font: '#db6e14',
      background: '#dddddd',
    },
    inventoryRange: {
      chart: ['#db6e14', currentYear, '#4E944F', '#af5810'],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    salesRange: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
      secondaryFont: '#b96a29',
    },
    salesYTD: {
      chart: ['#db6e14', currentYear],
      font: '#db6e14',
    },
  },
  restock: {
    barChart: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    customBarChart: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    customPieChart: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    customLineChart: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    drilldown: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    gauge: {
      chart: ['#479a4f', '#63b76b', '#7bc783'],
      font: '#3e8745',
      background: '#dddddd',
    },
    inventoryRange: {
      chart: ['#63b76b', currentYear, '#4E944F', '#5b5b5b'],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    salesRange: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
      secondaryFont: '#479a4f',
    },
    salesYTD: {
      chart: ['#3e8745', currentYear],
      font: '#3e8745',
    },
  },
};

export default Palette;
