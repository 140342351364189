import {
  getObjectArrayIndex,
  removeObjectArrayByProperty,
  searchObject,
  updateObjectArrayByProperty,
} from 'helpers/object';
import { SelectChangeEvent } from '@mui/material';
import { sortAlphabetically } from 'helpers/array';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FilterSelection from './FilterSelection';
import RemoveIcon from '@mui/icons-material/Remove';

import styles from '../../../styles/components/fields/where-filter.module.scss';
import { generateRandomString } from 'helpers/string';

interface WhereFilterProps {
  disabled: boolean;
  filters: any;
  filterConfig: WhereFilter;
  onFilterUpdate: (value: WhereFilter) => void;
}

const WhereFilter = ({
  disabled = true,
  filters,
  filterConfig,
  onFilterUpdate,
}: WhereFilterProps): JSX.Element => {
  const [optionsList, setOptionsList] = useState<Maybe<string[]>>(null);
  const count = filterConfig.count || 0;
  const items = filterConfig.items || [];

  useEffect(() => {
    if (filters === null || filters.length === 0) return;
    const options = searchObject('name', 'where', filters)[0].inputFields;
    const filterList = options.map((option: any) => option.name);
    setOptionsList(sortAlphabetically(filterList));
  }, [filters]);

  function onClickAddFilter(): void {
    const newcount = count + 1;
    const randomString = generateRandomString();
    const newItems: FilterFieldType[] = [
      ...items,
      {
        id: `${randomString}`,
        condition: 'equal',
        field: '',
        name: '',
        value: '',
      },
    ];
    onFilterUpdate({
      count: newcount,
      items: newItems,
    });
  }

  function onChangeFilterSelection(e: SelectChangeEvent<string>): void {
    const name = e.target.name;
    const index = name.split('-')[1];

    const fieldIndex = getObjectArrayIndex('id', index, items);
    if (fieldIndex === null) return;

    const updateFilterField: FilterFieldType = {
      id: index,
      condition: items[fieldIndex].condition,
      field: e.target.value,
      name,
      value: '',
    };

    const updatedArray = updateObjectArrayByProperty(
      'id',
      index,
      updateFilterField,
      items
    );

    onFilterUpdate({
      count,
      items: updatedArray,
    });
  }

  function onInputValueChange(e: any): void {
    const name = e.target.name.split('-input')[0];
    const index = name.split('-')[1];

    const fieldIndex = getObjectArrayIndex('id', index, items);
    if (fieldIndex === null) return;

    const updateFilterField: FilterFieldType = {
      id: index,
      condition: items[fieldIndex].condition,
      field: items[fieldIndex].field,
      name,
      value: e.target.value,
    };

    const updatedArray = updateObjectArrayByProperty(
      'id',
      index,
      updateFilterField,
      items
    );

    onFilterUpdate({
      count,
      items: updatedArray,
    });
  }

  function onConditionChange(event: any, conditionValue: Condition): void {
    const name = event.target.name.split('-condition')[0];
    const index = name.split('-')[1];
    const fieldIndex = getObjectArrayIndex('id', index, items);
    if (fieldIndex === null) return;

    const updateFilterField: FilterFieldType = {
      id: index,
      condition: conditionValue,
      field: items[fieldIndex].field,
      name,
      value: items[fieldIndex].value,
    };

    const updatedArray = updateObjectArrayByProperty(
      'id',
      index,
      updateFilterField,
      items
    );

    onFilterUpdate({
      count,
      items: updatedArray,
    });
  }

  function onClickRemoveFilter(e: any): void {
    const id = e.target.closest('span').dataset.id;
    const updatedItems = removeObjectArrayByProperty('id', id, items);
    const newcount = count - 1;
    onFilterUpdate({
      count: newcount,
      items: updatedItems,
    });
  }

  function handleClearFilter(e: any): void {
    e.preventDefault();
    onFilterUpdate({
      count: 0,
      items: [],
    });
  }

  return (
    <div className={styles.whereFilter}>
      <div className={styles.header}>
        <h2>Filter Fields:</h2>
        {count !== 0 && (
          <button type="button" onClick={handleClearFilter}>
            clear
          </button>
        )}
      </div>

      <div className={styles.filterItemWrapper}>
        {items.length > 0 &&
          items.map((item: FilterFieldType, index: number) => {
            return (
              <div className={styles.item} key={index}>
                <FilterSelection
                  name={`filter-${item.id}`}
                  filters={optionsList}
                  onConditionChange={onConditionChange}
                  onSelectChange={onChangeFilterSelection}
                  onInputChange={onInputValueChange}
                  conditionValue={item.condition}
                  selectValue={item.field !== '' ? item.field : null}
                  inputValue={item.value !== '' ? item.value : null}
                />
                <span
                  className={styles.removeFilter}
                  onClick={onClickRemoveFilter}
                  data-id={item.id}
                >
                  <RemoveIcon fontSize="inherit" />
                </span>
              </div>
            );
          })}
      </div>

      {count < 4 && (
        <button
          disabled={disabled}
          type="button"
          className={styles.addFilterBtn}
          onClick={onClickAddFilter}
        >
          <AddIcon fontSize="inherit" />
        </button>
      )}
    </div>
  );
};

export default WhereFilter;
