import { renameObjectKey } from 'helpers/object';
import useColorPalette from 'hooks/useColorPalette';
import { defaultGraphQLQueryConfig, GraphQLQueryConfig } from 'hooks/useData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatToDollar } from '../../helpers/format';
import { PaletteConfig } from '../../palette';
import { RootState } from '../../store';
import DrillDownChart from './DrillDownChart';

const componentTitle = 'Sales By Region';
const valueAxisText = 'Sales in Dollars';

const SalesByRegion = ({ redrawKey }: DashboardComponentProps): JSX.Element => {
  const calendar = useSelector((state: RootState) => state.calendar);
  const { themePalette } = useColorPalette();

  const { dateRange } = useSelector((state: RootState) => state.query);
  const [subTitle, setSubTitle] = useState<string>('');
  const [currentMonth] = useState<number | null>(calendar.props.month);
  const [currentWeek] = useState<number | null>(calendar.props.week);
  const [calendarSettings, setCalendarSettings] =
    useState<CalendarSettingsType>({
      month: null,
      week: null,
    });

  const [chartSettings, setChartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    month: calendar.props.month,
    week: calendar.props.week,
  });

  useEffect(() => {
    switch (dateRange) {
      case 'Week-To-Date':
        setSubTitle('Week-To-Date');
        setCalendarSettings({
          ...calendarSettings,
          month: null,
          week: currentWeek,
        });
        break;
      case 'Year-To-Date':
        setSubTitle('Year-To-Date');
        setCalendarSettings({
          ...calendarSettings,
          month: null,
          week: null,
        });
        break;
      default:
        setSubTitle('Month-To-Date');
        setCalendarSettings({
          ...calendarSettings,
          month: currentMonth,
          week: null,
        });
        break;
    }
  }, [dateRange, currentMonth, currentWeek]);

  /**
   * Loads initial chartSettings for the component to work.
   */
  useEffect(() => {
    const settings = {
      ...chartSettings,
      argField: 'regionDescription',
      month:
        calendarSettings.month !== null
          ? (calendarSettings.month as number)
          : null,
      query: 'salesByRegionOld',
      valueFields: [
        { field: 'tyRetail' },
        { field: 'lyRetail' },
        { field: 'regionID' },
      ],
      week:
        calendarSettings.week !== null
          ? (calendarSettings.week as number)
          : null,
    };
    setChartSettings(settings);
  }, [calendarSettings]);

  /**
   * Drilldown Event.
   */
  const didDrilldown = (parentItemID: string): void => {
    setChartSettings({
      ...chartSettings,
      argField: 'marketDescription',
      month:
        calendarSettings.month !== null
          ? (calendarSettings.month as number)
          : null,
      regionID: parentItemID,
      query: 'salesByMarketOld',
      valueFields: [
        { field: 'tyRetail' },
        { field: 'lyRetail' },
        { field: 'marketID' },
      ],
      week:
        calendarSettings.week !== null
          ? (calendarSettings.week as number)
          : null,
    });
  };

  /**
   * Back Button Event.
   */
  const didClickBackBtn = (level: number, currentParentID: string): void => {
    if (level === 0) {
      setChartSettings({
        ...chartSettings,
        argField: 'regionDescription',
        month:
          calendarSettings.month !== null
            ? (calendarSettings.month as number)
            : null,
        parentID: '',
        query: 'salesByRegionOld',
        regionID: '',
        valueFields: [
          { field: 'tyRetail' },
          { field: 'lyRetail' },
          { field: 'regionID' },
        ],
        week:
          calendarSettings.week !== null
            ? (calendarSettings.week as number)
            : null,
      });
    } else {
      setChartSettings({
        ...chartSettings,
        argField: 'marketDescription',
        month:
          calendarSettings.month !== null
            ? (calendarSettings.month as number)
            : null,
        parentID: currentParentID,
        query: 'salesByMarketOld',
        regionID: currentParentID,
        valueFields: [
          { field: 'tyRetail' },
          { field: 'lyRetail' },
          { field: 'regionID' },
        ],
        week:
          calendarSettings.week !== null
            ? (calendarSettings.week as number)
            : null,
      });
    }
  };

  /**
   * Data Format Event.
   * Grabs raw data coming from graphQL query result and
   * formats it based on given query, then passes it back
   * to DrilldownChart component to display.
   */
  const didGetData = (
    data: any,
    currentRegionID: string,
    level: any
  ): DrilldownDataResponse => {
    let updatedData: any[] = [];

    if (chartSettings.query === 'salesByRegionOld' && data.length) {
      data.forEach((dataItem: any) => {
        const object = renameObjectKey(chartSettings.argField, 'arg', dataItem);
        object.arg = `Region ${object.arg}`;
        object.parentID = currentRegionID;
        object.id = object.regionID;
        delete object.regionID;
        updatedData = [...updatedData, object];
      });
    }
    if (chartSettings.query === 'salesByMarket' && data.length) {
      data.forEach((dataItem: any) => {
        const object = renameObjectKey(chartSettings.argField, 'arg', dataItem);
        object.parentID = currentRegionID;
        object.id = object.marketID;
        delete object.marketID;
        updatedData = [...updatedData, object];
      });

      level.push({
        key: level.length + 1,
        currentRegionID,
      });
    }

    return { data: updatedData, level };
  };

  const customizeToolTip = (arg: any): CustomToolTipType => {
    return {
      text: `<div>${formatToDollar(arg.valueText)}</div>`,
    };
  };

  return (
    <div className="sales-by-region">
      {chartSettings !== null ? (
        <DrillDownChart
          redrawKey={redrawKey}
          classes={['paper-box-t-l', 'paper-box-t-large']}
          chartSettings={chartSettings}
          didDrilldownCallback={didDrilldown}
          didClickBackBtnCallback={didClickBackBtn}
          didGetDataCallback={didGetData}
          palette={themePalette.drilldown as PaletteConfig}
          rotated={true}
          sideBySide={true}
          subTitle={subTitle}
          title={componentTitle}
          toolTipCallback={customizeToolTip}
          valueAxisTitle={valueAxisText}
          valueFieldOne="tyRetail"
          valueFieldTwo="lyRetail"
        />
      ) : null}
    </div>
  );
};

export default SalesByRegion;
