import {
  setCurrentDashboard,
  setDashboards,
} from 'store/slices/dashboardSlice';
// import dashboardComparator from 'data/DashboardComparator';
// import { DashboardsContext } from 'auth/providers/DashboardsProvider';
import db from 'data/db';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import useAccessibleProducts from './loaders/useAccessibleProducts';
import useRemoteDashboards from './loaders/useRemoteDashboards';

interface UseSyncedDashboardsAPI {
  loadAllDashboards: () => Promise<void>;
  saveNewDashboard: (newDashboard: SFDashboardInit) => Promise<SFDashboard>;
  updateDashboard: (dashboard: SFDashboard) => Promise<SFDashboard>;
  deleteDashboard: (dashboardId: string) => Promise<void>;
  apiReady: boolean;
}

/** Exposes API methods that handle CRUD operations for dashboards,
 * while transparently syncing state between IndexedDB and the remote server,
 * and updating redux store */
function useSyncedDashboardAPI(): UseSyncedDashboardsAPI {
  const dispatch = useDispatch();

  // console.warn(`Pretty sure this needs to change. Could cause a race condition.
  // Storing asynchronously loaded state in the hook, but returning an
  // imperative API that depends on that state is probably a bad idea.`);
  const { dashboards, currentDashboardId } = useSelector(
    (state: RootState) => state.dashboard
  );
  const { products, loaded: loadedUserProducts } = useAccessibleProducts();

  const {
    // Using underscore prefix so the function names can be re-used and returned from the hook
    fetchAllDashboards: _fetchAllDashboards,
    saveNewDashboard: _saveNewDashboard,
    updateDashboard: _updateDashboard,
    deleteDashboard: _deleteDashboard,
    // refetch: refetchAllDashboards,
    loading,
  } = useRemoteDashboards();

  async function syncRemoteAndIndexedDBDashboards(
    _remoteDashboards: SFDashboard[],
    idbDashboards: SFDashboard[]
  ): Promise<void> {
    // Get ids of dashboards in IndexedDB that *don't* exist in remote dashboards
    const idbDashboardsToDelete = idbDashboards
      .filter((idbDashboard) => {
        return !_remoteDashboards.some(
          (remoteDashboard) => remoteDashboard.id === idbDashboard.id
        );
      })
      .map((dashboard) => dashboard.id);

    // Generate list of dashboard ids that need syncing
    const remoteDashboardsToSync = _remoteDashboards
      .filter((remoteDashboard) => {
        const idbDashboard = idbDashboards.find(
          (_idbDashboard) => _idbDashboard.id === remoteDashboard.id
        );

        // If the dashboard doesn't exist in IndexedDB, then it needs to be synced
        if (!idbDashboard) {
          return true;
        }

        // If the dashboard exists in IndexedDB, but the layoutHash is different, then it needs to be synced
        return isDashboardUpdated(remoteDashboard, idbDashboard);
      })
      .map((dashboard) => dashboard.id);

    try {
      // Delete dashboards from IndexedDB that don't exist in remote dashboards
      const deletePromises = idbDashboardsToDelete.map(
        db.sfDeleteDashboard.bind(db)
      );
      await Promise.all(deletePromises);

      // Sync dashboards from remote dashboards to IndexedDB
      const syncPromises = remoteDashboardsToSync.map((dashboardId) => {
        // Find the remote version of the dashboard (already fetched)
        const remoteDashboard = _remoteDashboards.find(
          (_remoteDashboard) => _remoteDashboard.id === dashboardId
        )!;

        // Update in IDB
        return db.sfUpsertDashboard(remoteDashboard);
      });

      await Promise.all(syncPromises);
    } catch (e) {
      console.error('There was an error syncing dashboards: ', e);
    }
  }

  async function insertNewDashboardIntoIndexedDB(
    dashboard: SFDashboard
  ): Promise<void> {
    try {
      await db.sfInsertDashboard(dashboard);
    } catch (e) {
      console.error('Error inserting new dashboard into IndexedDB: ', e);
    }
  }

  function isDashboardUpdated(a: SFDashboard, b: SFDashboard): boolean {
    return a.id === b.id && a.layoutHash !== b.layoutHash;
  }

  /** Returns all dashboards (in the background, ensures dashboards are synced
   * from remote to IndexedDB and redux.) */
  async function loadAllDashboards(): Promise<void> {
    // Load remote
    const _dashboards = await _fetchAllDashboards();

    // Load IndexedDB
    const allIndexedDBDashboards = await db.sfGetAllDashboards();
    // Sync between the two
    await syncRemoteAndIndexedDBDashboards(_dashboards, allIndexedDBDashboards);

    dispatch(setDashboards(_dashboards));
  }

  async function saveNewDashboard(
    dashboard: SFDashboardInit
  ): Promise<SFDashboard> {
    if (products === null || dashboards === null) {
      throw new Error(
        "Can't save new dashboard before dashboards and products are loaded."
      );
    }

    const product =
      products.find(
        (_product) =>
          _product.code === dashboard.productCode ||
          _product.code === dashboard.productName.toLowerCase()
      ) || null;

    if (product === null) {
      throw new Error('No product found with name: ' + dashboard.productCode);
    }

    // Save remote
    const result = await _saveNewDashboard(product.id, dashboard);
    // Save IndexedDB
    await insertNewDashboardIntoIndexedDB(result);

    const newDashboards = [...dashboards, result];
    dispatch(setDashboards(newDashboards));
    dispatch(setCurrentDashboard(result.id));
    return result;
  }

  async function updateDashboard(dashboard: SFDashboard): Promise<SFDashboard> {
    if (dashboards === null) {
      throw new Error("Can't update dashboard before dashboards are loaded.");
    }

    // Update remote
    const updatedDashboard = await _updateDashboard(dashboard);
    // Update IndexedDB
    await db.sfUpsertDashboard(updatedDashboard);

    const indexOfUpdatedDashboardInRedux = dashboards.findIndex(
      (_dashboard) => _dashboard.id === updatedDashboard.id
    );

    if (indexOfUpdatedDashboardInRedux === -1) {
      throw new Error("Edited dashboard doesn't exist in local state.");
    }

    // Replace the edited dahsboard in redux state
    // The dashboard may already be up to date from local management, but it
    // can't hurt to ensure.
    const updatedDashboards = [...dashboards];
    updatedDashboards[indexOfUpdatedDashboardInRedux] = updatedDashboard;

    // Update redux state
    dispatch(setDashboards(updatedDashboards));

    return updatedDashboard;
  }

  async function deleteDashboard(dashboardId: string): Promise<void> {
    if (dashboards === null) {
      throw new Error("Can't delete dashboard before dashboards are loaded.");
    }

    // Delete remote
    await _deleteDashboard(dashboardId);
    // Delete IndexedDB
    await db.sfDeleteDashboard(dashboardId);

    // Update redux state
    const indexOfDashboardInRedux = dashboards!.findIndex(
      (_dashboard) => _dashboard.id === dashboardId
    );

    if (indexOfDashboardInRedux === -1) {
      throw new Error("Deleted dashboard doesn't exist in local state.");
    }

    const updatedDashboards = [...dashboards!];
    updatedDashboards.splice(indexOfDashboardInRedux, 1);

    if (dashboardId === currentDashboardId && updatedDashboards.length > 0) {
      // Deleted dashboard being currently viewed, so select new one
      console.warn(
        'Need to set this up to select a new dashboard from the same product.'
      );
      const newDashboardId = updatedDashboards[0].id;
      dispatch(setCurrentDashboard(newDashboardId));
    }

    dispatch(setDashboards(updatedDashboards));
  }

  return {
    loadAllDashboards,
    saveNewDashboard,
    updateDashboard,
    deleteDashboard,
    apiReady: loadedUserProducts,
  };
}

export default useSyncedDashboardAPI;
