import { currentPathArray } from './../helpers/url';
import Palette, { PaletteDetail, ThemePalette } from 'palette';
import { useMemo } from 'react';
import useDashboard, { DashboardActions } from './useDashboard';

export interface ColorPaletteActions {
  themePalette: PaletteDetail;
}

const useColorPalette = (): ColorPaletteActions => {
  const dashboard: DashboardActions = useDashboard();

  /**
   * Helps set the color palette for a given component.
   */
  const createPalette = useMemo(() => {
    const currentProductCode = currentPathArray()[0];

    if (
      typeof currentProductCode !== 'undefined' &&
      typeof Palette !== 'undefined'
    ) {
      const _currentProductCode = currentProductCode as keyof ThemePalette;
      const _palette = Palette[_currentProductCode] as PaletteDetail;
      return _palette;
    }
    return null;
  }, [dashboard]);

  /**
   * Sets color palette for component base on theme.
   */
  const themePalette = useMemo((): PaletteDetail => {
    if (createPalette !== null) {
      return createPalette;
    }

    return Palette.analyze as PaletteDetail;
  }, [createPalette]);

  return {
    themePalette,
  };
};

export default useColorPalette;
