import { gql } from '@apollo/client';

/** Returns ids and names of products to which the current user has access within ANALYZE
 * - Note - these are defined in the ANALYZE database (not SFAuth). */
export const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;
