import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import MonthlySales from './MonthlySales';
import WeeklySales from './WeeklySales';
import YearlySales from './YearlySales';

import { Paper } from '@mui/material';
import styles from '../../../styles/components/charts/range-sales.module.scss';
import '../../../styles/material-ui/paper.scss';

const RangeSales = ({ redrawKey }: DashboardComponentProps): JSX.Element => {
  const { dateRange } = useSelector((state: RootState) => state.query);

  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-r',
  ]);

  const Component = useMemo(() => {
    switch (dateRange) {
      case 'Week-To-Date':
        return WeeklySales;
      case 'Year-To-Date':
        return YearlySales;
      default:
        return MonthlySales;
    }
  }, [dateRange]);

  return (
    <div className={styles.rangeSales}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <Component redrawKey={redrawKey} />
      </Paper>
    </div>
  );
};

export default RangeSales;
