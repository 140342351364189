import { monthListShort, weekList } from './date';
import { setNumberTwoDecimals } from './numbers';
import { searchObject } from './object';

export type DataGridFormatType = {
  type: string;
  precision: number;
};

/**
 * Formats date for data grid that the DataGrid doesn't support.
 */
export function formatDataGridData(configuration: any, data: any): any[] {
  const mutableData = [...data];
  for (const i in mutableData) {
    const item = data[i];
    for (const key in item) {
      const value = item[key];
      const config = searchObject('field', key, configuration)[0];
      if (typeof value === 'undefined' || typeof config === 'undefined')
        continue;

      if (!config.format) continue;

      switch (config.format) {
        case 'percent':
          mutableData[i][key] = value / 100;
          break;
        case 'month':
          mutableData[i][key] = monthListShort[value - 1];
          break;
        case 'weekDay':
          mutableData[i][key] = weekList[value - 1];
          break;
      }
    }
  }

  return mutableData;
}

export function setDataGridDataType(format: any): Maybe<string> {
  switch (format) {
    case 'currency':
      return 'number';

    case 'date':
      return 'date';

    case 'percent':
      return 'percent';

    default:
      return null;
  }
}

export function setDataGridFormatType(format: any): Maybe<DataGridFormatType> {
  if (typeof format === 'undefined') return null;

  switch (format) {
    case 'currency':
      return {
        type: 'currency',
        precision: 2,
      };

    case 'percent':
      return {
        type: 'percent',
        precision: 1,
      };

    default:
      return null;
  }
}

/**
 * Format Cash Value with K M B T
 */
function formatCashValue(n: number): string | undefined {
  if (n < 1e3) return n.toString();
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
}

export function formatCash(
  n: number,
  addCashSymbol = true
): string | undefined {
  const isNegative = Math.sign(n) === -1;
  if (addCashSymbol) {
    return isNegative
      ? '-$' + formatCashValue(-1 * n)
      : `$${formatCashValue(n)}`;
  }
  return isNegative ? '-' + formatCashValue(-1 * n) : formatCashValue(n);
}

export function formatToDollar(value: number): string {
  const result = formatCash(value);
  return typeof result !== 'undefined' ? result : '';
}
