import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SFDashboardProductState {
  accessibleDashboardProducts: Maybe<SFDashboardProduct[]>;
}

const initialState: SFDashboardProductState = {
  accessibleDashboardProducts: null,
};

export const dashboardProductSlice = createSlice({
  name: 'dashboardProduct',
  initialState,
  reducers: {
    setAccessibleDashboardProducts: (
      state,
      action: PayloadAction<SFDashboardProduct[]>
    ) => {
      state.accessibleDashboardProducts = action.payload;
    },
  },
});

export const { setAccessibleDashboardProducts } = dashboardProductSlice.actions;
export default dashboardProductSlice.reducer;
