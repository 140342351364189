import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'store';
import { setCurrentDashboard } from 'store/slices/dashboardSlice';
import { setDateRange, setHideNav } from 'store/slices/queryStringSlice';
import { setCurrentRetailer } from 'store/slices/retailerSlice';

interface UseQueryStringSyncingAPI {
  dateRange: Maybe<string>;
  hideNav: boolean;
  currentRetailerId: Maybe<string>;
  currentDashboardId: Maybe<string>;
  loaded: boolean;
}

/** Sync redux store with url query string (and continue to do so
 * on changes) */
function useQueryStringSyncing(): UseQueryStringSyncingAPI {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loaded, setLoaded] = useState(false);

  const { dateRange, hideNav } = useSelector((state: RootState) => state.query);
  const { currentRetailerId } = useSelector(
    (state: RootState) => state.retailer
  );
  const { currentDashboardId } = useSelector(
    (state: RootState) => state.dashboard
  );

  // Sync url to redux on first load
  useEffect(() => {
    const _dateRange = searchParams.get('dateRange');
    const retailerId = searchParams.get('retailerId');
    const dashboardId = searchParams.get('dashboardId');
    const _hideNav = searchParams.get('hideNav');

    if (_hideNav) {
      dispatch(setHideNav(_hideNav === 'true'));
    }

    if (_dateRange) {
      dispatch(setDateRange(_dateRange));
    }

    if (retailerId) {
      dispatch(setCurrentRetailer(retailerId));
    }

    if (dashboardId) {
      dispatch(setCurrentDashboard(dashboardId));
    }

    setLoaded(true);
  }, []);

  // If redux state changes, sync back to url
  useEffect(() => {
    const updatedSearch = {
      // Spread onto object if value exists
      ...(dateRange && { dateRange }),
      ...(currentRetailerId && { retailerId: currentRetailerId }),
      ...(currentDashboardId && { dashboardId: currentDashboardId }),
      hideNav: `${hideNav}`,
    };
    setSearchParams(updatedSearch);
  }, [hideNav, dateRange, currentRetailerId, currentDashboardId]);

  return {
    dateRange: searchParams.get('dateRange'),
    hideNav: searchParams.get('hideNav') === 'true',
    currentRetailerId: searchParams.get('retailerId'),
    currentDashboardId: searchParams.get('dashboardId'),
    loaded,
  };
}

export default useQueryStringSyncing;
