import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { fieldBlacklist } from './AvailableFields';
import { humanizer } from 'helpers/string';
import { IntrospectiveQueryField } from 'helpers/query';
import { useEffect, useState } from 'react';
import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/order.module.scss';

interface OrderFilterProps {
  disabled: boolean;
  onChange: (value: string) => void;
  selectedFields: any[];
  value: string;
}

const OrderFilter = ({
  disabled = true,
  onChange,
  selectedFields,
  value,
}: OrderFilterProps): JSX.Element => {
  const orderItems = value.split('|');
  const [selectedField, setSelectedField] = useState<string>(
    orderItems[0] || '0'
  );
  const [direction, setDirection] = useState<Order>(
    (orderItems[1] as Order) || 'DESC'
  );

  useEffect(() => {
    onChange(`${selectedField}|${direction}`);
  }, [selectedField, direction]);

  function onFieldChange(event: SelectChangeEvent<string>): void {
    setSelectedField(event.target.value);
  }

  function onDirectionChange(event: SelectChangeEvent<string>): void {
    setDirection(event.target.value as Order);
  }

  return (
    <div className={styles.order}>
      <h2>Sort By:</h2>
      <div className={styles.orderSelect}>
        <FormControl variant="outlined" className={muStyles.select}>
          <Select
            native
            disabled={selectedFields.length === 0 || disabled}
            value={selectedField}
            onChange={onFieldChange}
            inputProps={{
              name: `order-field`,
              id: `order-field`,
            }}
          >
            <option value="0">-- Field --</option>
            {selectedFields.map((field: any, index: number) => {
              if (fieldBlacklist.includes(field.field)) {
                return null;
              }

              return (
                <option key={index} value={field.field}>
                  {humanizer(field.field)}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={muStyles.select}>
          <Select
            disabled={selectedField === '0' || disabled}
            native
            value={direction.toUpperCase() || 'DESC'}
            onChange={onDirectionChange}
            inputProps={{
              name: `order-direction`,
              id: `order-direction`,
            }}
          >
            <option value="DESC">DESC</option>
            <option value="ASC">ASC</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default OrderFilter;
