export function generatedRandomNumber(): number {
  return Math.floor(Math.random() * 100);
}

export function setNumberTwoDecimals(value: number): number {
  return Math.round(value * 100) / 100;
}

/**
 * Subtract two values if result is negative return 1.
 **/
export function subtractValues(value1: number, value2: number): number {
  const result = value1 - value2;
  return result < 0 ? 1 : result;
}
