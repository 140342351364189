import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
  open: boolean;
}

const initialState: SidebarState = {
  open: true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = sidebarSlice.actions;
export default sidebarSlice.reducer;
