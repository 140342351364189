import DashboardSharp from '@mui/icons-material/DashboardSharp';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentDashboard } from 'store/selectors/dashboard';
import { RootState } from '../../store';
import styles from '../../styles/components/subcomponents/subheader.module.scss';
import DataControls from '../DataControls';

interface SubheaderProps {
  displayLegend?: boolean;
}

const SubHeader = ({ displayLegend = true }: SubheaderProps): JSX.Element => {
  const dashboard: DashboardActions = useDashboard();
  const { hideNav } = useSelector((state: RootState) => state.query);
  const [retailTag, setRetailTag] = useState<string>('');

  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );
  const retailerList = useSelector(
    (state: RootState) => state.retailer.retailerList
  );

  const currentDashboard = useSelector(selectCurrentDashboard);

  const title = useMemo(() => {
    if (currentDashboard !== null) {
      return currentDashboard.name;
    }
    return null;
  }, [currentDashboard]);

  // Set Retail display tag.
  useEffect(() => {
    const retailerResult = retailerList.find((retailer) => {
      if (retailer.id === currentRetailerId) {
        return retailer;
      }
      return '';
    });

    let name =
      typeof retailerResult?.name !== 'undefined' ? retailerResult?.name : '';
    name = name !== 'All Retailers' ? name : '';
    setRetailTag(name);
  }, [currentRetailerId, retailerList]);

  return (
    <>
      <div className={styles.subHeader}>
        <div className={styles.left}>
          {title !== null ? (
            <h1>
              <DashboardSharp className={styles.icon} />
              <span>
                {title} Dashboard
                {retailTag !== '' ? <span>{retailTag}</span> : null}
              </span>
            </h1>
          ) : null}
          {hideNav && <DataControls layout="horizontal" colorful={true} />}
        </div>

        <div className={styles.right}>
          {displayLegend ? (
            <div className={styles.legend}>
              <ul>
                <li className="current-year">
                  <span>Current Year</span>
                </li>
                <li className="previous-year">
                  <span>Previous Year</span>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SubHeader;
