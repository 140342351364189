import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface DisplayProductSectionProps {
  hiddenProducts: ProductCode[];
}

interface DisplayProductSection {
  display: boolean;
}

const useDisplayProductSection = ({
  hiddenProducts,
}: DisplayProductSectionProps): DisplayProductSection => {
  const currentTheme = useSelector(
    (state: RootState) => state.theme.selectedTheme
  );

  const setDisplay = useMemo(() => {
    return currentTheme !== null
      ? hiddenProducts.indexOf(currentTheme) === -1
      : false;
  }, [currentTheme, hiddenProducts]);

  return {
    display: setDisplay,
  };
};

export default useDisplayProductSection;
