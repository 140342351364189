const authUrl = process.env.REACT_APP_AUTH_URL as string;
const sessionManagerUrl = process.env.REACT_APP_SESSION_MANAGER_URI as string;
const redirectUri = process.env.REACT_APP_SESSION_MANAGER_URI as string;
const sfApiUrl = process.env.REACT_APP_SF_API_URL as string;
const appName = process.env.REACT_APP_APPLICATION_NAME as string;
const clientId = process.env.REACT_APP_CLIENT_ID as string;
const tenantId = process.env.REACT_APP_TENANT_ID as string;

export class SFAuth {
  private static _instance: SFAuth;

  // Singleton
  // eslint-disable-next-line
  private constructor() {}

  // eslint-disable-next-line
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async checkSession(): Promise<boolean> {
    const response = await fetch(
      `${sessionManagerUrl}/check-session?appName=${appName}`,
      {
        method: 'HEAD',
        credentials: 'include',
      }
    );

    if (response.status === 200) {
      return true;
    }

    if (response.status === 401) {
      return false;
    }

    const text = await response.text();

    const err = new Error('Error checking session');
    throw err;
  }

  async getCurrentUser(): Promise<Maybe<SFUser>> {
    const response = await fetch(`${sfApiUrl}/me`, {
      credentials: 'include',
    });
    if (response.status === 200) {
      const userInfo: SFUser = await response.json();
      return userInfo;
    }

    if (response.status === 401) {
      return null;
    }

    const err = new Error('Error checking session');
    throw err;
  }

  async getCompanyInfo(): Promise<Maybe<SFCompany>> {
    const response = await fetch(`${sfApiUrl}/company`, {
      credentials: 'include',
    });
    if (response.status === 200) {
      const companyInfo: SFCompany = await response.json();
      return companyInfo;
    }

    if (response.status === 401) {
      return null;
    }

    const err = new Error('Error checking session');
    throw err;
  }

  async getCompany(): Promise<Maybe<SFCompany>> {
    const response = await fetch(`${sfApiUrl}/company`, {
      credentials: 'include',
    });
    if (response.status === 200) {
      const company: SFCompany = await response.json();
      return company;
    }

    if (response.status === 401) {
      return null;
    }

    const err = new Error('Error fetching company.');
    throw err;
  }

  async getProductAccess(): Promise<Maybe<string[]>> {
    const response = await fetch(`${sfApiUrl}/product-access`, {
      credentials: 'include',
    });
    if (response.status === 200) {
      const productAccess = await response.json();
      return productAccess;
    }

    if (response.status === 401) {
      return null;
    }

    const err = new Error('Error fetching product access.');
    throw err;
  }

  async getProfilesForProduct(
    productName: string
  ): Promise<Maybe<SFAppProfile[]>> {
    const url = `${sfApiUrl}/profiles?productName=${productName}`;
    const response = await fetch(url, {
      credentials: 'include',
    });

    if (response.status === 200) {
      const profiles: SFAppProfile[] = await response.json();
      return profiles;
    }

    if (response.status === 401) {
      return null;
    }

    const err = new Error('Error checking session');
    throw err;
  }

  async getMobileAppsProfiles(): Promise<Maybe<MobileAppsProfile[]>> {
    const profiles = await this.getProfilesForProduct('mobile');
    if (!profiles) {
      return null;
    }

    return profiles as MobileAppsProfile[];
  }

  async getInventoryProfiles(): Promise<Maybe<InventoryProfile[]>> {
    const profiles = await this.getProfilesForProduct('inventory');
    if (!profiles) {
      return null;
    }

    return profiles as InventoryProfile[];
  }

  redirectToLogin(onSuccessReturnToPath = true): void {
    const params = new URLSearchParams({
      client_id: clientId,
      redirect_uri: `${redirectUri}/oauth-redirect`,
      response_type: 'code',
      scope: 'openid profile email offline_access',
      state: JSON.stringify({
        // ** Required **
        // appName parameter through state for oauth redirect
        // (so we can use apps.silverfern.app and still pivot on domain)
        appName,

        // ** Optional **
        // By default, return to current path after authentication; else return to root
        returnTo: onSuccessReturnToPath
          ? `${window.location.pathname}${window.location.search}`
          : '/',

        // ** Optional **
        // On dev, a port can be provided so that the redirect returns the user
        // back to the correct port after successful authentication.
        port: window.location.port,
      }),
    });
    const qs = params.toString();
    window.location.href = `${authUrl}/oauth2/authorize?${qs}`;
  }

  redirectToLogout(): void {
    const params = new URLSearchParams({
      client_id: clientId,
      tenantId,
      state: JSON.stringify({
        // ** Optional **
        // On dev, a port can be provided so that the redirect returns the user
        // back to the correct port after logging out.
        port: window.location.port,
      }),
    });
    const qs = params.toString();
    const logoutUrl = `${authUrl}/oauth2/logout?${qs}`;
    window.location.href = logoutUrl;
  }
}

const sfAuth = SFAuth.Instance;

export default sfAuth;
