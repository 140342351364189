import { gql } from '@apollo/client';

export const GET_ALL_DASHBOARDS = gql`
  query GetAllDashboards {
    dashboards(first: 100) {
      edges {
        node {
          id
          productID
          productName
          scope
          name
          layoutJson
          layoutHash
        }
      }
    }
  }
`;

export const GET_DASHBOARD_BY_ID = gql`
  query GetDashboardById($id: ID!) {
    dashboards(where: { id: { eq: $id } }) {
      edges {
        node {
          id
          productID
          productName
          scope
          name
          layoutJson
          layoutHash
        }
      }
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  mutation CreateDashboard(
    $name: String!
    $productID: ID!
    $scope: DashboardScope!
    $layoutJson: String!
  ) {
    createDashboard(
      name: $name
      productID: $productID
      scope: $scope
      layoutJson: $layoutJson
    ) {
      id
      productID
      productName
      scope
      name
      layoutJson
      layoutHash
    }
  }
`;

export const SAVE_DASHBOARD = gql`
  mutation SaveDashboard($id: ID!, $layoutJson: String!) {
    saveDashboard(id: $id, layoutJson: $layoutJson) {
      id
      productID
      productName
      scope
      name
      layoutJson
      layoutHash
    }
  }
`;

export const DELETE_DASHBOARD = gql`
  mutation DeleteDashboard($id: ID!) {
    deleteDashboard(id: $id) {
      success
    }
  }
`;

export const GET_DASHBOARDS_WITH_SCOPE = gql`
  query GetDashboardsWithScope($scope: DashboardScope!) {
    dashboards(where: { scope: { eq: $scope } }) {
      edges {
        node {
          id
          productID
          productName
          scope
          name
          layoutJson
          layoutHash
        }
      }
    }
  }
`;

export const GET_DASHBOARDS_FOR_PRODUCT = gql`
  query GetDashboardsForProduct($productID: ID!) {
    dashboards(where: { productID: { eq: $productID } }) {
      edges {
        node {
          id
          productID
          productName
          scope
          name
          layoutJson
          layoutHash
        }
      }
    }
  }
`;
