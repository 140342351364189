import { Grid, Typography } from '@mui/material';
import '../../styles/auth/components/ui/spinner.scss';

interface SpinnerProps {
  /**
   * The background color for the Spinner
   */
  background?: string;
  message?: string;
  active?: boolean;
}

export default function Spinner({
  active = false,
  message = '',
  background = '#ffffffaa',
}: SpinnerProps): JSX.Element {
  return (
    <Grid
      className={`spinner ${active ? '' : 'hidden'}`}
      style={{ backgroundColor: background }}
      direction="column"
      container
      justifyContent="center"
      alignItems="center"
    >
      <svg
        fill="#fff"
        height="30"
        viewBox="0 0 210 30"
        width="210"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="dot" cx="15" cy="15" r="15"></circle>
        <circle className="dot" cx="60" cy="15" r="15"></circle>
        <circle className="dot" cx="105" cy="15" r="15"></circle>
        <circle className="dot" cx="150" cy="15" r="15"></circle>
        <circle className="dot" cx="195" cy="15" r="15"></circle>
      </svg>
      {message && (
        <Typography variant="h3" className="message">
          {message}
        </Typography>
      )}
    </Grid>
  );
}
