import { removeThemeClass } from 'helpers/classes';
import React, { useEffect } from 'react';

interface UnthemeProps {
  children: React.ReactNode;
}

function Untheme({ children }: UnthemeProps): JSX.Element {
  useEffect(() => {
    removeThemeClass();
  }, []);

  return <>{children}</>;
}

export default Untheme;
