import { Spinner } from 'auth';
import SubHeader from 'components/SubComponents/SubHeader';
import {
  getComponentForDashboardItemType,
  isCustomDashboardItemType,
} from 'data/dashboard-item';
import deepEqual from 'deep-equal';
import { useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarState } from 'store/slices/sidebarSlice';
import { RootState } from '../../store';
import Widget from './Widget';

import 'react-grid-layout/css/styles.css';
import { selectCurrentDashboard } from 'store/selectors/dashboard';
import {
  setUpdatedLayout,
  setDashboardItemCustomState,
} from 'store/slices/dashboardSlice';
import styles from '../../styles/components/dashboard-layout/layout.module.scss';
import '../../styles/react-grid/react-grid.scss';
import useSyncedDashboardAPI from 'hooks/useSyncedDashboardAPI';

const ReactGridLayout = WidthProvider(RGL);

interface LayoutProps {
  navHidden: boolean;
}

const Layout = ({ navHidden }: LayoutProps): JSX.Element => {
  const dispatch = useDispatch();

  const currentDashboard = useSelector(selectCurrentDashboard);
  const { updateDashboard } = useSyncedDashboardAPI();

  const { isEditing: isEditingDashboard, isCreating: isCreatingDashboard } =
    useSelector((state: RootState) => state.dashboard);

  const [rowHeight] = useState<number>(17);

  const sidebarSettings: SidebarState = useSelector(
    (state: RootState) => state.sidebar
  );

  const onLayoutChange = (layout: RGL.Layout[]): void => {
    if (currentDashboard === null) {
      return;
    }

    const updatedDashboardLayout = layout.map((rglLayoutItem, i) => {
      const layoutItem = currentDashboard.layout[i];
      const updatedComponent = updateItemWithLayout(layoutItem, rglLayoutItem);
      return updatedComponent;
    });

    // Don't dispatch an update if the layout hasn't changed
    if (deepEqual(currentDashboard.layout, updatedDashboardLayout)) {
      return;
    }

    dispatch(setUpdatedLayout(updatedDashboardLayout));
  };

  function updateItemWithLayout(
    item: SFDashboardItem,
    layout: GridLayoutPosition
  ): SFDashboardItem {
    const updatedItem = {
      ...item,
      gridLayout: {
        x: layout.x,
        y: layout.y,
        w: layout.w,
        h: layout.h,
      },
    };
    return updatedItem;
  }

  async function handleWidgetCustomStateChange(
    itemID: string,
    customState: any
  ): Promise<void> {
    // If there is no current dashboard, update doesn't make sense
    if (currentDashboard === null) {
      return;
    }

    // Update dashboard in redux
    dispatch(setDashboardItemCustomState({ itemID, customState }));

    // If dashboard is being edited or created, don't update remotely;
    // that will happen automatically when the user saves the dashboard.
    if (isCreatingDashboard || isEditingDashboard) {
      return;
    }

    // Not editing/creating - auto-save behind the scenes
    // Make a copy and cast to SFDashboard - it's definitely of that type because
    // we're not editing or creating
    const updateableDashboard = structuredClone(
      currentDashboard
    ) as SFDashboard;

    const itemForUpdate = updateableDashboard.layout.find(
      (item) => item.id === itemID
    );

    if (
      itemForUpdate === undefined ||
      itemForUpdate.chartConfigurations === null
    ) {
      throw new Error('Widget configuration not found.');
    }

    itemForUpdate.chartConfigurations.customState = customState;
    try {
      await updateDashboard(updateableDashboard);
    } catch (e) {
      console.error('Error updating dashboard: ', e);
    }
  }

  // Extract layout data from all dashboard items
  function getLayout(items: SFDashboardItem[]): RGL.Layout[] {
    return items.map((item, i) => {
      const layoutItem = {
        i: `${i}`,
        x: item.gridLayout.x,
        y: item.gridLayout.y,
        w: item.gridLayout.w,
        h: item.gridLayout.h,
      };
      return layoutItem;
    });
  }

  const layoutItems: RGL.Layout[] = getLayout(currentDashboard?.layout || []);

  return (
    <div
      className={`${styles.layout} ${
        !navHidden && sidebarSettings.open ? styles.drawerOpen : ''
      }`}
    >
      <SubHeader />
      <div
        className={`${styles.layoutContent} ${
          isEditingDashboard || isCreatingDashboard ? styles.isEditable : ''
        }`}
      >
        {currentDashboard !== null && currentDashboard.layout.length > 0 ? (
          <>
            <ReactGridLayout
              className={styles.responsiveLayout}
              layout={layoutItems}
              isDraggable={isEditingDashboard || isCreatingDashboard}
              isResizable={isEditingDashboard || isCreatingDashboard}
              isBounded={true}
              measureBeforeMount={false}
              // southeast resize handle
              resizeHandles={['se']}
              rowHeight={rowHeight}
              onLayoutChange={onLayoutChange}
              useCSSTransforms
              style={{ transitionDuration: `2000` }}
            >
              {currentDashboard.layout.map((item, i) => {
                const Component = getComponentForDashboardItemType(item.type);
                // If new item type has been added, the Component may be `null`.
                // Just skip over for now.
                if (Component === null) {
                  return <></>;
                }
                const isCustom = isCustomDashboardItemType(item.type);

                // Key to prompt chart redraw if anything about its layout changes
                const redrawKey = JSON.stringify({
                  w: item.gridLayout.w,
                  h: item.gridLayout.h,
                });

                return (
                  <div key={`${i}`} className={styles.widgetItem}>
                    <Widget
                      itemID={item.id}
                      editable={isEditingDashboard || isCreatingDashboard}
                      configurable={isCustom}
                    >
                      <Component
                        itemID={item.id}
                        redrawKey={redrawKey}
                        onCustomStateChange={(customState: any) =>
                          handleWidgetCustomStateChange(item.id, customState)
                        }
                      />
                    </Widget>
                  </div>
                );
              })}
            </ReactGridLayout>
          </>
        ) : (
          <>
            {!isEditingDashboard && !isCreatingDashboard && (
              <Spinner active={true} background={'clear'} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Layout;
