import { Paper } from '@mui/material';
import { useState } from 'react';
import styles from '../../styles/components/charts/production-to-date.module.scss';

const ProductionToDate = (): JSX.Element => {
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-t-large',
  ]);

  return (
    <div className={styles.productionToDate}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <div className={styles.heading}>
          <h2>Production To-Date</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.topDisplay}>
            <div className={styles.amount}>
              <p>
                <span>80%</span>
              </p>
            </div>
            <div className={styles.indicator}>
              <div className={styles.down}></div>
            </div>
          </div>
          <div className={styles.bottomDisplay}>
            <div className={styles.total}>
              <p>
                <span>150k</span>
                <span>units</span>
              </p>
            </div>
            <span className={styles.divider}></span>
            <div className={styles.remaining}>
              <p>
                <span>1M</span>
                <span>units</span>
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default ProductionToDate;
