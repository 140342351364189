import BarChartIcon from '@mui/icons-material/BarChart';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import { useMemo } from 'react';

import {
  getCustomItemNamesList,
  getDashboardItemTypeFromName,
  getDefaultGridLayoutFromDashboardName,
  getPrebuiltItemNamesList,
  isCustomDashboardItemType,
} from 'data/dashboard-item';
import { useSelector } from 'react-redux';
import { selectCurrentDashboard } from 'store/selectors/dashboard';
import styles from '../../styles/components/sidebar/available-chart-list.module.scss';
import { generateRandomString } from 'helpers/string';

const AvailableChartList = (): JSX.Element => {
  const dashboard: DashboardActions = useDashboard();

  const currentDashboard = useSelector(selectCurrentDashboard);

  const prebuiltItemNames: string[] = useMemo(() => {
    return getPrebuiltItemNamesList();
  }, []);

  const customItemNames: string[] = useMemo(() => {
    return getCustomItemNamesList();
  }, []);

  function canItemBeSelected(itemName: string): boolean {
    const itemType = getDashboardItemTypeFromName(itemName);

    if (currentDashboard === null) {
      return false;
    }

    // Custom items can always be selected/reused
    if (customItemNames.includes(itemName)) {
      return true;
    }

    // Prebuilt - check if it's already in use
    const itemIsInUse = currentDashboard.layout.some(
      (item) => item.type === itemType
    );

    return !itemIsInUse;
  }

  function didSelectDashboardItem(itemName: string): void {
    console.log('Selected item name: ', itemName);
    if (!canItemBeSelected(itemName)) {
      return;
    }

    const itemType = getDashboardItemTypeFromName(itemName);
    const gridLayout = getDefaultGridLayoutFromDashboardName(itemName);
    const isCustom = isCustomDashboardItemType(itemType);

    const newDashboardItem: SFDashboardItem = {
      id: `${itemType}${isCustom ? '_' + generateRandomString() : ''}`,
      type: itemType,
      gridLayout,
      chartConfigurations: null,
    };

    dashboard.addDashboardItem(newDashboardItem);
  }

  return (
    <div className={styles.availableChartList}>
      <div className={styles.chartList}>
        <ul>
          {prebuiltItemNames.map((name: string, index: number) => {
            const itemSelectable = canItemBeSelected(name);
            return (
              <DashboardItemListItem
                key={`${index}`}
                itemName={name}
                selectable={itemSelectable}
                onClick={() => didSelectDashboardItem(name)}
              />
            );
          })}
        </ul>
        <ul>
          {customItemNames.map((name: string, index: number) => {
            const itemSelectable = canItemBeSelected(name);
            return (
              <DashboardItemListItem
                key={`${index}`}
                itemName={name}
                selectable={itemSelectable}
                onClick={() => didSelectDashboardItem(name)}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

interface DashboardItemListItemProps {
  itemName: string;
  selectable: boolean;
  onClick: () => void;
}
function DashboardItemListItem({
  itemName,
  selectable,
  onClick,
}: DashboardItemListItemProps): JSX.Element {
  return (
    <li className={selectable ? '' : styles.used} onClick={onClick}>
      <BarChartIcon />
      {itemName}
    </li>
  );
}

export default AvailableChartList;
