import DashboardIcon from '@mui/icons-material/Dashboard';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ExpandIcon from './ExpandIcon';

interface DashboardListProps {
  className: string;
  onDoubleClick(e: any): Promise<void>;
  didSelectDashboard: (dashboardId: string) => void;
  isOpen: boolean;
  title?: string;
  dashboards: SFDashboard[];
  currentDashboardId: Maybe<string>;
}

const DashboardList = ({
  className,
  onDoubleClick,
  didSelectDashboard,
  isOpen,
  title,
  dashboards,
  currentDashboardId,
}: DashboardListProps): JSX.Element => {
  const { isCreating } = useSelector((state: RootState) => state.dashboard);
  return (
    <>
      {dashboards !== null && dashboards.length ? (
        <div className={className}>
          <div onDoubleClick={onDoubleClick}>
            <ExpandIcon open={isOpen} />
            <span>{title}</span>
          </div>
          <ul>
            {dashboards.map((dashboard, i) => {
              return (
                <li
                  key={i}
                  onClick={() => didSelectDashboard(dashboards[i].id)}
                  data-selected={
                    !isCreating && dashboard.id === currentDashboardId
                  }
                >
                  <DashboardIcon />
                  <span>{dashboard.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default DashboardList;
