/**
 * write a functional component that requires authentication using the AuthContext, and returns a spinner if the session hasn't been checked yet.
 */

import { Spinner } from 'auth';
import { AuthContext } from 'auth/providers/AuthProvider';
import React, { useContext } from 'react';

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { sessionChecked, loggedIn } = useContext(AuthContext);

  return (
    <>{sessionChecked && loggedIn ? children : <Spinner active={true} />}</>
  );
};

export default RequireAuth;
