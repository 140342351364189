import SettingsIcon from '@mui/icons-material/Settings';
import { ClickAwayListener } from '@mui/material';
import useModal, { ModalActions } from 'hooks/useModal';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentDashboard } from 'store/selectors/dashboard';
import { setChartConfigState } from 'store/slices/chartConfigSlice';
import { setUpdatedLayout } from 'store/slices/dashboardSlice';
import { RootState } from '../../store';
import styles from '../../styles/components/dashboard-layout/widget.module.scss';

interface WidgetProps {
  itemID: Maybe<string>;
  editable: boolean;
  configurable: boolean;
  children: React.ReactNode;
}

const Widget = ({
  itemID,
  editable,
  configurable,
  children,
}: WidgetProps): JSX.Element => {
  const dispatch = useDispatch();
  const modal: ModalActions = useModal();
  const chartConfig = useSelector((state: RootState) => state.chartConfig);

  const currentDashboard = useSelector(selectCurrentDashboard);

  const [configMenuActive, setConfigMenuActive] = useState<boolean>(false);

  function editSettingsClick(): void {
    // No editing default dashboard
    if (currentDashboard === null || currentDashboard.isDefault) {
      return;
    }

    setConfigMenuActive(true);
  }

  /**
   * Remove chart from dashboard layout.
   */
  function onClickRemove(): void {
    if (currentDashboard === null) {
      return;
    }

    const updatedLayout = [...currentDashboard.layout];
    const removedIndex = updatedLayout.findIndex((item) => item.id === itemID);
    updatedLayout.splice(removedIndex, 1);
    dispatch(setUpdatedLayout(updatedLayout));
  }

  function closeConfigMenu(): void {
    setConfigMenuActive(false);
  }

  /**
   * Brings up modal with chart configuration form.
   */
  const configureClick = useCallback(() => {
    modal.setState({
      ...modal.state,
      dashboardItemID: itemID,
      open: true,
      type: 'editConfig',
    });

    // dispatch(
    //   setChartConfigState({
    //     ...chartConfig,
    //   })
    // );
  }, [modal, chartConfig]);

  return (
    <div className={styles.widget}>
      {editable ? (
        <>
          <ClickAwayListener onClickAway={closeConfigMenu}>
            <div className={styles.edit}>
              <SettingsIcon onClick={editSettingsClick} fontSize="small" />
            </div>
          </ClickAwayListener>
          <div
            className={`${styles.editMenu} ${
              configMenuActive ? styles.active : ''
            }`}
          >
            <ul>
              <li onClick={onClickRemove}>Remove</li>
              {configurable && <li onClick={configureClick}>Configure</li>}
              <li onClick={closeConfigMenu}>Cancel</li>
            </ul>
          </div>
        </>
      ) : null}
      <div className={styles.widgetContainer}>{children}</div>
    </div>
  );
};

export default Widget;
