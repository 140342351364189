const element = document.getElementsByTagName('html')[0];

export function addThemeClass(themeName: string): void {
  const classForTheme = `theme-${themeName}`;
  if (!element.classList.contains(classForTheme)) {
    element.classList.add(classForTheme);
  }
}

export function removeThemeClass(): void {
  const classList = element.classList;
  for (let i = 0; i < classList.length; i++) {
    const className = classList[i];
    if (className.startsWith('theme-')) {
      element.classList.remove(className);
    }
  }
}
