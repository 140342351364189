import { useSnacks } from 'auth';
import db from 'data/db';
import { currentPathArray } from 'helpers/url';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentDashboardOnProductChange } from 'store/slices/dashboardSlice';
import { setOpen } from 'store/slices/sidebarSlice';
import { RootState } from '../../store';
import ProductLogo from './ProductLogo';

interface ProductLinkProps {
  active: boolean;
  product: ProductCode;
  toolTipClass: string;
}

const ProductLink = ({
  active = false,
  product,
  toolTipClass = '',
}: ProductLinkProps): JSX.Element => {
  const dispatch = useDispatch();
  const dashboard: DashboardActions = useDashboard();
  const sidebarState = useSelector((state: RootState) => state.sidebar);
  const currentThemeState = useSelector((state: RootState) => state.theme);
  const [toolTipTitle, setToolTipTitle] = useState<string>('');

  const { openSnack } = useSnacks();

  const { isEditing, isCreating } = useSelector(
    (state: RootState) => state.dashboard
  );

  const navigate = useNavigate();
  const currentlySelectedProduct = currentPathArray()[0] as ProductCode;

  useEffect(() => {
    const pageTitle = product.toUpperCase();
    setToolTipTitle(pageTitle);
  }, [product]);

  const handleSelectionClick = useCallback(
    (e: any) => {
      if (isEditing || isCreating) {
        openSnack('Please save or cancel your changes first', 'warning');
        return;
      }
      /**
       * This prevents the link from activating if user
       * is in the correct location. Helps with double-click event.
       */
      if (product === currentlySelectedProduct) {
        e.preventDefault();
        return false;
      }

      dispatch(setCurrentDashboardOnProductChange(product));

      // Navigate to selected product route.
      navigate({
        pathname: `/${product}`,
        search: window.location.search,
      });
    },
    [product, dashboard.state, currentThemeState]
  );

  const handleDoubleClick = useCallback(async () => {
    let open: any = await db.settings.where({ name: 'drawerOpen' }).first();
    open = open.storage as boolean;

    const isOpen = !open;
    dispatch(setOpen(isOpen));

    await db.updateSetting('drawerOpen', isOpen);
  }, [sidebarState.open, db, dashboard.state]);

  return (
    <a
      onClick={handleSelectionClick}
      onDoubleClick={handleDoubleClick}
      className={active ? 'active' : 'inactive'}
    >
      <ProductLogo product={product} />
      <div className={toolTipClass}>
        <p>{toolTipTitle}</p>
      </div>
    </a>
  );
};

export default ProductLink;
