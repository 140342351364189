import { generatedRandomNumber } from 'helpers/numbers';
import { currentPathArray } from 'helpers/url';
import Palette, { PaletteConfig, PaletteDetail, ThemePalette } from 'palette';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentDashboard } from 'store/selectors/dashboard';
import {
  setUpdatedLayout,
  SFDashboardSyncState,
} from 'store/slices/dashboardSlice';
import { RootState } from '../store';

export interface DashboardActions {
  addDashboardItem(item: SFDashboardItem): void;
  colorPalette(detail: string): PaletteConfig | null;
  isDefaultDashboard: boolean;
  names: string[];
  // refresh(): void;
  remove(): void;
  removeDashboardComponent(indexInLayout: number): void;
  reset(refreshLayout?: boolean): void;
  saveNewDashboard(): void;
  state: SFDashboardSyncState;
  saveDashboardUpdates(): void;
}

const useDashboard = (): DashboardActions => {
  const dispatch = useDispatch();

  const dashboardState = useSelector((state: RootState) => state.dashboard);
  const { dashboards, isEditing } = useSelector(
    (state: RootState) => state.dashboard
  );
  const currentDashboard = useSelector(selectCurrentDashboard);

  /**
   * Output Dashboard State
   */
  const state = useMemo(() => {
    return dashboardState;
  }, [dashboardState]);

  const currentPath = useMemo(() => {
    const path: string = currentPathArray()[0];
    return path;
  }, [window.location.pathname]);

  /** Add new chart to layout. */
  function addDashboardItem(item: SFDashboardItem): void {
    if (currentDashboard === null) {
      return;
    }

    const updatedLayout = [...currentDashboard.layout, item];
    dispatch(setUpdatedLayout(updatedLayout));
  }

  /**
   * Helps set the color palette for a given component.
   */
  const colorPalette = (detailKey: string): PaletteConfig | null => {
    if (typeof currentPath !== 'undefined' && typeof Palette !== 'undefined') {
      const _currentPath = currentPath as keyof ThemePalette;
      const _palette = Palette[_currentPath] as PaletteDetail;
      return _palette[detailKey as keyof PaletteDetail] as PaletteConfig;
    }
    return null;
  };

  /**
   * Check if the current dashboard is default.
   */
  const isDefaultDashboard = currentDashboard?.isDefault || false;

  /**
   * Outputs the list of layout names for both
   * shared and user dashboard layouts.
   */
  const dashboardNames = dashboards?.map((d) => d.name) || [];

  /**
   * Updates layout items by generating a new key for components.
   */
  // const refresh = useCallback((): void => {
  //   dispatch(
  //     setDashboardState({
  //       ...dashboardState,
  //       redrawLayout: generatedRandomNumber(),
  //     })
  //   );
  // }, [dashboardState]);

  /**
   * Remove Current Layout from indexDB.
   */
  const remove = useCallback(async () => {
    throw new Error('Implement delete dashboard.');
    // await db.removeDashboard(dashboardState.configuration);
  }, [dashboardState]);

  /**
   * Removes chart items in layout.
   */
  function removeDashboardComponent(indexInLayout: number): void {
    if (currentDashboard === null) {
      return;
    }

    const updatedLayout = [...currentDashboard.layout];
    updatedLayout.splice(indexInLayout, 1);
    dispatch(setUpdatedLayout(updatedLayout));
  }

  /**
   * Reset Dashboard Configuration.
   */
  const reset = useCallback(
    (refreshLayout = true): void => {
      throw new Error('Implement reset dashboard back to unedited state.');

      // dispatch(
      //   setDashboardState({
      //     ...dashboardState,
      //     configuration:
      //       hasBeenEdited && refreshConfig !== null
      //         ? refreshConfig
      //         : dashboardState.configuration,
      //     cache: null,
      //     editable: false,
      //     isNewLayout: false,
      //     redrawLayout: refreshLayout
      //       ? generatedRandomNumber()
      //       : dashboardState.redrawLayout,
      //   })
      // );
    },
    [dashboardState, dispatch, generatedRandomNumber]
  );

  /**
   * Update existing dashboard layout in db.
   */
  function saveDashboardUpdates(): void {
    throw new Error('Implement save dashboard updates.');
    // try {
    //   const configuration: DashboardItem | null = dashboardState.configuration;

    //   if (configuration === null) {
    //     throw new Error(
    //       'Dashboard Configuration not found for updating layout.'
    //     );
    //   }

    //   await db.updateDashboard(configuration, currentPath);
    //   // Set state correctly and disable editing.
    //   dispatch(
    //     setDashboardState({
    //       ...dashboardState,
    //       cache: null,
    //       configuration,
    //       editable: !dashboardState.editable,
    //     })
    //   );
    // } catch (error: any) {
    //   console.error(error);
    // }
  }

  /**
   * Save new dashboard layout.
   */
  function saveNewDashboard(): void {
    throw new Error('Implement save new dashboard.');
    // try {
    //   const configuration: DashboardItem | null = dashboardState.configuration;

    //   if (configuration === null) {
    //     throw new Error(
    //       'Dashboard Configuration not found for saving layouts.'
    //     );
    //   }

    //   // Clear isNewLayout flag when saving.
    //   setState({ ...dashboardState, isNewLayout: false });

    //   const configName =
    //     typeof name !== 'undefined' ? name : configuration.name;
    //   const configType =
    //     typeof type !== 'undefined' ? type : configuration.type;

    //   await db.newDashboard(configuration, configName, configType);
    //   reset(false);
    // } catch (error: any) {
    //   console.error(error);
    // }
  }

  return {
    addDashboardItem,
    colorPalette,
    isDefaultDashboard,
    names: dashboardNames,
    // refresh,
    remove,
    removeDashboardComponent,
    reset,
    saveNewDashboard,
    state,
    saveDashboardUpdates,
  };
};

export default useDashboard;
