/* eslint-disable */

import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

export type NotificationVariant =
  | 'default'
  | 'error'
  | 'success'
  | 'warning'
  | 'info';

const okStrings = [
  'Righto',
  'Roger',
  'Righto',
  'Alrighty',
  'Very Well',
  'Got It',
  'Alright',
  'Sure Thing',
  'Okay',
  'Okey Doke',
  'Gotcha',
  'OK',
  'Understood',
];

const goodStrings = [
  'Great!',
  'Huzzah!',
  'Booyah!',
  'Cool!',
  'Awesome!',
  'Fantastic!',
  'Nice!',
  'Hurrah!',
  'Terrific!',
  'Splendid!',
  'Splendiferous!',
  'Wonderful!',
  'Amazing!',
  'Right On!',
  'Sweet!',
  'Hurray!',
  'Yay!',
  'Woohoo!',
  'Yesssss!',
  '🎉',
  '🎊',
  '🥳',
];

/**
 * Provides open and close methods to display and dismiss notifications
 * @returns []
 */
const useSnacks = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function openSnack(
    msg: React.ReactNode,
    variant: NotificationVariant,
    persist = false,
    action: any = ''
  ): string | number {
    // Yellow doesn't work well against yellow/blue background of warning/info
    if (variant !== 'warning' && variant !== 'info') {
      okStrings.push('👍');
    }

    let actionStrings: string[] = [];
    if (variant === 'success') {
      actionStrings = goodStrings;
    } else {
      actionStrings = okStrings;
    }

    const rand = Math.floor(Math.random() * actionStrings.length);
    const str = actionStrings[rand];

    // Assign default closeable action
    if (!action) {
      action = (key: string | number | undefined) => (
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {str}
        </Button>
      );
    }

    return enqueueSnackbar(msg, {
      variant,
      persist,
      action,
      preventDuplicate: true,
    });
  }

  function closeSnack(key: string | number): void {
    closeSnackbar(key);
  }

  return { openSnack, closeSnack };
};

export default useSnacks;
