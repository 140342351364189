import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export,
  Font,
  Label,
  Legend,
  Series,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import { getObjectArrayIndex, renameObjectKey } from 'helpers/object';
import { formatToDollar } from '../../helpers/format';
import { fuzzyDateText } from 'helpers/query';
import { humanizer } from 'helpers/string';
import { PaletteConfig } from '../../palette';
import { Paper } from '@mui/material';
import { removeHoverHashing } from '../../helpers/hashing';
import { RootState } from '../../store';
import { selectDashboardItem } from 'store/selectors/dashboard';
import { Spinner } from 'auth';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import QueryGenerator from 'data/graph/queryGenerator';
import useColorPalette from 'hooks/useColorPalette';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import '../../styles/material-ui/paper.scss';
import styles from '../../styles/components/custom/bar-chart.module.scss';
import { setComparisonFields } from 'helpers/dashboard';

interface ChartProps extends DashboardComponentProps {
  itemID: string;
}

const BarChart = ({ itemID, redrawKey }: ChartProps): JSX.Element => {
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const calendar = useSelector((state: RootState) => state.calendar);
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>(null);
  const [seriesItems, setSeriesItems] = useState<any[]>([]);

  const chartItem = useSelector((state: RootState) =>
    selectDashboardItem(state, itemID)
  );
  const dashboardConfiguration = chartItem?.chartConfigurations || null;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dashboardConfiguration === null) return;

    // Class that builds out query from tableConfiguration.
    const buildQuery = new QueryGenerator(dashboardConfiguration, calendar);
    const generatedQuery = buildQuery.result();

    const query: GraphQLQueryConfig = {
      ...defaultGraphQLQueryConfig,
      argField: dashboardConfiguration.selectedArgField,
      query: 'generatedQuery',
      comparisonFuzzyDate: dashboardConfiguration.comparisonFuzzyDate,
      generatedQueryString: generatedQuery,
    };

    setDataQuery(query);
  }, [dashboardConfiguration, itemID]);

  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    if (dashboardConfiguration === null) {
      return;
    }

    // Load and configure data to display with correct arg field.
    const argField = dashboardConfiguration.selectedArgField;
    let updatedData: any[] = [];
    for (const dataItem of data) {
      const object = renameObjectKey(argField, 'arg', dataItem);
      updatedData = [...updatedData, object];
    }

    // Load and configure comparison data.
    const selectedFields = dashboardConfiguration?.selectedFields;
    if (typeof selectedFields === 'undefined') return;
    const comparisonDateOffset =
      dashboardConfiguration?.comparisonFuzzyDate as string;
    const comparisonSuffix: string | boolean =
      comparisonDateOffset !== '0' && fuzzyDateText[comparisonDateOffset];

    let dataKeys = Object.keys(data[0]);
    dataKeys = dataKeys.filter((key) => key !== 'arg');
    const mutableSelectedFields = setComparisonFields(
      dataKeys,
      selectedFields,
      comparisonSuffix
    );

    setSeriesItems(mutableSelectedFields);
    setChartData(updatedData);
    setLoading(isLoading);
  }, [data]);

  const colorPalette = useMemo(() => {
    return themePalette.customBarChart as PaletteConfig;
  }, [themePalette]);

  const customizeText = (amount: any): string => {
    return formatToDollar(amount.value);
  };

  const customizeToolTip = (arg: any): CustomToolTipType => {
    return {
      text: `<div>${formatToDollar(arg.valueText)}</div>`,
    };
  };

  return (
    <div className={styles.barChart}>
      <Paper
        square
        elevation={0}
        className={`${styles.box} paper-box paper-box-l`}
      >
        <div className={styles.heading}>
          <h2>{dashboardConfiguration?.chartTitle}</h2>
        </div>
        {!loading ? (
          hasData ? (
            <>
              <Chart
                id={`${itemID}`}
                key={redrawKey}
                animation={true}
                dataSource={chartData}
                customizePoint={removeHoverHashing}
                rotated={dashboardConfiguration?.rotateChart}
                palette={colorPalette.chart}
              >
                <CommonAxisSettings allowDecimals={false} />
                <CommonSeriesSettings
                  argumentField="arg"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                />
                {seriesItems.length > 0 &&
                  seriesItems.map((valueField: any, index: number) => {
                    return (
                      <Series
                        type={valueField.type}
                        valueField={valueField.field}
                        name={valueField.name}
                        key={index}
                      />
                    );
                  })}
                <ArgumentAxis>
                  <Tick visible={false} />
                  <Title text={dashboardConfiguration?.argAxisTitle}>
                    <Font color={colorPalette.secondaryFont} size={12} />
                  </Title>
                  <Label>
                    <Font color={colorPalette.font} weight={700} />
                  </Label>
                </ArgumentAxis>
                <ValueAxis showZero={true}>
                  <Title text={dashboardConfiguration?.valueAxisTitle}>
                    <Font color={colorPalette.secondaryFont} size={12} />
                  </Title>
                  <Label>
                    <Font color={colorPalette.font} weight={700} />
                  </Label>
                  <Tick visible={false} />
                </ValueAxis>
                <Legend visible={false} />
                <Export enabled={false} />
                <Tooltip
                  enabled={dashboardConfiguration?.enableToolTip}
                  location="edge"
                  customizeTooltip={customizeToolTip}
                />
              </Chart>
            </>
          ) : (
            <div className={styles.notFound}>
              <p>No data found.</p>
            </div>
          )
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner active={loading} background={'clear'} />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default BarChart;
