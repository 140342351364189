// Set Graphql Endpoint.
const apolloUri = process.env.REACT_APP_GRAPHQL_ENDPOINT as string;

const Config: IConfig = {
  supportedWorksuiteProducts: ['analyze', 'produce', 'forecast', 'restock'], // supports 'analyze','produce','restock','fulfill','forecast'
  useApolloClient: true,
  apolloUri,
  dexieVersion: 1,
  dbName: 'analyze',
  version: '0.2.0',
  sentryDSN:
    'https://f9c8d45da54448d29f6eaf8ee348eee2@o1137736.ingest.sentry.io/6727694',
  useDebug: false,
};

export default Config;
