import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface QueryStringState {
  hideNav: boolean;
  dateRange: Maybe<string>;
}

const initialState: QueryStringState = {
  hideNav: false,
  dateRange: null,
};

export const queryStringSlice = createSlice({
  name: 'queryString',
  initialState,
  reducers: {
    setHideNav: (state, action: PayloadAction<boolean>) => {
      state.hideNav = action.payload;
    },
    setDateRange: (state, action: PayloadAction<string>) => {
      state.dateRange = action.payload;
    },
  },
});

export const { setHideNav, setDateRange } = queryStringSlice.actions;
export default queryStringSlice.reducer;
