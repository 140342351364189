// Apparently this must be a class component
// https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries

import { Component, ErrorInfo, ReactNode } from 'react';
// Material UI
import { Typography } from '@mui/material';
import styles from '../../styles/auth/components/other/error-boundary.module.scss';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className={styles.errorBoundary}>
          <Typography variant="h3" className={styles.message}>
            Uh oh! There was an unexpected error with this application. Please
            contact your Silver Fern client partner.
          </Typography>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
