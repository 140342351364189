/**
 * Documention on how this component works. Because I won't remember.
 * 1. The useData hook is called with { data, setData } = useData(); in a component.
 * 2. GraphQLQueryConfig is used to apply needed configurations for a given graphQL query.
 * 3. setData() is used within a component to send GraphQLQueryConfig object back to useData.
 * 4. Once setData() is called a useEffect hook loads the GraphQLQueryConfig into state for use in another useEffect.
 * 5. Based on the dependencies of the second useEffect GraphQLQueryConfig is parsed and passed into apolloClient.
 * 6. Note: useQuery hook is not being used here. apolloClient.query is being used instead, dynamically.
 * 7. Based on GraphQLQueryConfig, GraphQLData object is called and the correct graphQL query is called.
 * 8. Data from the graphQL query is then loaded into state and output using 'data' property.
 * 9. That 'data' then can be used as a dependency in another component for use.
 */
import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import GraphQLData, {
  hasDataQuery,
  type CallableGraphQLDataTypes,
} from 'data/graph/data';
import {
  buildGrapqhQlFields,
  extractLegacyQueryData,
  extractQueryFields,
  extractQueries,
  extractQueryData,
} from 'helpers/query';
import { lowercaseFirstLetter } from '../helpers/string';
import { RetailerState } from 'store/slices/retailerSlice';
import { RootState } from '../store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type ValueFieldType = {
  field: string;
  name?: string;
  type?: string;
};

export interface GraphQLQueryConfig {
  argField: string;
  comparisonFuzzyDate: FuzzyDate;
  generatedQueryString: string;
  id: any;
  introspectiveQueryName: string;
  month: Maybe<number>;
  regionID: string;
  parentID: string;
  query: string;
  valueFields: ValueFieldType[];
  week: Maybe<number>;
  weekCount: Maybe<number>;
}

// TODO: Remove this later after all components are updated.
export const defaultGraphQLQueryConfig: GraphQLQueryConfig = {
  argField: '',
  comparisonFuzzyDate: '0',
  generatedQueryString: '',
  id: 0,
  introspectiveQueryName: '',
  month: null,
  parentID: '',
  query: '',
  regionID: '',
  valueFields: [{ field: '' }],
  week: null,
  weekCount: null,
};

interface IData {
  data: any;
  hasData: boolean;
  isLoading: boolean;
  setDataQuery(query: GraphQLQueryConfig): void;
}

const useData = (): IData => {
  const apolloClient: ApolloClient<object> = useApolloClient();

  const [data, setData] = useState<any>([]);
  const [hasData, setHasData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [queryArray, setQueryArray] = useState<GraphQLQueryConfig | null>(null);

  const { currentRetailerId }: RetailerState = useSelector(
    (state: RootState) => state.retailer
  );

  useEffect(() => {
    (async () => {
      try {
        if (queryArray === null) {
          setData([]);
          setIsLoading(false);
          return [];
        }

        if (queryArray.query === '') return [];

        // Check if query being used is available.
        const queryName = lowercaseFirstLetter(
          queryArray.query
        ) as keyof CallableGraphQLDataTypes;

        const hasQuery = hasDataQuery(queryName);
        if (!hasQuery) {
          throw new Error(
            `Query ${queryName} not found as keyof CallableGraphQLDataTypes.`
          );
        }

        // Setup query fields.
        let queryFieldData: string | string[] = buildGrapqhQlFields(queryArray);
        queryFieldData = queryFieldData.length ? queryFieldData.join(' ') : '';

        // Set graphql query parameter values.
        GraphQLData.introspectiveQueryName = queryArray.introspectiveQueryName;

        // Check for loadQueryFields to make sure a queryName is given.
        if (
          queryName === 'loadQueryFields' &&
          queryArray.introspectiveQueryName === ''
        ) {
          throw new Error(
            'Error: loadQueryFields does not have an introspecitveQueryName.'
          );
        }

        if (queryName === 'generatedQuery') {
          queryFieldData = queryArray.generatedQueryString;
        }

        // QUERY graphql...
        const graphQuery = GraphQLData[queryName](
          queryFieldData
        ) as DocumentNode;
        const response = await apolloClient.query({ query: graphQuery });
        if (response === null) return;

        setIsLoading(response.loading);

        // Extract and send data based on queryName.
        let extractedData: any = [];
        switch (queryName) {
          case 'loadQueries':
            extractedData = extractQueries(response.data);
            break;
          case 'loadQueryFields':
            extractedData = extractQueryFields(response.data);
            break;
          case 'generatedQuery':
            extractedData = extractQueryData(
              response.data,
              queryArray.comparisonFuzzyDate
            );
            break;
          default:
            extractedData = extractLegacyQueryData(response.data, queryArray);
            break;
        }

        setData(extractedData);
        setHasData(extractedData.length > 0);
      } catch (errorMsg: any) {
        console.error(errorMsg.toString());
      }
    })();
  }, [queryArray, currentRetailerId]);

  return {
    data,
    hasData,
    isLoading,
    setDataQuery: setQueryArray,
  };
};

export default useData;
