import { GraphQLQueryConfig, ValueFieldType } from 'hooks/useData';
import {
  convertObjectToKeyValuePairs,
  flattenObjectArray,
  getObjectArrayIndex,
} from './object';
import { capitalizeFirstLetter, humanizer } from './string';

type QueryArgumentType = {
  name: string;
  inputFields: string[] | null;
};

export interface IntrospectiveQueries {
  name: string;
  args: QueryArgumentType[];
}

export interface IntrospectiveQueryField {
  name: string;
  inputFields: string[] | null;
}

export const fuzzyDateText: { [x: string]: string } = {
  '0': '',
  yearOffset: 'LY',
  monthOffset: 'LM',
  weekOffset: 'LW',
};

function extractInputFieldOptions(fieldType: any): string[] {
  const fieldOptions = fieldType.ofType.inputFields;
  if (fieldOptions === null) return [];

  let results: string[] = [];
  fieldOptions.forEach((option: any) => {
    results = [...results, option.name];
  });
  return results;
}

function extractInputFields(args: any[]): any[] {
  let results: any[] = [];
  args.forEach((arg: any) => {
    results = [
      ...results,
      {
        name: arg.name,
        options:
          typeof arg.type !== 'undefined'
            ? extractInputFieldOptions(arg.type)
            : [],
      },
    ];
  });
  return results;
}

/**
 * Extract list of available query names from graphql
 * introspection query along with arguments.
 */
export function extractQueries(graphQueryData: any): any {
  const length = Object.keys(graphQueryData).length;
  if (length === 0) return null;

  const queryBlacklist = [
    'calendar',
    'connections',
    'dashboards',
    'node',
    'nodes',
    'products',
    'retailer',
    'retailers',
  ];
  const dataResult = Object.values(graphQueryData)[0] as any;

  let results: IntrospectiveQueries[] = [];
  for (const i in dataResult.fields) {
    const result = dataResult.fields[i];

    if (queryBlacklist.indexOf(result.name) === -1) {
      let args: QueryArgumentType[] = [];
      result.args.forEach((arg: any) => {
        args = [
          ...args,
          {
            name: arg.name,
            inputFields:
              arg.type.inputFields !== null
                ? extractInputFields(arg.type.inputFields)
                : null,
          },
        ];
      });

      const queryName: IntrospectiveQueries = {
        name: capitalizeFirstLetter(result.name),
        args,
      };
      results = [...results, queryName];
    }
  }

  return results;
}

/**
 * Extract list of available query fields from graphql
 * introspection query.
 */
export function extractQueryFields(
  graphQueryData: any
): IntrospectiveQueryField[] | null {
  const length = Object.keys(graphQueryData).length;

  if (length === 0) return null;

  const dataResult = Object.values(graphQueryData)[0] as any;
  let results: any[] = [];
  for (const i in dataResult.fields) {
    const result = dataResult.fields[i];
    const field: IntrospectiveQueryField = {
      name: result.name,
      inputFields: extractInputFields(result.args),
    };
    results = [...results, field];
  }

  return results;
}

function filterQueryDataFields(fields: any, comparisonFuzzyDate: string): any {
  let result: any[] = [];
  let comparisonArray: any[] = [];
  for (const [key, value] of fields) {
    if (key.split('__').length > 1) continue;

    if (key === 'comparison') {
      if (value === null) continue;
      const returnResult = filterQueryDataFields(
        Object.entries(value),
        comparisonFuzzyDate
      );
      comparisonArray = [...returnResult];
    } else {
      result = [...result, { key, value }];
    }
  }

  // Rename and add comparison fields to result array based on index.
  for (const item of comparisonArray) {
    const keyValue = item.key;
    const index = getObjectArrayIndex('key', keyValue, result);
    if (index === null) continue;

    const insertIndex = index + 1;
    const fuzzyDate = fuzzyDateText[comparisonFuzzyDate];
    const newValue = `${keyValue} ${fuzzyDate}`;
    result = [...result];
    result.splice(insertIndex, 0, { key: newValue, value: item.value });
  }

  return result;
}

/**
 * Extract Query Data from GraphQL Query result.
 */
export function extractQueryData(
  graphQueryData: any,
  comparisonFuzzyDate: FuzzyDate
): any {
  const length = Object.keys(graphQueryData).length;
  if (length === 0) return [];

  const data = graphQueryData[Object.keys(graphQueryData)[0]].edges;
  let result: any[] = [];

  for (const item of data) {
    const node = item.node;
    const nodeItems = Object.entries(node);
    const filterResultArray = filterQueryDataFields(
      nodeItems,
      comparisonFuzzyDate
    );
    result = [...result, filterResultArray];
  }

  result = convertObjectToKeyValuePairs(result);
  result = result.map((item: any) => {
    return flattenObjectArray(item);
  });

  return result;
}

/**
 * Extract specific fields defined in queryArray from data result array.
 * @deprecated
 */
export function extractLegacyQueryData(
  graphQueryData: any,
  queryArray: GraphQLQueryConfig
): any {
  const length = Object.keys(graphQueryData).length;
  if (length === 0) return null;

  const queryFields = buildGrapqhQlFields(queryArray);
  if (!queryFields.length) return null;

  let dataResult = Object.values(graphQueryData)[0] as any;
  const isArray = Array.isArray(dataResult);
  let dataArray: any[] = [];

  dataResult = isArray ? dataResult : [dataResult];
  dataResult.forEach((item: any) => {
    // Support for deprecated legacy queries.
    const isLegacy = typeof item.edges === 'undefined';
    let contentArray: any = {};

    if (!isLegacy) {
      const edges = item.edges;
      for (const edge of edges) {
        const { node } = edge;
        for (const field of queryFields) {
          if (typeof node[field] !== 'undefined') {
            contentArray[field] = node[field];
          }
        }
        dataArray = [...dataArray, contentArray];
        contentArray = {};
      }
    } else {
      // Configure Legacy data.
      queryFields.forEach((field: string) => {
        if (typeof item[field] !== 'undefined') {
          contentArray[field] = item[field];
        }
      });
      dataArray = [...dataArray, contentArray];
      contentArray = {};
    }
  });
  return dataArray;
}

/**
 * Builds out the array for fields graphql query.
 * @deprecated
 */
export function buildGrapqhQlFields(queryArray: GraphQLQueryConfig): string[] {
  // Grab all valueField values.
  const values = queryArray.valueFields.map((valueField: ValueFieldType) => {
    return valueField.field;
  }, []);

  // Append argField and valueFields together in one array.
  const graphqlFields = queryArray.argField.length
    ? [queryArray.argField, ...values]
    : values;

  return graphqlFields;
}

/**
 * Provides a human readable string for fuzzy date comparison.
 */
export function fuzzyComparisonDates(offsetValue: string): string {
  let dateText = '';
  switch (offsetValue) {
    case 'yearOffset':
      dateText = 'Previous Year';
      break;
    case 'monthOffset':
      dateText = 'Previous Month';
      break;
    case 'weekOffset':
      dateText = 'Previous Week';
      break;
    default:
      dateText = humanizer(offsetValue);
      break;
  }
  return dateText;
}
