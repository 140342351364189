import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/display-filter.module.scss';

interface DisplayFilterProps {
  disabled: boolean;
  onChange: (value: DisplayOptions) => void;
  value: DisplayOptions;
}

const DisplayFilter = ({
  disabled = true,
  onChange,
  value,
}: DisplayFilterProps): JSX.Element => {
  const displayItems = value.split('|');
  const [direction, setDirection] = useState<DisplaySelection>(
    displayItems[0] as DisplaySelection
  );
  const [displayLimit, setDisplayLimit] = useState<DisplayAmount>(
    displayItems[1] as DisplayAmount
  );

  useEffect(() => {
    onChange(`${direction}|${displayLimit}`);
  }, [direction, displayLimit]);

  function onDirectionChange(event: SelectChangeEvent<string>): void {
    setDirection(event.target.value as DisplaySelection);
  }

  function onDisplayChange(event: SelectChangeEvent<string>): void {
    setDisplayLimit(event.target.value as DisplayAmount);
  }

  return (
    <div className={styles.displayFilter}>
      <h2>Display Number of Results:</h2>
      <div className={styles.displayFilterWrapper}>
        <FormControl
          variant="outlined"
          className={`${muStyles.select} ${styles.displaySelect}`}
        >
          <Select
            disabled={disabled}
            native
            value="top"
            onChange={onDirectionChange}
            inputProps={{
              name: 'display-direction',
              id: 'display-direction',
            }}
          >
            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          className={`${muStyles.select} ${styles.displaySelect}`}
        >
          <Select
            disabled={disabled}
            native
            value={displayLimit}
            onChange={onDisplayChange}
            inputProps={{
              name: 'display-amount',
              id: 'display-amount',
            }}
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default DisplayFilter;
