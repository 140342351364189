import { DocumentNode, gql } from '@apollo/client';
import GraphQLData from './data';

interface LegacyData {
  monthlySalesOld(queryFields?: string): DocumentNode;
  monthlySalesByDayOld(queryFields?: string): DocumentNode;
  salesByMarketOld(queryFields?: string): DocumentNode;
  salesByRegionOld(queryFields?: string): DocumentNode;
  trailingWeeklySalesOld(queryFields?: string): DocumentNode;
  weeklySalesOld(queryFields?: string): DocumentNode;
  weeklySalesByDayOld(queryFields?: string): DocumentNode;
  yearlySalesOld(queryFields?: string): DocumentNode;
}

export interface LegacyCallableGraphQLDataTypes {
  monthlySalesOld(queryFields?: string): DocumentNode;
  monthlySalesByDayOld(queryFields?: string): DocumentNode;
  salesByMarketOld(queryFields?: string): DocumentNode;
  salesByRegionOld(queryFields?: string): DocumentNode;
  trailingWeeklySalesOld(queryFields?: string): DocumentNode;
  weeklySalesOld(queryFields?: string): DocumentNode;
  weeklySalesByDayOld(queryFields?: string): DocumentNode;
  yearlySalesOld(queryFields?: string): DocumentNode;
}

const legacyData: LegacyData = {
  monthlySalesOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${GraphQLData.retailerID}"`;

    let fields = `month
                    lyRetail
                    tyRetail
                    lyRetailOnHand
                    tyRetailOnHand
                    retailCompPct
                    marginCompPct
                    unitsSoldCompPct`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
        query {
          monthlySalesOld( retailerID: ${retailer}, month: ${GraphQLData.month} ) { ${fields} }
        }
      `;
  },
  monthlySalesByDayOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${GraphQLData.retailerID}"`;

    let fields = `dayOfMonth
                    lyRetail
                    tyRetail
                    lyRetailOnHand
                    tyRetailOnHand`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
      query {
        monthlySalesByDayOld( retailerID: ${retailer}, month: ${GraphQLData.month} ) { ${fields} }
      }
    `;
  },
  salesByMarketOld: (queryFields = ''): DocumentNode => {
    const regionID = GraphQLData.regionID;

    let fields = `marketID
                    marketDescription
                    lyRetail
                    tyRetail
                    lyRetailOnHand
                    tyRetailOnHand
                    lySellThruPct
                    tySellThruPct
                    lyWeeksOnHand
                    tyWeeksOnHand `;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
      query {
        salesByMarketOld( regionID: "${regionID}", month: ${GraphQLData.month}, week: ${GraphQLData.week} ) { ${fields} }
      }
  `;
  },
  salesByRegionOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${_retailerID}"`;

    let fields = `regionID
                    regionDescription
                    lyRetail
                    tyRetail
                    lyRetailOnHand
                    tyRetailOnHand
                    lySellThruPct
                    tySellThruPct
                    lyWeeksOnHand
                    tyWeeksOnHand`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
        query {
          salesByRegionOld(retailerID: ${retailer}, month: ${GraphQLData.month}, week: ${GraphQLData.week} ) { ${fields} }
        }
      `;
  },
  trailingWeeklySalesOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${_retailerID}"`;

    let fields = `weekIndex        
                    retailCompPct
                    lyRetail
                    tyRetail`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
        query {
          trailingWeeklySalesOld(retailerID: ${retailer}, weekCount: ${GraphQLData.weekCount} ) { ${fields} }
        }
      `;
  },
  weeklySalesOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${GraphQLData.retailerID}"`;

    let fields = `retailCompPct
                    marginCompPct
                    unitsSoldCompPct`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
        query {
        weeklySalesOld( retailerID: ${retailer}, week: ${GraphQLData.week} ) { ${fields} }
      }
    `;
  },
  weeklySalesByDayOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${GraphQLData.retailerID}"`;

    let fields = `lyRetail
                    tyRetail
                    lyRetailOnHand
                    tyRetailOnHand`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
        query {
          weeklySalesByDayOld( retailerID: ${retailer}, week: ${GraphQLData.week} ) { ${fields} }
        }
      `;
  },
  yearlySalesOld: (queryFields = ''): DocumentNode => {
    const _retailerID = GraphQLData.retailerID;
    const retailer =
      _retailerID === null || _retailerID === '0' || _retailerID === ''
        ? null
        : `"${GraphQLData.retailerID}"`;

    let fields = `lyRetail
                    tyRetail
                    retailCompPct
                    marginCompPct
                    unitsSoldCompPct`;

    fields =
      typeof queryFields !== 'undefined' && queryFields.length
        ? queryFields
        : fields;

    return gql`
      query {
        yearlySalesOld( retailerID: ${retailer} ) { ${fields} }
      }
    `;
  },
};

export default legacyData;
