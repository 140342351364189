import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RetailerState {
  currentRetailerId: Maybe<string>;
  retailerList: RetailerType[];
  loaded: boolean;
}

const initialState: RetailerState = {
  currentRetailerId: null,
  retailerList: [
    {
      id: '0',
      name: 'All Retailers',
    },
  ],
  loaded: false,
};

export const retailerSlice = createSlice({
  name: 'retailer',
  initialState,
  reducers: {
    setCurrentRetailer: (state, action: PayloadAction<Maybe<string>>) => {
      const newRetailerId = action.payload;
      if (newRetailerId === null) {
        state.currentRetailerId = null;
        return;
      }
      // Don't update on invalid retailerId passed
      const retailer = state.retailerList.find(
        (_retailer) => _retailer.id === newRetailerId
      );

      if (!retailer) {
        return;
      }

      state.currentRetailerId = action.payload;
    },
    setRetailerList: (state, action: PayloadAction<RetailerType[]>) => {
      state.retailerList = action.payload;
    },
    setRetailerDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
  },
});

export const { setCurrentRetailer, setRetailerList, setRetailerDataLoaded } =
  retailerSlice.actions;
export default retailerSlice.reducer;
