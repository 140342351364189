import { useEffect, useRef } from 'react';

function usePrevious<T>(value: Maybe<T>): Maybe<T> {
  const ref: React.MutableRefObject<Maybe<T>> = useRef<T>(null);

  // Update value *after* returning current value
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePrevious;
