import DashboardConfigEditor from '../DashboardConfigEditor';

interface DashboardItemConfigEditorProps {
  itemConfiguration: DashboardItemConfig;
  didUpdateConfiguration: (updatedConfig: DashboardItemConfig) => void;
}

const LineChartConfigEditor = ({
  itemConfiguration,
  didUpdateConfiguration,
}: DashboardItemConfigEditorProps): JSX.Element => {
  return (
    <DashboardConfigEditor
      enabledArgFields={true}
      itemConfiguration={itemConfiguration}
      didUpdateConfiguration={didUpdateConfiguration}
    />
  );
};

export default LineChartConfigEditor;
