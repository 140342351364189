import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WidgetState {
  editedItemID: Maybe<string>;
}

const initialState: WidgetState = {
  editedItemID: null,
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    clearEditedWidgetID: (state) => {
      state.editedItemID = null;
    },
    setEditedWidgetID: (state, action: PayloadAction<WidgetState>) => {
      const { editedItemID } = action.payload;
      state.editedItemID = editedItemID;
    },
  },
});

export const { clearEditedWidgetID, setEditedWidgetID } = widgetSlice.actions;
export default widgetSlice.reducer;
