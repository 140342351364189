import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import calendarReducer from './slices/calendarSlice';
import chartConfigReducer from './slices/chartConfigSlice';
import dashboardSlice from './slices/dashboardSlice';
import modalReducer from './slices/modalSlice';
import paramReducer from './slices/paramSlice';
import productReducer from './slices/productSlice';
import queryStringReducer from './slices/queryStringSlice';
import retailerReducer from './slices/retailerSlice';
import sidebarReducer from './slices/sidebarSlice';
import themeReducer from './slices/themeSlice';
import widgetReducer from './slices/widgetSlice';
import worksuiteReducer from './slices/worksuiteSlice';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chartConfig: chartConfigReducer,
  dashboard: dashboardSlice,
  modal: modalReducer,
  param: paramReducer,
  product: productReducer,
  query: queryStringReducer,
  retailer: retailerReducer,
  sidebar: sidebarReducer,
  theme: themeReducer,
  widget: widgetReducer,
  worksuite: worksuiteReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function store(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore['dispatch'];
