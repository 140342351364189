import styles from '../../styles/components/fields/toggle.module.scss';

interface ToggleProps {
  label: string;
  onClick: () => void;
  value: boolean;
}

const Toggle = ({ label, onClick, value }: ToggleProps): JSX.Element => {
  return (
    <div className={styles.toggle}>
      <label>
        <span className={styles.label}>{label}</span>
        <span className={styles.checkBox}>
          <input type="checkbox" onClick={onClick} value={value.toString()} />
          <span className={value ? `${styles.on}` : ''}>
            <span></span>
          </span>
        </span>
      </label>
    </div>
  );
};

export default Toggle;
