import { Spinner } from 'auth';
import useInitialData from 'hooks/loaders/useInitialData';
import useQueryStringSyncing from 'hooks/loaders/useQueryStringSyncing';
import useSyncedDashboards from 'hooks/useSyncedDashboardAPI';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setCurrentDashboard } from 'store/slices/dashboardSlice';
import { setDateRange } from 'store/slices/queryStringSlice';
import { setCurrentRetailer } from 'store/slices/retailerSlice';

interface InitDataProviderProps {
  children: React.ReactNode;
}

const InitDataProvider: React.FC<InitDataProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  const { dashboards } = useSelector((state: RootState) => state.dashboard);
  const dashboardsLoaded = dashboards !== null;

  /** Two way sync querystring params with redux state */
  const {
    dateRange,
    currentRetailerId,
    currentDashboardId,
    loaded: queryParamsLoaded,
  } = useQueryStringSyncing();
  const { loadAllDashboards, apiReady } = useSyncedDashboards();
  const { retailers, calendar, loaded: loadedInitialData } = useInitialData();

  useEffect(() => {
    loadAllDashboards();
  }, []);

  function setDefaultReduxValuesIfNeeded(): void {
    setDefaultRetailerIfProvided();
    setDefaultDashboard();
    setDefaultDateRange();
  }

  function setDefaultRetailerIfProvided(): void {
    const preselectedRetailer = retailers.find(
      (r) => r.id === currentRetailerId
    );
    // Set default retailer if one is provided in querystring, else leave null, which shows all retailers
    if (preselectedRetailer) {
      dispatch(setCurrentRetailer(preselectedRetailer.id));
    }
  }

  function setDefaultDashboard(): void {
    if (dashboards === null) return;

    // If dashboardId is provided in querystring, use that
    const preselectedDashboard = dashboards.find(
      (d) => d.id === currentDashboardId
    );
    if (preselectedDashboard) {
      dispatch(setCurrentDashboard(preselectedDashboard.id));
    } else {
      // Else just use first dashboard in list
      dispatch(setCurrentDashboard(dashboards[0].id));
    }
  }

  function setDefaultDateRange(): void {
    if (!dateRange) {
      dispatch(setDateRange('Month-To-Date'));
    } else {
      dispatch(setDateRange(dateRange));
    }
  }

  const sourceDataLoaded =
    queryParamsLoaded && dashboardsLoaded && loadedInitialData && apiReady;
  useEffect(() => {
    if (!sourceDataLoaded) {
      return;
    }

    setDefaultReduxValuesIfNeeded();
  }, [sourceDataLoaded]);

  const defaultsInitialized = currentDashboardId !== null;

  // console.log('queryParamsLoaded:  ', queryParamsLoaded);
  // console.log('dashboardsLoaded:  ', dashboardsLoaded);
  // console.log('loadedInitialData:  ', loadedInitialData);
  // console.log('SourceDataLoaded:  ', sourceDataLoaded);
  // console.log('defaultsInitialized:  ', defaultsInitialized);
  // ) : !hasError ? (
  //   <Spinner active={true} background={'clear'} />
  // ) : (
  //   <ErrorMessage type={'error'} />
  // )}
  return (
    <>
      {sourceDataLoaded && defaultsInitialized ? (
        children
      ) : (
        <Spinner active={true} background={'clear'} />
      )}
    </>
  );
};

export default InitDataProvider;
