// create a hook that fetches dashboard products from server with apollo graphql and stores them in redux
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from 'data/graph/products';
import { useState } from 'react';

interface useProductIDsAPI {
  productsWithIDs: Maybe<SFDashboardProduct[]>;
  loaded: boolean;
}

/** Load product IDs from network; this hook is intended to be used once on startup.
 * Relevant data afterwards can be accessed via Redux. */
function useProductIDs(): useProductIDsAPI {
  const [productsWithIDs, setProductsWithIDs] =
    useState<Maybe<SFDashboardProduct[]>>(null);

  const [loaded, setLoaded] = useState(false);
  useQuery(GET_PRODUCTS, {
    skip: loaded,
    onCompleted: (data: any) => {
      if (data) {
        const _products = data.products.edges.map((edge: any) => edge.node);
        setProductsWithIDs(_products);
        setLoaded(true);
      }
    },
    onError: (error: any) => {
      if (error) {
        console.log('Error fetching products: ', error);
        throw new Error('There was an error loading products.');
      }
    },
  });

  return {
    productsWithIDs,
    loaded,
  };
}

export default useProductIDs;
