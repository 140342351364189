import { useSnacks } from 'auth';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import useModal, { ModalActions } from 'hooks/useModal';
import useSyncedDashboardAPI from 'hooks/useSyncedDashboardAPI';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentDashboard,
  selectDashboardHasBeenEdited,
} from 'store/selectors/dashboard';
import { setIsCreating, setIsEditing } from 'store/slices/dashboardSlice';
import styles from '../../styles/components/dashboard-layout/save-controls.module.scss';

interface SaveControlsProps {
  editType: 'create' | 'edit';
}

const SaveControls = ({ editType }: SaveControlsProps): JSX.Element => {
  const { openSnack } = useSnacks();

  const dispatch = useDispatch();
  const dashboard: DashboardActions = useDashboard();
  const modal: ModalActions = useModal();

  const { saveNewDashboard, updateDashboard } = useSyncedDashboardAPI();

  const isCreating = editType === 'create';
  const isEditing = editType === 'edit';

  const dashboardHasBeenEdited = useSelector(selectDashboardHasBeenEdited);

  const currentDashboard = useSelector(selectCurrentDashboard);

  const hasLayout = useMemo(() => {
    if (currentDashboard === null) {
      return false;
    }

    return currentDashboard.layout.length > 0;
  }, [currentDashboard]);

  /**
   * Cancel Layout Updates
   */
  function handleCancel(): void {
    endEditing();
  }

  function endEditing(): void {
    if (isCreating) {
      dispatch(setIsCreating(false));
    } else if (isEditing) {
      dispatch(setIsEditing(false));
    }
  }

  /**
   * Existing Layout Config Update Save.
   */
  async function handleSave(): Promise<void> {
    // Nothing was edited, but cancel and show a save message
    // just because user clicked save
    if (currentDashboard === null || !dashboardHasBeenEdited) {
      openSnack('Dashboard saved!', 'success');
      handleCancel();
      return;
    }

    if (isCreating) {
      saveCreatedDashboard();
    }

    if (isEditing) {
      saveUpdatedDashboard();
    }
  }

  async function saveCreatedDashboard(): Promise<void> {
    try {
      await saveNewDashboard(currentDashboard as SFDashboard);
      openSnack('Dashboard saved!', 'success');
      endEditing();
    } catch (e) {
      console.error(e);
      openSnack(
        'There was an error saving your new dashboard. If this issue persists, please contact your Silver Fern Client partner.',
        'error'
      );
    }
  }

  async function saveUpdatedDashboard(): Promise<void> {
    try {
      await updateDashboard(currentDashboard as SFDashboard);
      openSnack('Dashboard saved!', 'success');
      endEditing();
    } catch (e) {
      console.error(e);
      openSnack(
        'There was an error saving your dashboard. If this issue persists, please contact your Silver Fern Client partner.',
        'error'
      );
    }
  }

  /**
   * Save new Layout update.
   */
  function handleSaveAs(): void {
    modal.setState({
      ...modal.state,
      type: 'saveLayout',
      open: true,
    });
  }

  return (
    <div className={styles.saveControls}>
      <ul>
        {!currentDashboard?.isDefault && hasLayout && !isCreating ? (
          <li>
            <button type="button" onClick={handleSave}>
              Save
            </button>
          </li>
        ) : null}
        {hasLayout ? (
          <li>
            <button type="button" onClick={handleSaveAs}>
              Save As
            </button>
          </li>
        ) : null}

        <li>
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SaveControls;
