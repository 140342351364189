import { FormControl, InputLabel, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import useModal, { ModalActions } from 'hooks/useModal';
import { useCallback, useState } from 'react';
import muStyles from '../../../styles/material-ui/select.module.scss';

import useSyncedDashboardAPI from 'hooks/useSyncedDashboardAPI';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { selectCurrentDashboard } from 'store/selectors/dashboard';
import {
  setDashboardDiff,
  setIsCreating,
  setIsEditing,
} from 'store/slices/dashboardSlice';
import styles from '../../../styles/components/modals/content/save-layout.module.scss';

interface SaveLayoutState {
  classList: string[];
  hasError: boolean;
  layoutType: SFDashboardScope;
  layoutName: string;
  nameClass: string[];
}

const SaveLayout = (): JSX.Element => {
  const dispatch = useDispatch();
  const debouncedDispatch = useCallback(
    debounce((action) => dispatch(action), 300),
    []
  );
  const { isEditing, isCreating } = useSelector(
    (state: RootState) => state.dashboard
  );

  const dashboard: DashboardActions = useDashboard();
  const modal: ModalActions = useModal();

  const { saveNewDashboard } = useSyncedDashboardAPI();
  const currentDashboard = useSelector(selectCurrentDashboard)!;

  const [state, setState] = useState<SaveLayoutState>({
    classList: [`${styles.layoutModal}`],
    hasError: false,
    layoutType: 'user',
    layoutName: '',
    nameClass: [`${styles.name}`],
  });

  /**
   * Reset Modal Event.
   */
  const resetModal = useCallback(() => {
    setState({
      ...state,
      classList: [`${styles.layoutModal}`],
      hasError: false,
      layoutType: 'user',
      layoutName: '',
      nameClass: [`${styles.name}`],
    });

    modal.close();
  }, [modal, state]);

  /**
   * Save Modal Event.
   */
  const saveLayout = useCallback(async () => {
    await saveNewDashboard(currentDashboard);
    resetModal();
    dispatch(setIsCreating(false));
    dispatch(setIsEditing(false));
  }, [state, dashboard]);

  const onLayoutSelectChange = (e: SelectChangeEvent): void => {
    const copy = structuredClone(currentDashboard);
    copy.scope = e.target.value as SFDashboardScope;
    console.log('Updated to scope: ', copy.scope);
    console.log('Save diff.');
    dispatch(setDashboardDiff(copy));
  };

  const onLayoutNameChange = useCallback(
    async (e: any): Promise<void> => {
      try {
        const { value } = e.target;
        const names = await dashboard.names;
        const error = names.indexOf(value.toLowerCase()) !== -1;
        const _class = error
          ? [`${styles.name}`, `${styles.error}`]
          : [`${styles.name}`];

        setState({
          ...state,
          nameClass: _class,
          hasError: error,
        });

        if (value.length <= 25) {
          setState({
            ...state,
            layoutName: value as string,
          });
        }

        const copy = structuredClone(currentDashboard);
        copy.name = value;

        debouncedDispatch(setDashboardDiff(copy));
      } catch (error: any) {
        console.error(error);
      }
    },
    [dashboard, state]
  );

  return (
    <div className={styles.saveLayout}>
      <p className={styles.title}>Save Layout</p>

      <div className={styles.formFields}>
        <FormControl className={state.nameClass.join(' ')}>
          <TextField
            id="layout-name"
            label="Layout Name"
            size="small"
            value={state.layoutName}
            onChange={onLayoutNameChange}
          />
          <span>Name already in use.</span>
        </FormControl>
        <FormControl variant="outlined" className={muStyles.select}>
          <InputLabel htmlFor="layout-type">Layout Type</InputLabel>
          <Select
            native
            labelId="layout-type"
            value={currentDashboard.scope}
            label="Layout Type"
            onChange={onLayoutSelectChange}
            inputProps={{
              name: 'layout-type',
              id: 'layout-type',
            }}
          >
            <option value="user">User</option>
            <option value="shared">Shared</option>
          </Select>
        </FormControl>
      </div>
      <div className={styles.contentButtons}>
        <button type="button" onClick={resetModal}>
          Cancel
        </button>
        <button type="button" disabled={state.hasError} onClick={saveLayout}>
          Save
        </button>
      </div>
    </div>
  );
};

export default SaveLayout;
