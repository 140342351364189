import { Paper } from '@mui/material';
import { Spinner } from 'auth';
import { BarGauge, Geometry, Label } from 'devextreme-react/bar-gauge';
import {
  checkForZeroValues,
  largestArrayValue,
  smallestArrayValue,
} from 'helpers/array';
import useColorPalette from 'hooks/useColorPalette';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaletteConfig } from '../../palette';
import { RootState } from '../../store';

import styles from '../../styles/components/charts/gauge-comp.module.scss';
import '../../styles/material-ui/paper.scss';

const GaugeComp = ({ redrawKey }: DashboardComponentProps): JSX.Element => {
  const calendar = useSelector((state: RootState) => state.calendar);
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [maxEndValue, setMaxEndValue] = useState<number>(100);
  const [maxStartValue, setMaxStartValue] = useState<number>(0);
  const [month, setMonth] = useState<number>(calendar.props.month);
  const [subTitle, setSubtitle] = useState<string>('');
  const [week, setWeek] = useState<number>(calendar.props.week);
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-t-r',
    'paper-box-t-large',
  ]);

  const { dateRange } = useSelector((state: RootState) => state.query);
  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );
  // const currentDashboard = useSelector(selectCurrentDashboard);
  // const layoutKey = currentDashboard?.layoutHash || '';

  const [chartSettings, setChartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    month: calendar.props.month,
    valueFields: [
      { field: 'retailCompPct' },
      { field: 'marginCompPct' },
      { field: 'unitsSoldCompPct' },
    ],
    week: calendar.props.week,
  });

  useEffect(() => {
    if (calendar.loaded) {
      setWeek(calendar.props.week);
      setMonth(calendar.props.month);
    }
  }, [calendar]);

  /**
   * Query data here.
   */
  useEffect(() => {
    setLoading(true);
    let settings: GraphQLQueryConfig = { ...chartSettings };
    switch (dateRange) {
      case 'Week-To-Date':
        setSubtitle('Week-to-Date');
        settings = {
          ...settings,
          month: null,
          query: 'weeklySalesOld',
          week,
        };
        break;
      case 'Year-To-Date':
        setSubtitle('Year-to-Date');
        settings = {
          ...settings,
          month: null,
          query: 'yearlySalesOld',
          week: null,
        };
        break;
      default:
        setSubtitle('Month-to-Date');
        settings = {
          ...settings,
          month,
          query: 'monthlySalesOld',
          week: null,
        };
        break;
    }
    setChartSettings(settings);
  }, [dateRange, month, week]);

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendar.loaded && retailerLoaded) {
      setDataQuery(chartSettings);
    }
  }, [calendar.loaded, retailerLoaded, chartSettings]);

  /**
   * Set data here.
   */
  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    let updatedData: any[] = [];

    data.forEach((dataItem: any) => {
      updatedData = [
        ...updatedData,
        ...[
          dataItem.retailCompPct,
          dataItem.marginCompPct,
          dataItem.unitsSoldCompPct,
        ],
      ];
    });

    updatedData = checkForZeroValues(updatedData);

    const startValue = smallestArrayValue(updatedData);
    setMaxStartValue(startValue);

    const endValue = largestArrayValue(updatedData);
    setMaxEndValue(endValue);

    setAnimate(true);
    setChartData(updatedData);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId, dateRange]);

  const colorPalette = useMemo(() => {
    return themePalette.gauge as PaletteConfig;
  }, [themePalette]);

  const customizeText = (textData: any): string => {
    const { valueText } = textData;
    return `${valueText}%`;
  };

  return (
    <div className={styles.comp}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <div className={styles.heading}>
          <h2>
            Comp<span>{subTitle}</span>
          </h2>
        </div>

        {!loading ? (
          hasData ? (
            <div className={styles.bargaugeContainer}>
              <div className={styles.legend}>
                <ul>
                  <li>
                    <span
                      style={{ backgroundColor: colorPalette.chart[0] }}
                    ></span>
                    Sales Comp
                  </li>
                  <li>
                    <span
                      style={{ backgroundColor: colorPalette.chart[1] }}
                    ></span>
                    Margin % Comp
                  </li>
                  <li>
                    <span
                      style={{ backgroundColor: colorPalette.chart[2] }}
                    ></span>
                    Sales Unit Comp
                  </li>
                </ul>
              </div>
              <div className={styles.barGauge}>
                <BarGauge
                  id="sales-comp-gauge"
                  animation={animate}
                  key={redrawKey}
                  barSpacing={4}
                  startValue={maxStartValue}
                  endValue={maxEndValue}
                  values={chartData}
                  palette={colorPalette.chart}
                  backgroundColor={colorPalette.background}
                  redrawOnResize={true}
                  relativeInnerRadius={0.3}
                >
                  <Label customizeText={customizeText} />
                  <Geometry startAngle={180} endAngle={0} />
                </BarGauge>
              </div>
            </div>
          ) : (
            <div className={styles.notFound}>
              <p>No data found.</p>
            </div>
          )
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner active={true} background={'clear'} />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default GaugeComp;
