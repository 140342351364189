import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { generateRandomString, humanizer } from 'helpers/string';

import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/argument-select.module.scss';

interface ArgumentSelectProps {
  disabled: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  fields: string[];
  value: string;
}

const ArgumentSelect = ({
  disabled = true,
  onChange,
  fields,
  value,
}: ArgumentSelectProps): JSX.Element => {
  return (
    <div className={styles.argumentSelect}>
      <h2>Selected Arg Field</h2>
      <FormControl
        variant="outlined"
        className={`${styles.selectArg} ${muStyles.select} ${muStyles.gray}`}
      >
        <Select
          disabled={disabled}
          native
          value={value}
          onChange={onChange}
          label=""
          inputProps={{
            name: 'selected-arg-field',
            id: `selected-arg-field-${generateRandomString()}`,
          }}
        >
          <option value="0">-- Select --</option>
          {fields.map((field: any, index: number) => {
            return (
              <option key={index} value={field}>
                {humanizer(field)}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default ArgumentSelect;
