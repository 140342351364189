import { createContext, useEffect, useState } from 'react';
import useInterval from '../hooks/useInterval';
import sfAuth from '../SFAuth';

const defaultAuthContext = {
  sessionChecked: false,
  loggedIn: false,
  currentUser: null,
  userIsInSilverFern: false,
  company: null,
  productAccess: null,
  profiles: {
    mobileApps: null,
    inventory: null,
  },
  // eslint-disable-next-line
  redirectToLogin: () => {},
  // eslint-disable-next-line
  redirectToLogout: () => {},
  error: null,
};

export const AuthContext = createContext<AuthContext>(defaultAuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

const TOKEN_DURATION_SECONDS = parseInt(
  process.env.REACT_APP_TOKEN_DURATION_SECONDS as string
);

function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [loggedIn, setLoggedIn] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);
  const [currentUser, setCurrentUser] = useState<Maybe<SFUser>>(null);
  const [company, setCompany] = useState<Maybe<SFCompany>>(null);
  const [productAccess, setProductAccess] =
    useState<Maybe<ProductCode[]>>(null);
  const [mobileAppProfiles, setMobileAppProfiles] =
    useState<Maybe<MobileAppsProfile[]>>(null);
  const [inventoryProfiles, setInventoryProfiles] =
    useState<Maybe<InventoryProfile[]>>(null);
  const [authError, setAuthError] = useState<Maybe<string>>(null);

  /** Run initial session check */
  useEffect(() => {
    (async () => {
      try {
        const hasSession = await sfAuth.checkSession();
        if (!hasSession) {
          // Utility route to remain on page even without a session; helpful for
          // checking cookies without getting redirected
          if (
            window.location.pathname.startsWith('/no-redirect') ||
            window.location.pathname.startsWith('/forbidden')
          ) {
            return;
          }
          sfAuth.redirectToLogin();
        } else {
          await loadUserInfo();
          setLoggedIn(true);
        }
      } catch (e) {
        console.error('Error checking session: ', e);
        setLoggedIn(false);
      } finally {
        setSessionChecked(true);
      }
    })();
  }, []);

  /** Check session/renew access token periodically */
  useInterval(() => {
    (async () => {
      try {
        const hasSession = await sfAuth.checkSession();
        if (!hasSession) {
          sfAuth.redirectToLogin();
        }
      } catch (e) {
        console.error('Error periodically checking session: ', e);
        sfAuth.redirectToLogin();
      }
    })();
  }, (TOKEN_DURATION_SECONDS * 1000) / 5);

  let userIsInSilverFern = false;
  const abbreviation = company?.abbreviation;
  if (abbreviation) {
    userIsInSilverFern =
      ['sfg', 'sfd'].indexOf(company.abbreviation.toLowerCase()) > -1;
  }

  async function loadUserInfo(): Promise<void> {
    try {
      const _user = await sfAuth.getCurrentUser();
      const _company = await sfAuth.getCompany();
      const _productAccess = await sfAuth.getProductAccess();

      setCurrentUser(_user);
      setCompany(_company);
      setProductAccess(_productAccess as ProductCode[]);
    } catch (e) {
      console.error('Error loading user info: ', e);
      setAuthError(`${e}`);
    }
  }

  const contextValue: AuthContext = {
    loggedIn,
    sessionChecked,
    currentUser,
    userIsInSilverFern,
    company,
    productAccess,
    profiles: {
      mobileApps: mobileAppProfiles,
      inventory: inventoryProfiles,
    },
    redirectToLogin: sfAuth.redirectToLogin,
    redirectToLogout: sfAuth.redirectToLogout,
    error: authError,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
