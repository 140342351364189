import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import muStyles from '../../styles/material-ui/select.module.scss';

interface WeekDaySelectProps {
  disabled: boolean;
  name: string;
  onSelectChange: (event: SelectChangeEvent<string>) => void;
  value: string;
}

const WeekDaySelect = ({
  disabled,
  name,
  onSelectChange,
  value,
}: WeekDaySelectProps): JSX.Element => {
  return (
    <FormControl variant="outlined" className={muStyles.select}>
      <Select
        disabled={disabled}
        native
        value={value === null ? '0' : value}
        onChange={onSelectChange}
        inputProps={{
          name,
          id: name,
        }}
      >
        <option value="0">-- Week Day --</option>
        <option value="1">Monday</option>
        <option value="2">Tuesday</option>
        <option value="3">Wednesday</option>
        <option value="4">Thursday</option>
        <option value="5">Friday</option>
        <option value="6">Saturday</option>
        <option value="7">Sunday</option>
      </Select>
    </FormControl>
  );
};

export default WeekDaySelect;
