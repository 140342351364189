import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { generateRandomString } from 'helpers/string';
import { useMemo } from 'react';

import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/format-selection.module.scss';

interface FormatSelectionProps {
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
}

const FormatSelection = ({
  onChange,
  value,
}: FormatSelectionProps): JSX.Element => {
  const randomString = useMemo(() => {
    return generateRandomString();
  }, []);

  return (
    <div className={styles.formatSelection}>
      <FormControl variant="outlined" className={muStyles.select}>
        <label htmlFor={`table-format-${randomString}`}>Format:</label>{' '}
        <Select
          native
          value={value || 'default'}
          onChange={onChange}
          inputProps={{
            name: `table-format-${randomString}`,
            id: `table-format-selection-${randomString}`,
          }}
        >
          <option value="default">Default</option>
          <option value="currency">Currency</option>
          <option value="date">Date</option>
          <option value="month">Month</option>
          <option value="percent">Percent</option>
          <option value="weekDay">Week Day</option>
        </Select>
      </FormControl>
    </div>
  );
};

export default FormatSelection;
