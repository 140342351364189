import { date } from 'yup';
import { subtractValues } from './numbers';

export const monthList = [
  'January',
  'Feburary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthListShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const weekList = [
  'Saturday',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

// Output Week days based on offset.
export const weekDates = (curDate: Date, dayOfWeek: number): any => {
  const week: any = [];

  if (!date) {
    return week;
  }

  const currentDateTime = new Date(curDate).getTime();
  const startDateTime = currentDateTime - 24 * 60 * 60 * 1000 * dayOfWeek;
  const startDate = new Date(startDateTime);

  for (let i = 0; i < 7; i++) {
    const dayTimeValue = new Date(startDate).setDate(startDate.getDate() + i);
    const _date = new Date(dayTimeValue);
    const dd = _date.getDate();
    const mm = _date.getMonth() + 1;
    const yyyy = _date.getFullYear().toString().substring(2);
    const day = `${mm}/${dd}/${yyyy}`;
    week.push(day);
  }

  return week;
};

// Takes data from weekDates function and determines what month to display for a given week.
export const currentMonthDisplay = (currentWeekList: string[]): string => {
  if (!currentWeekList.length) return '';

  let currentMonth = 0;
  let iterator = 1;
  currentWeekList.forEach((weekDay: string) => {
    const dayBits = weekDay.split('/');
    const monthNumber = parseInt(dayBits[0]);
    if (iterator < 4) {
      currentMonth = monthNumber !== currentMonth ? monthNumber : currentMonth;
    }
    iterator++;
  });

  return monthList[currentMonth - 1];
};

/**
 * Checks if current date is found within weekList.
 */
export const checkDateToWeekDayList = (currentWeekList: string[]): boolean => {
  if (!currentWeekList.length) return false;

  let currentDate: Date | string = new Date();
  const dd = currentDate.getDate();
  const mm = currentDate.getMonth() + 1;
  const yyyy = currentDate.getFullYear().toString().substring(2);
  currentDate = `${mm}/${dd}/${yyyy}`;

  let hasWeekDay = false;
  for (let i = 0; i <= currentWeekList.length - 1; i++) {
    const day = currentWeekList[i];

    if (day === currentDate) {
      hasWeekDay = true;
      break;
    }
  }

  return hasWeekDay;
};

/**
 * Helps get date for beginning and end of yard using
 * 52 week years.
 */
export const getWeekDateStartPosition = (
  data: any,
  dayOfWeek: number,
  yearPeriod: 'start' | 'end' = 'start',
  weekInterval = 52
): Date => {
  let result: Date = new Date();

  try {
    if (!data.length) {
      throw new Error('Data not found for getWeekDateBasedYearPosition.');
    }

    const firstDayOfWeek = data[0].dayOfWeek;
    const msWeek: number = 7 * 24 * 60 * 60 * 1000;
    const iterator = Array.from(Array(weekInterval).keys(), (n) => n + 1);
    let _date = new Date(firstDayOfWeek).getTime();

    let count = 0;
    iterator.forEach(() => {
      count++;
      if (count !== 52) {
        _date =
          yearPeriod === 'end'
            ? new Date(_date - msWeek).getTime()
            : new Date(_date + msWeek).getTime();
      }
    });

    // Add dayOfWeek offset so the correct result for weekDates function works.
    result = new Date(_date);
    result.setDate(result.getDate() + (dayOfWeek - 1));
  } catch (error: any) {
    console.error(error);
  }
  return result;
};

/**
 * Get date with correct timezone offset.
 **/
export function getCorrectDate(_date: Date): string {
  const offset = _date.getTimezoneOffset();
  const newDate = new Date(_date.getTime() - offset * 60 * 1000)
    .toISOString()
    .slice(0, -1);
  return newDate.split('T')[0];
}

/**
 * Get date x days in the past of current date.
 */
export function getPastDate(days: number): string {
  let _date: Date | string = new Date();
  _date.setDate(_date.getDate() - days);
  _date = getCorrectDate(_date);
  return _date;
}
