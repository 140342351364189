import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { hasValue, searchObject } from 'helpers/object';
import { humanizer } from 'helpers/string';
import { useEffect, useState } from 'react';
import muStyles from '../../styles/material-ui/select.module.scss';
import styles from '../../styles/components/fields/limit.module.scss';

interface LimitProps {
  disabled: boolean;
  fields: any;
  filters: any;
  onChange: (value: string) => void;
  value: string;
}

const LimitFilter = ({
  disabled = true,
  fields,
  filters,
  onChange,
  value,
}: LimitProps): JSX.Element => {
  const limitItems =
    typeof value !== 'undefined' ? value.split('|') : ['0', ''];

  const [selectValue, setSelectValue] = useState<string>(limitItems[0]);
  const [inputValue, setInputValue] = useState<string | null>(limitItems[1]);
  const toDate =
    filters !== null ? searchObject('name', 'toDate', filters) : [];

  useEffect(() => {
    onChange(`${selectValue}|${inputValue}`);
  }, [selectValue, inputValue]);

  function onLimitSelectionChange(e: SelectChangeEvent<string>): void {
    const resetInputValue = e.target.value === 'toDate' ? '0' : '';
    setSelectValue(e.target.value as string);
    setInputValue(resetInputValue);
  }

  function onInputChange(e: any): void {
    setInputValue(e.target.value);
  }

  function filterInput(): JSX.Element {
    switch (selectValue) {
      case 'trailingDays':
        return (
          <input
            disabled={disabled}
            type="number"
            name="input-value"
            onChange={onInputChange}
            value={inputValue === null ? '' : inputValue}
          />
        );
      case 'trailingMonths':
        return (
          <input
            disabled={disabled}
            type="number"
            name="input-value"
            onChange={onInputChange}
            value={inputValue === null ? '' : inputValue}
          />
        );
      case 'toDate':
        return (
          <FormControl
            variant="outlined"
            className={`${muStyles.select} ${styles.limitSelect}`}
          >
            <Select
              disabled={disabled}
              native
              value={inputValue === null ? '' : inputValue}
              onChange={onInputChange}
              inputProps={{
                name: 'input-value',
                id: 'input-value',
              }}
            >
              <option value="0">-- Select --</option>
              {typeof toDate[0] !== 'undefined' &&
                toDate[0].inputFields.map((field: any, index: number) => (
                  <option key={index} value={field.name}>
                    {humanizer(field.name)}
                  </option>
                ))}
            </Select>
          </FormControl>
        );
      default:
        return <input disabled={true} type="text" />;
    }
  }

  return (
    <div className={styles.limit}>
      <h2>Limit: </h2>
      <div className={styles.limitWrapper}>
        <FormControl
          variant="outlined"
          className={`${muStyles.select} ${styles.limitSelect}`}
        >
          <Select
            disabled={disabled}
            native
            value={selectValue}
            onChange={onLimitSelectionChange}
            inputProps={{
              name: 'limit-type',
              id: 'limit-type',
            }}
          >
            <option value="0">-- Select --</option>
            {hasValue(fields, 'saleDate') && (
              <option value="trailingDays">Trailing Days</option>
            )}
            {hasValue(fields, 'month') && (
              <option value="trailingMonths">Trailing Months</option>
            )}
            {toDate.length > 0 && <option value="toDate">To Date</option>}
          </Select>
        </FormControl>
        {filterInput()}
      </div>
    </div>
  );
};

export default LimitFilter;
