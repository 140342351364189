import SettingsIcon from '@mui/icons-material/Settings';
import { ClickAwayListener } from '@mui/material';
import { useSnacks } from 'auth';
import useModal, { ModalActions } from 'hooks/useModal';
import useSyncedDashboards from 'hooks/useSyncedDashboardAPI';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  selectCurrentDashboard,
  selectDashboardHasBeenEdited,
} from 'store/selectors/dashboard';
import { setIsCreating, setIsEditing } from 'store/slices/dashboardSlice';
import styles from '../../styles/components/dashboard-layout/edit-menu.module.scss';

const EditMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const modal: ModalActions = useModal();

  const { saveNewDashboard, updateDashboard } = useSyncedDashboards();
  const { openSnack } = useSnacks();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItemLength, setMenuItemLength] = useState<number>(1);

  // get current dashboard from redux
  const { isEditing, isCreating } = useSelector(
    (state: RootState) => state.dashboard
  );
  const currentDashboard = useSelector(selectCurrentDashboard);
  const dashboardHasBeenEdited = useSelector(selectDashboardHasBeenEdited);

  /**
   * Check if layout has layout items.
   */
  const hasLayout = useMemo(() => {
    if (currentDashboard === null) {
      return false;
    }
    return currentDashboard.layout.length > 0;
  }, [currentDashboard?.layout.length]);

  /**
   * Displays the menu options on click.
   */
  function handleLayoutSettingsClick(e: any): void {
    let menuLength = 1;
    const parentChildItems = e.target.closest('[data-settings]').children;
    if (typeof parentChildItems !== 'undefined') {
      const items = Object.values(parentChildItems);
      const editMenu = items[items.length - 1] as HTMLElement;
      menuLength = editMenu.querySelectorAll('li').length;
    }
    setMenuItemLength(menuLength);

    if (menuOpen) {
      setMenuItemLength(1);
    } else {
      setMenuOpen(true);
    }
  }

  /**
   * Manages editing layout or creating a new empty layout.
   */
  function handleEditClick(e: any): void {
    if (isEditing) {
      return;
    }

    const isNewDashboard =
      typeof e.target.dataset.newLayout !== 'undefined' &&
      currentDashboard !== null;

    if (isNewDashboard) {
      dispatch(setIsCreating(true));
    } else {
      dispatch(setIsEditing(true));
    }

    setMenuOpen(false);
  }

  function handleNewDashboardClick(): void {
    dispatch(setIsCreating(true));
    setMenuOpen(false);
  }

  /**
   * Existing Layout Config Update Save.
   */
  async function handleSave(): Promise<void> {
    // Cancel, nothing was edited.
    if (currentDashboard === null || !dashboardHasBeenEdited) {
      openSnack('Dashboard saved!', 'success');
      handleCancel();
      return;
    }

    if (isCreating) {
      saveCreatedDashboard();
    }

    if (isEditing) {
      saveUpdatedDashboard();
    }

    setMenuOpen(false);
  }

  function endEditing(): void {
    if (isCreating) {
      dispatch(setIsCreating(false));
    } else if (isEditing) {
      dispatch(setIsEditing(false));
    }
  }

  function handleSaveAs(): void {
    modal.setState({
      ...modal.state,
      type: 'saveLayout',
      open: true,
    });

    setMenuOpen(false);
  }

  async function saveCreatedDashboard(): Promise<void> {
    try {
      await saveNewDashboard(currentDashboard as SFDashboard);
      openSnack('Dashboard saved!', 'success');
      endEditing();
    } catch (e) {
      console.error(e);
      openSnack(
        'There was an error saving your new dashboard. If this issue persists, please contact your Silver Fern Client partner.',
        'error'
      );
    }
  }

  async function saveUpdatedDashboard(): Promise<void> {
    try {
      await updateDashboard(currentDashboard as SFDashboard);
      openSnack('Dashboard saved!', 'success');
      endEditing();
    } catch (e) {
      console.error(e);
      openSnack(
        'There was an error saving your dashboard. If this issue persists, please contact your Silver Fern Client partner.',
        'error'
      );
    }
  }

  /**
   * Deletes selected layout using dashboard hook
   * functions. And then resets view to default layout.
   */
  function handleDeleteClick(): void {
    modal.setState({
      ...modal.state,
      type: 'deleteLayout',
      open: true,
    });

    setMenuOpen(false);
  }

  const handleCancel = (): void => {
    if (isEditing) {
      dispatch(setIsEditing(false));
    }

    if (isCreating) {
      dispatch(setIsCreating(false));
    }

    setMenuOpen(false);
  };

  return (
    <div className={styles.editMenu}>
      <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
        <div className={styles.settings} data-settings="true">
          <div className={styles.editIcon} onClick={handleLayoutSettingsClick}>
            <SettingsIcon />
          </div>
          <div
            className={`${styles.optionContainer} ${
              menuOpen ? styles.open : ''
            }`}
            data-menu-length={menuItemLength.toString()}
          >
            <ul>
              {!isCreating && !isEditing && (
                <li onClick={handleNewDashboardClick}>New Dashboard</li>
              )}
              {isEditing || isCreating ? (
                <>
                  {currentDashboard !== null &&
                  !currentDashboard.isDefault &&
                  hasLayout &&
                  !isCreating ? (
                    <li onClick={handleSave}>Save</li>
                  ) : null}

                  {hasLayout ? <li onClick={handleSaveAs}>Save As</li> : null}

                  {currentDashboard !== null &&
                  !currentDashboard.isDefault &&
                  hasLayout &&
                  !isCreating ? (
                    <li onClick={handleDeleteClick}>Delete</li>
                  ) : null}
                  <li onClick={handleCancel}>Cancel</li>
                </>
              ) : (
                <li onClick={handleEditClick}>Edit</li>
              )}
            </ul>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default EditMenu;
