import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalState, setModalState } from 'store/slices/modalSlice';
import { RootState } from '../store';
import useChartConfig, { ChartConfigActions } from './useChartConfig';

export interface ModalActions {
  open(): void;
  close: () => void;
  setState(state: ModalState): void;
  state: ModalState;
}

const useModal = (): ModalActions => {
  const dispatch = useDispatch();
  const modalState: ModalState = useSelector((state: RootState) => state.modal);

  /**
   * Open Modal
   */
  const open = useCallback((): void => {
    const modalStateConfig: ModalState = {
      ...modalState,
      open: !modalState.open,
    };
    dispatch(setModalState(modalStateConfig));
  }, [dispatch, modalState]);

  /**
   * Set and update current modal state.
   */
  const setState = useCallback(
    (modifiedState: ModalState) => {
      dispatch(setModalState(modifiedState));
    },
    [dispatch]
  );

  /**
   * Close Modal
   */
  const close = useCallback((): void => {
    const modalStateConfig: ModalState = {
      ...modalState,
      dashboardItemID: null,
      open: false,
      type: null,
    };
    dispatch(setModalState(modalStateConfig));
  }, []);

  const state = useMemo(() => {
    return modalState;
  }, [modalState]);

  return {
    close,
    open,
    setState,
    state,
  };
};

export default useModal;
