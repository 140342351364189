import useModal, { ModalActions } from 'hooks/useModal';
import { useMemo } from 'react';
import ConfirmDelete from './Content/ConfirmDelete';
import SaveLayout from './Content/SaveLayout';

import styles from '../../styles/components/modals/modal.module.scss';
import ModalWrapper from './ModalWrapper';

const Modal = (): JSX.Element => {
  const modal: ModalActions = useModal();

  /**
   * The modal component content is loaded by detecting
   * the update width modal state in redux.
   */
  const renderContent = useMemo(() => {
    if (!modal.state.open) {
      return null;
    }

    const { dashboardItemID } = modal.state;

    let component: JSX.Element | null = null;
    switch (modal.state.type) {
      case 'editConfig':
        if (dashboardItemID === null) {
          throw new Error(
            'No dashboard item index was provided for configuring.'
          );
        }
        component = <ModalWrapper itemID={dashboardItemID} />;
        break;
      case 'deleteLayout':
        component = <ConfirmDelete />;
        break;
      case 'saveLayout':
        component = <SaveLayout />;
        break;
      default:
        component = null;
        break;
    }

    return component;
  }, [modal.state.type, modal.state.open]);

  const resetModal = (): void => {
    modal.close();
  };

  return (
    <div className={styles.modal} data-open={modal.state.open}>
      <div className={styles.background}></div>
      <div className={styles.contentWrapper}>
        <div className={styles.resetScreen} onClick={resetModal}></div>
        <div className={styles.content}>{renderContent}</div>
      </div>
    </div>
  );
};

export default Modal;
