import { IntrospectiveQueryField } from 'helpers/query';
import { humanizer } from 'helpers/string';
import styles from '../../styles/components/fields/available-fields.module.scss';
import { searchObject } from 'helpers/object';

interface AvailableFieldsProps {
  fields: IntrospectiveQueryField[];
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const fieldBlacklist = [
  'comparison',
  'computedUnitsOnHand',
  'computedWeeksOnHand',
];

const AvailableFields = ({
  onClick,
  fields,
}: AvailableFieldsProps): JSX.Element => {
  return (
    <div id="available-fields" className={styles.availableFields}>
      <h2>Available Fields:</h2>
      <div
        className={styles.availableFieldList}
        onClick={onClick}
        data-has-feedback="true"
      >
        {fields.length > 0 &&
          fields.map((field: IntrospectiveQueryField, index: number) => {
            if (fieldBlacklist.includes(field.name)) {
              return null;
            }

            return (
              <div
                id={`${field.name}-id`}
                data-field={field.name}
                key={index}
                data-field-name={`${field.name}`}
                data-input-fields={JSON.stringify(field.inputFields)}
              >
                {humanizer(field.name)}
              </div>
            );
          })}
      </div>

      <div className={styles.fadeOut}></div>
    </div>
  );
};

export default AvailableFields;
