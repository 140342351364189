// Capitalizes the first letter of a string.
export function capitalizeFirstLetter(string: string | null): string {
  return string !== null
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : '';
}

// Makes first letter of a string lowercase.
export function lowercaseFirstLetter(string: string | null): string {
  return string !== null
    ? string.charAt(0).toLowerCase() + string.slice(1)
    : '';
}

export function generateRandomString(length = 8): string {
  return Math.random().toString(16).substring(2, length);
}

export function hashString(str: string): number {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function humanizer(str: string): string {
  if (typeof str === 'undefined') return '';

  return str
    .replace(/([A-Z][A-Za-z])/g, ' $1')
    .replace(/^./, (_str) => _str.toUpperCase());
}
