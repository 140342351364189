import { Paper } from '@mui/material';
import { Spinner } from 'auth';
import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Export,
  Font,
  Grid,
  Label,
  Legend,
  SeriesTemplate,
  Tick,
  ValueAxis,
} from 'devextreme-react/chart';
import useColorPalette from 'hooks/useColorPalette';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { removeHoverHashing } from '../../helpers/hashing';
import { formatToDollar } from '../../helpers/format';
import { PaletteConfig } from '../../palette';
import { RootState } from '../../store';

import styles from '../../styles/components/charts/sales-ytd.module.scss';
import '../../styles/material-ui/paper.scss';

export interface ISalesYTD {
  arg: string;
  value: number;
  tag: string;
}

const SalesYTD = ({ redrawKey }: DashboardComponentProps): JSX.Element => {
  const { data, hasData, isLoading, setDataQuery } = useData();

  const { themePalette } = useColorPalette();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-l',
  ]);

  const calendarLoaded = useSelector(
    (state: RootState) => state.calendar.loaded
  );
  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );

  const [chartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    argField: '',
    query: 'yearlySalesOld',
    valueFields: [{ field: 'lyRetail' }, { field: 'tyRetail' }],
  });

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendarLoaded && retailerLoaded) {
      setDataQuery(chartSettings);
    }
  }, [calendarLoaded, retailerLoaded, chartSettings]);

  /**
   * Set data here.
   */
  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    const updatedData: ISalesYTD[] = [
      {
        arg: data[0].lyRetail.toString(),
        value: parseInt(data[0].lyRetail),
        tag: 'previousYear',
      },
      {
        arg: data[0].tyRetail.toString(),
        value: parseInt(data[0].tyRetail),
        tag: 'currentYear',
      },
    ];

    setChartData(updatedData);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId]);

  const customizeText = (e: any): string => {
    return formatToDollar(e.value);
  };

  const colorPalette = useMemo(() => {
    return themePalette.salesYTD as PaletteConfig;
  }, [themePalette]);

  return (
    <div className={styles.salesYTD}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <div className={styles.heading}>
          <h2>Sales YTD</h2>
        </div>
        {!loading ? (
          hasData ? (
            <Chart
              id="sales-year-to-date-chart"
              key={redrawKey}
              dataSource={chartData}
              rotated={true}
              customizePoint={removeHoverHashing}
              palette={colorPalette.chart}
              animation={animate}
            >
              <CommonSeriesSettings
                type="bar"
                argumentField="arg"
                valueField="value"
                ignoreEmptyPoints={true}
              />
              <SeriesTemplate nameField="arg" />
              <ArgumentAxis visible={true}>
                <Tick visible={false} />
                <Label visible={true} customizeText={customizeText}>
                  <Font color={colorPalette.font} size={12} weight={600} />
                </Label>
              </ArgumentAxis>
              <ValueAxis visible={false}>
                <Grid visible={false} />
                <Label visible={false} />
                <Tick visible={false} />
              </ValueAxis>
              <Legend visible={false} />
              <Export enabled={false} />
            </Chart>
          ) : (
            <div className={styles.notFound}>
              <p>No data found.</p>
            </div>
          )
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner active={true} background={'clear'} />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default SalesYTD;
