export const largestArrayValue = (array: any, offset = 50): number => {
  let value = 0;
  if (!array.length) {
    return value;
  }
  array.forEach((item: any) => {
    if (item > value) {
      value = item;
    }
  });
  return value + offset;
};

export const smallestArrayValue = (array: any, offset = 25): number => {
  let value = 0;
  if (!array.length) {
    return value;
  }
  array.forEach((item: any) => {
    if (item < value) {
      value = item;
    }
  });

  return value === 0 ? value : value - offset;
};

/**
 * Checks if array has all 0 values.
 * If all zero values are found an empty array if returned.
 * Else, the original array is retured.
 */
export const checkForZeroValues = (array: any): any => {
  let hasAllZeroValues = true;

  for (let i = 0; i <= array.length - 1; i++) {
    const value = array[i];

    if (typeof value !== 'number' || value !== 0) {
      hasAllZeroValues = false;
      break;
    }
  }
  return hasAllZeroValues ? [] : array;
};

export const dedupeArray = (array: any[]): any[] => {
  const seen: any = {};
  const out = [];
  const len = array.length;
  let j = 0;
  for (let i = 0; i < len; i++) {
    const item: any = array[i];
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }
  return out;
};

/**
 * Merges an array of objects into one flattend object.
 */
export const mergeArrayofObjects = (array: any[]): any => {
  if (!array.length) return {};
  if (array.length === 1) return array[0];

  let newObject = {};

  for (let i = 0; i <= array.length - 1; i++) {
    const currentObject = array[i];
    newObject = Object.assign(newObject, currentObject);
  }
  return newObject;
};

export const sortAlphabetically = (array: string[]): any[] => {
  const sortArray = array.slice(); // create copy of array so its mutable.
  sortArray.sort((a: string, b: string): number =>
    a > b ? 1 : b > a ? -1 : 0
  );
  return sortArray;
};

/**
 * Helps sort an array of objects by a specific property name.
 */
export const sortObjectArrayByPropertyName = (
  array: any[],
  propertyName: string
): any[] => {
  const sortArray = array.slice(); // create copy of array so its mutable.
  sortArray.sort((a: any, b: any): number =>
    a[propertyName] > b[propertyName]
      ? 1
      : b[propertyName] > a[propertyName]
      ? -1
      : 0
  );
  return sortArray;
};

export const arrayIndexFromObjectKeyValue = (
  array: any,
  key: string,
  value: any
): number => {
  let selectedIndex = -1;

  for (let i = 0; i <= array.length - 1; i++) {
    const arrayItem = array[i];
    const arrayValue = arrayItem[key];
    if (value === arrayValue) {
      selectedIndex = i;
      break;
    }
  }

  return selectedIndex;
};

/**
 * Convert number into an array of numbers.
 */
export const numberToArray = (number: number): number[] => {
  const array: number[] = [];
  for (let i = 1; i <= number; i++) {
    array.push(i);
  }
  return array;
};
