import sfAuth from 'auth/SFAuth';
import { useEffect, useState } from 'react';
import styles from '../../styles/components/subcomponents/error-message.module.scss';

type MessageType = 'access' | 'error' | '404';

interface IMessage {
  showLogout?: boolean;
  type: MessageType;
}

const ErrorMessage = ({ type, showLogout }: IMessage): JSX.Element => {
  const [displayLogout, setDisplayLogout] = useState<boolean>(true);

  const logout = (): void => {
    sfAuth.redirectToLogout();
  };

  useEffect(() => {
    if (typeof showLogout !== 'undefined') {
      setDisplayLogout(showLogout);
    }
  }, [showLogout]);

  return (
    <div className={styles.errorMessage}>
      {displayLogout ? (
        <div className={styles.buttonContainer}>
          <button onClick={logout}>Logout</button>
        </div>
      ) : null}
      <div className={styles.wrapper}>
        <div className={styles.message}>
          {type === 'access' ? (
            <p>
              Currently you do not have access to this product.
              <span>
                If this is an issue or you are interested in accessing this
                product contact your client partner.
              </span>
            </p>
          ) : null}
          {type === 'error' ? (
            <p>
              Oops! We have encountered an error.
              <span>
                Please contact your client partner and we will get this
                resolved.
              </span>
            </p>
          ) : null}
          {type === '404' ? (
            <p>
              Oops! This is awkward...
              <span>
                The page you are looking for unfortunately does not exist.
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
