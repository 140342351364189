import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { Spinner } from 'auth';
import Config from 'config';
import { useEffect, useState } from 'react';

interface SFApolloProviderProps {
  children: React.ReactNode;
}

const SFApolloProvider: React.FC<SFApolloProviderProps> = ({ children }) => {
  const [apolloClient, setApolloClient] =
    useState<Maybe<ApolloClient<NormalizedCacheObject>>>(null);

  useEffect(() => {
    initApolloClient();
  }, []);

  async function initApolloClient(): Promise<void> {
    const httpLink = createHttpLink({
      uri: Config.apolloUri,
      useGETForQueries: true,
      credentials: 'include',
    });

    const cache = new InMemoryCache();
    const newPersistor = new CachePersistor({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      debug: Config.useDebug,
      trigger: 'write',
    });
    await newPersistor.restore();

    setApolloClient(
      new ApolloClient({
        link: httpLink,
        cache,
        connectToDevTools: process.env.NODE_ENV === `development`,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
          },
        },
      })
    );
  }

  return (
    <>
      {apolloClient === null ? (
        <Spinner active={true} background={'clear'} />
      ) : (
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      )}
    </>
  );
};

export default SFApolloProvider;
