import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import deepEqual from 'deep-equal';

export interface SFDashboardSyncState {
  dashboards: Maybe<SFDashboard[]>;
  currentDashboardId: Maybe<string>;
  /** Whether or not the current dashboard is being edited */
  isEditing: boolean;
  /** Whether or not a new dashboard is being created */
  isCreating: boolean;
  /** When editing, copy of current dashboard is stored here and edits are made to it
   * until save or cancel (so as to not modify the original) */
  dashboardDiff: Maybe<SFDashboard | SFDashboardInit>;
  open: boolean;
  lastDashboardForProduct: {
    [key in ProductCode]?: string;
  };
}

const initialState: SFDashboardSyncState = {
  dashboards: null,
  currentDashboardId: null,
  isEditing: false,
  isCreating: false,
  dashboardDiff: null,
  open: true,
  lastDashboardForProduct: {},
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboards(state, action: PayloadAction<SFDashboard[]>) {
      state.dashboards = action.payload;
    },
    setCurrentDashboard(state, action: PayloadAction<string>) {
      // Store dashboardId for last dashboard product
      const currentDashboard =
        state.dashboards?.find(
          (_dashboard) => _dashboard.id === action.payload
        ) || null;
      if (currentDashboard) {
        const code = currentDashboard.productName.toLowerCase() as ProductCode;
        state.lastDashboardForProduct[code] = currentDashboard.id;
      }

      state.currentDashboardId = action.payload;
    },
    setDashboardDiff(
      state,
      action: PayloadAction<SFDashboard | SFDashboardInit>
    ) {
      state.dashboardDiff = action.payload;
    },
    setIsEditing(state, action: PayloadAction<boolean>) {
      // Have to get `current` state to pass through redux's `Proxy` and copy just the original object
      const currentState = current(state);
      const currentDashboard =
        currentState.dashboards?.find(
          (_dashboard) => _dashboard.id === state.currentDashboardId
        ) || null;

      // If there are no dashboards or current dashboard is
      // a default dashboard, don't allow editing
      if (
        state.dashboards === null ||
        state.dashboards.length === 0 ||
        !state.currentDashboardId ||
        currentDashboard?.isDefault
      ) {
        return;
      }

      state.isEditing = action.payload;

      // Editing turned off; wipe modifiedDashboard where diffs were stored
      if (!state.isEditing) {
        state.dashboardDiff = null;
        return;
      }

      // Editing turned on; now create a copy of the current dashboard to edit

      if (currentDashboard === null) {
        return;
      }

      // Make the copy
      state.dashboardDiff = structuredClone(currentDashboard);
    },
    setIsCreating(state, action: PayloadAction<boolean>) {
      const currentState = current(state);
      const currentDashboard =
        currentState.dashboards?.find(
          (_dashboard) => _dashboard.id === state.currentDashboardId
        ) || null;

      state.isCreating = action.payload;
      if (!state.isCreating || currentDashboard === null) {
        state.dashboardDiff = null;
        return;
      }

      // Create enabled; create new dashboard object
      state.dashboardDiff = {
        id: 'tbd',
        name: '',
        isDefault: false,
        layout: [],
        productCode: currentDashboard.productCode,
        productName: currentDashboard.productName,
        // Default to user scope; user can update to shared later
        scope: 'user',
      };
    },
    setUpdatedLayout(state, action: PayloadAction<SFDashboardItem[]>) {
      // No updates made
      if (
        state.dashboardDiff?.layout === undefined ||
        deepEqual(state.dashboardDiff?.layout, action.payload)
      ) {
        return;
      }

      // Apply changes to `dashboardDiff` if editing
      if ((state.isEditing || state.isCreating) && state.dashboardDiff) {
        state.dashboardDiff.layout = action.payload;
      } else {
        throw new Error("You can't update a layout if you're not editing.");
      }
    },
    setDashboardItemConfiguration(
      state,
      action: PayloadAction<{ itemID: string; config: DashboardItemConfig }>
    ) {
      const { itemID, config } = action.payload;
      if (state.dashboardDiff?.layout === undefined) {
        return;
      }

      // Apply changes to `dashboardDiff` if editing/creating
      if ((state.isEditing || state.isCreating) && state.dashboardDiff) {
        const index = state.dashboardDiff.layout.findIndex(
          (item) => item.id === itemID
        );

        if (index === -1) {
          throw new Error(
            `Could not find dashboard item with ID ${itemID} in current dashboard.`
          );
        }

        state.dashboardDiff.layout[index].chartConfigurations = config;
      } else {
        throw new Error("You can't update a layout if you're not editing.");
      }
    },
    setCurrentDashboardOnProductChange(
      state,
      action: PayloadAction<ProductCode>
    ) {
      // Otherwise, select first
      const product = action.payload;

      // Use last selected if stored
      const lastDashboardForProduct = state.lastDashboardForProduct[product];
      if (lastDashboardForProduct !== undefined) {
        state.currentDashboardId = lastDashboardForProduct;
        return;
      }

      // Else select first
      const dashboardsForProduct = state.dashboards?.filter(
        (_dashboard) =>
          _dashboard.productCode === product ||
          _dashboard.productName.toLowerCase() === product
      );

      if (dashboardsForProduct && dashboardsForProduct.length > 0) {
        const dashboardId = dashboardsForProduct[0].id;
        state.lastDashboardForProduct[product] = dashboardId;
        state.currentDashboardId = dashboardId;
      }
    },
    setDashboardItemCustomState(
      state,
      action: PayloadAction<{ itemID: string; customState: any }>
    ) {
      const { itemID, customState } = action.payload;

      // Have to get `current` state to pass through redux's `Proxy` and copy just the original object
      // const currentState = current(state);
      const currentDashboard =
        state.dashboards?.find(
          (_dashboard) => _dashboard.id === state.currentDashboardId
        ) || null;

      const activeDashboard =
        state.isEditing || state.isCreating
          ? state.dashboardDiff
          : currentDashboard;

      if (activeDashboard === null) {
        throw new Error('No active dashboard found for custom state update.');
      }

      const index = activeDashboard.layout.findIndex(
        (item) => item.id === itemID
      );

      if (index === -1) {
        throw new Error(
          `Could not find dashboard item with ID ${itemID} in current dashboard.`
        );
      }

      if (!activeDashboard.layout[index].chartConfigurations) {
        throw new Error(`No configuration found for item ${itemID}`);
      }

      activeDashboard.layout[index].chartConfigurations!.customState =
        customState;
    },
  },
});

export const {
  setDashboards,
  setCurrentDashboard,
  setIsEditing,
  setIsCreating,
  setDashboardDiff,
  setUpdatedLayout,
  setDashboardItemConfiguration,
  setCurrentDashboardOnProductChange,
  setDashboardItemCustomState,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
