import {
  ApolloQueryResult,
  OperationVariables,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { parseGraphQLDashboard } from 'helpers/parsing';
import { useState } from 'react';
import * as graph from '../../data/graph/dashboards';

interface RemoteDashboardAPI {
  loading: boolean;
  fetchAllDashboards: () => Promise<SFDashboard[]>;
  saveNewDashboard: (
    productID: string,
    newDashboard: SFDashboardInit
  ) => Promise<SFDashboard>;
  updateDashboard: (dashboard: SFDashboard) => Promise<SFDashboard>;
  deleteDashboard: (dashboardId: string) => Promise<void>;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}

/** CRUD operations for remote dashboards using Apollo GraphQL
 * Nothing fancy. No state (other than loading). No redux.
 * No IndexedDB. Just remote dashboard API methods. */
function useRemoteDashboards(): RemoteDashboardAPI {
  // Using our own `loading` state so loading for all CRUD ops
  // can be tracked in one place
  const [loading, setLoading] = useState(false);

  const [gqlGetAllDashboards, { refetch }] = useLazyQuery(
    graph.GET_ALL_DASHBOARDS
  );
  const [gqlCreateDashboard] = useMutation(graph.CREATE_DASHBOARD);
  const [gqlSaveDashboard] = useMutation(graph.SAVE_DASHBOARD);
  const [gqlDeleteDashboard] = useMutation(graph.DELETE_DASHBOARD);

  async function fetchAllDashboards(): Promise<SFDashboard[]> {
    setLoading(true);
    try {
      const result = await gqlGetAllDashboards();
      if (result.data === undefined) {
        if (result.error) {
          throw new Error(result.error.message);
        }
        throw new Error('Error fetching all dashboards.');
      }

      const remoteDashboards: GraphQLDashboard[] =
        result.data.dashboards.edges.map((edge: any) => edge.node);

      const sfDashboards: SFDashboard[] = remoteDashboards.map(
        parseGraphQLDashboard
      );
      return sfDashboards;
    } catch (e) {
      console.error(e);
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function saveNewDashboard(
    productID: string,
    dashboard: SFDashboardInit
  ): Promise<SFDashboard> {
    setLoading(true);
    try {
      const result = await gqlCreateDashboard({
        variables: {
          name: dashboard.name,
          productID,
          scope: dashboard.scope.toUpperCase(),
          layoutJson: JSON.stringify(dashboard.layout),
        },
      });

      if (result.data === undefined) {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        throw new Error('Error saving new dashboard.');
      }

      const remoteDashboard: GraphQLDashboard = result.data.createDashboard;

      const sfDashboard = parseGraphQLDashboard(remoteDashboard);
      return sfDashboard;
    } finally {
      setLoading(false);
    }
  }

  async function updateDashboard(dashboard: SFDashboard): Promise<SFDashboard> {
    setLoading(true);
    try {
      const result = await gqlSaveDashboard({
        variables: {
          id: dashboard.id,
          name: dashboard.name,
          layoutJson: JSON.stringify(dashboard.layout),
        },
      });

      if (result.data === undefined) {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }

        throw new Error('Error updating dashboard.');
      }

      const remoteDashboard: GraphQLDashboard = result.data.saveDashboard;

      const sfDashboard = parseGraphQLDashboard(remoteDashboard);
      return sfDashboard;
    } finally {
      setLoading(false);
    }
  }

  async function deleteDashboard(dashboardId: string): Promise<void> {
    setLoading(true);
    try {
      const result = await gqlDeleteDashboard({
        variables: {
          id: dashboardId,
        },
      });

      if (result.data === undefined) {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        throw new Error('Error deleting dashboard.');
      }
    } finally {
      setLoading(false);
    }
  }

  return {
    fetchAllDashboards,
    saveNewDashboard,
    updateDashboard,
    deleteDashboard,
    refetch,
    loading,
  };
}

export default useRemoteDashboards;
