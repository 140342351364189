import { FormControl, InputLabel, Select } from '@mui/material';
import useDisplayProductSection from 'hooks/useDisplayProductSection';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from 'store/slices/queryStringSlice';
import { setCurrentRetailer } from 'store/slices/retailerSlice';
import { RootState } from '../store';
import styles from '../styles/components/data-controls.module.scss';
import muStyles from '../styles/material-ui/select.module.scss';

const datePeriods: RangeType[] = [
  'Month-To-Date',
  'Week-To-Date',
  'Year-To-Date',
];

const hiddenProducts: ProductCode[] = ['produce'];

export type DisplayDirection = 'horizontal' | 'vertical';

interface DataControlsProps {
  layout: DisplayDirection;
  colorful?: boolean;
}

const DataControls = ({
  layout,
  colorful = false,
}: DataControlsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { display } = useDisplayProductSection({ hiddenProducts });

  const [retailerData, setRetailerData] = useState<any>([]);
  const { currentRetailerId } = useSelector(
    (state: RootState) => state.retailer
  );

  const { dateRange } = useSelector((state: RootState) => state.query);

  const retailerList = useSelector(
    (state: RootState) => state.retailer.retailerList
  );

  const controlsLayout =
    layout === 'horizontal' ? styles.horizontal : styles.vertical;
  const colorToggle = colorful ? styles.colorful : '';
  const controlsClass = `${styles.dataControls} ${controlsLayout} ${colorToggle}`;

  useEffect(() => {
    const _data = !display ? [] : retailerList;
    setRetailerData(_data);
  }, [retailerList, display]);

  const allRetailersId = 'all';

  const handleRetailerSelectionChange = (e: any): void => {
    const { value: selectedRetailerId } = e.target;

    if (selectedRetailerId === allRetailersId) {
      dispatch(setCurrentRetailer(null));
    } else {
      dispatch(setCurrentRetailer(selectedRetailerId));
    }
  };

  const handleDateRangeChange = (e: any): void => {
    const { value } = e.target;
    dispatch(setDateRange(value));
  };

  const disableSelect = useCallback(
    (retailers: RetailerType[]): boolean => {
      return retailers.length === 1;
    },
    [retailerData]
  );

  const retailText = useCallback(
    (retailers: RetailerType[]): string => {
      return retailers.length === 1 ? 'Retailer' : 'Retailer Selection';
    },
    [retailerData]
  );

  const retailerOptions = useMemo(() => {
    const selectOptions = retailerData.map((retailData: any, index: number) => {
      return (
        <option value={retailData.id} key={index}>
          {retailData.name}
        </option>
      );
    });
    selectOptions.unshift(
      <option value={allRetailersId} key={-1}>
        All Retailers
      </option>
    );
    return selectOptions;
  }, [retailerData]);

  return (
    <div className={controlsClass}>
      {retailerData.length ? (
        <FormControl variant="outlined" className={muStyles.select}>
          <InputLabel htmlFor="location-data-selection">
            {retailText(retailerData)}
          </InputLabel>
          <Select
            native
            value={currentRetailerId || 'all'}
            onChange={handleRetailerSelectionChange}
            label={retailText(retailerData)}
            inputProps={{
              name: 'location-data',
              id: 'location-data-selection',
            }}
            disabled={disableSelect(retailerData)}
          >
            {retailerOptions}
          </Select>
        </FormControl>
      ) : null}

      <FormControl variant="outlined" className={muStyles.select}>
        <InputLabel htmlFor="location-data-selection">Date Range</InputLabel>
        <Select
          native
          value={dateRange}
          onChange={handleDateRangeChange}
          label="Date Range"
          inputProps={{
            name: 'date-range',
            id: 'date-range-selection',
          }}
        >
          {datePeriods.map((datePeriod: any, index: number) => {
            return (
              <option value={datePeriod} key={index}>
                {datePeriod}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default DataControls;
