import RequireAuth from 'auth/subcomponents/RequireAuth';
import { ConfirmProvider } from 'material-ui-confirm';
import InitDataProvider from 'providers/InitDataProvider';
import ThemeProvider from 'providers/ThemeProvider';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SentryErrorBoundary from '../../sentry/SentryErrorBoundry';
import '../../styles/config/index.scss';
import '../../styles/core-theme.scss';
import AuthProvider from './AuthProvider';
import SFApolloProvider from './SFApolloProvider';
import SnackProvider from './SnackProvider';
import parse from 'html-react-parser';

interface AppContextProps {
  /** The title of the app - appears in the sidebar header and as
   * the browser document title
   */
  appTitle?: string;
  children: React.ReactNode;
}

const AppContext: React.FC<AppContextProps> = ({ appTitle, children }) => {
  const location = useLocation();
  const productTitle = location.pathname.split('/')[1];

  useEffect(() => {
    const title = productTitle || appTitle;
    if (title === undefined) {
      return;
    }
    document.title = parse(
      `${title.toUpperCase()}&#8482; | Silver Fern`
    ).toString();
  }, [productTitle, appTitle]);

  return (
    <AuthProvider>
      <SentryErrorBoundary showDialog={false}>
        <ThemeProvider>
          {/* Make notistack available via `useSnacks` hook */}
          <SnackProvider>
            {/* Simple action confirmation UI */}
            <ConfirmProvider>
              <RequireAuth>
                <SFApolloProvider>
                  <InitDataProvider>{children}</InitDataProvider>
                </SFApolloProvider>
              </RequireAuth>
            </ConfirmProvider>
          </SnackProvider>
        </ThemeProvider>
      </SentryErrorBoundary>
    </AuthProvider>
  );
};

export default AppContext;
