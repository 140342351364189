import { ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import styles from '../../styles/components/sidebar/expand-icon.module.scss';

interface Props {
  open: boolean;
}

const ExpandIcon = ({ open }: Props): JSX.Element => {
  const [classList, setClassList] = useState<string[]>([
    `${styles.expandIcon}`,
  ]);

  useEffect(() => {
    const list = open
      ? [`${styles.expandIcon}`]
      : [`${styles.expandIcon}`, `${styles.open}`];

    setClassList([...list]);
  }, [open]);

  return (
    <div className={classList.join(' ')}>
      <ExpandMore />
    </div>
  );
};

export default ExpandIcon;
