import { RootState } from 'store';
import { initialState as defaultDashboardItemConfig } from 'store/slices/chartConfigSlice';
import { selectDashboardItem } from 'store/selectors/dashboard';
import { setDashboardItemConfiguration } from 'store/slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import DashboardItemConfigEditor from './DataTableConfigEditor';
import DashboardItemConfigSettings from './DataTableConfigSettings';
import useModal, { ModalActions } from 'hooks/useModal';

import styles from '../../../../styles/components/modals/content/chart-config/index.module.scss';

type SettingsTabType = 'chart-config' | 'settings';

interface DataTableConfigProps {
  itemID: string;
}

const DataTableConfig = ({ itemID }: DataTableConfigProps): JSX.Element => {
  const dispatch = useDispatch();
  const modal: ModalActions = useModal();
  const [tab, setTab] = useState<SettingsTabType>('chart-config');

  /**
   * Get item's configuration, or default if it doesn't have one yet.
   */
  const dashboardItem = useSelector((state: RootState) => {
    return selectDashboardItem(state, itemID);
  });

  const dashboardItemConfiguration =
    dashboardItem?.chartConfigurations || defaultDashboardItemConfig;

  const initialConfig =
    dashboardItemConfiguration || defaultDashboardItemConfig;
  const [mutableItemConfiguration, setMutableItemConfiguration] =
    useState<DashboardItemConfig>(initialConfig);

  const onTabClick = (e: any): void => {
    const settingsTab = e.target.dataset.tab as SettingsTabType;
    setTab(settingsTab);
  };

  /**
   * Cancelled, no persisting changes back to DashboardItem in redux.
   */
  function onCancel(): void {
    modal.close();
  }

  /**
   * Persist changes back to DashboardItem in redux.
   */
  function onSubmit(): void {
    const payload = {
      itemID,
      config: mutableItemConfiguration,
    };
    dispatch(setDashboardItemConfiguration(payload));
    modal.close();
  }

  return (
    <div className={styles.chartConfig}>
      {/* <div className={styles.configTabs}>
        <span
          onClick={onTabClick}
          data-tab="chart-config"
          data-selected={tab === 'chart-config'}
        >
          Query Config
        </span>
        <span
          onClick={onTabClick}
          data-tab="settings"
          data-selected={tab === 'settings'}
        >
          Settings
        </span>
      </div> */}
      <form>
        <div
          className={styles.configTab}
          data-section="chart-config"
          data-selected={tab === 'chart-config'}
        >
          <DashboardItemConfigEditor
            itemConfiguration={mutableItemConfiguration}
            didUpdateConfiguration={setMutableItemConfiguration}
          />
        </div>
        <div
          className={styles.settingsTab}
          data-section="settings"
          data-selected={tab === 'settings'}
        >
          <DashboardItemConfigSettings
            itemConfiguration={mutableItemConfiguration}
            didUpdateConfiguration={setMutableItemConfiguration}
          />
        </div>

        <div className={styles.buttonContainer}>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
          <button type="button" onClick={onSubmit}>
            Submit Config
          </button>
        </div>
      </form>
    </div>
  );
};

export default DataTableConfig;
