import { Paper } from '@mui/material';
import { Spinner } from 'auth';
import { Button } from 'devextreme-react/button';
import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  Export,
  Font,
  Grid,
  Label,
  Legend,
  LoadingIndicator,
  Series,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import { generateRandomString } from 'helpers/string';
import useData, { GraphQLQueryConfig } from 'hooks/useData';
import { PaletteConfig } from 'palette';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatToDollar } from '../../helpers/format';
import { RootState } from '../../store';
import styles from '../../styles/components/charts/drilldown-chart.module.scss';
import '../../styles/material-ui/paper.scss';

interface DrilldownChartProps extends DashboardComponentProps {
  argumentAxisTitle?: string;
  barLabel?: boolean;
  chartSettings: GraphQLQueryConfig | null;
  classes?: string[];
  customizeTextCallback?(arg: any): any;
  displayToolTip?: boolean;
  didClickBackBtnCallback?: any;
  didDrilldownCallback?: any;
  didGetDataCallback?: any;
  maxDepth?: number;
  palette: PaletteConfig;
  rotated?: boolean;
  sideBySide?: boolean;
  subTitle?: string;
  title?: string;
  valueAxisTitle?: string;
  valueFieldOne?: string;
  valueFieldTwo?: string;
  toolTipCallback?(arg: any): any;
}

const defaultToolTipCallback = (): null => {
  return null;
};
const defaultCustomizeTextCallback = (e: any): string => {
  return formatToDollar(e.value);
};

const DrillDownChart = ({
  argumentAxisTitle = '',
  barLabel = false,
  chartSettings = null,
  classes = [],
  customizeTextCallback = defaultCustomizeTextCallback,
  didClickBackBtnCallback = null,
  didDrilldownCallback = null,
  didGetDataCallback = null,
  displayToolTip = true,
  maxDepth = 1,
  palette,
  rotated = false,
  sideBySide = true,
  subTitle = '',
  title = '',
  toolTipCallback = defaultToolTipCallback,
  valueAxisTitle = '',
  valueFieldOne = 'tyRetail',
  valueFieldTwo = 'lyRetail',
  redrawKey,
}: DrilldownChartProps): JSX.Element => {
  const { data, hasData, isLoading, setDataQuery } = useData();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [currentRegionID, setCurrentRegionID] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState<any>([]);
  const [currentChartSettings, setCurrentCurrentChartSettings] =
    useState<GraphQLQueryConfig | null>(chartSettings);

  const calendarLoaded = useSelector(
    (state: RootState) => state.calendar.loaded
  );
  const { dateRange } = useSelector((state: RootState) => state.query);
  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );

  // const currentDashboard = useSelector(selectCurrentDashboard);
  // const layoutKey = currentDashboard?.layoutHash || '';

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendarLoaded && retailerLoaded && currentChartSettings !== null) {
      setDataQuery(currentChartSettings);
    }
  }, [calendarLoaded, retailerLoaded, currentChartSettings]);

  /**
   * Set data here.
   */
  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    const responseData: DrilldownDataResponse = didGetDataCallback(
      data,
      currentRegionID,
      level
    );

    setChartData(responseData.data);
    setLevel(responseData.level);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    if (chartSettings !== null) {
      setAnimate(true);
      setLoading(true);
      setCurrentCurrentChartSettings(chartSettings);
    }
  }, [chartSettings]);

  useEffect(() => {
    // Clear level state when retailer changes. Removes back button.
    setLevel([]);
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId, dateRange]);

  const customizePoint = (): dxChartSeriesTypesCommonSeriesPoint => {
    const depth = level.length;
    const chartSeriesPoint: dxChartSeriesTypesCommonSeriesPoint = {
      hoverStyle:
        depth === maxDepth + 1
          ? {
              hatching: 'none',
            }
          : {},
    };
    return chartSeriesPoint;
  };

  /**
   * Click handler for bar drilldown clicks.
   */
  const onPointClick = (e: any): void => {
    if (level.length !== maxDepth) {
      setAnimate(true);
      const value = e.target.originalArgument;

      // Acquire region Sales id to acquire market data.
      const parentItemData = chartData.filter((item: any) => {
        if (item.arg === value) {
          return item;
        }
        return null;
      });

      if (parentItemData !== null) {
        const id = parentItemData[0].id;
        setCurrentRegionID(id);
        didDrilldownCallback(id);
      }
    }
  };

  // Back button click event handler.
  const onBackButtonClick = (): void => {
    setAnimate(true);
    if (level.length === 1) {
      setLevel([]);
      setCurrentRegionID('');
      didClickBackBtnCallback(0, currentRegionID);
      return;
    }

    level.pop();
    setLevel(level);
    didClickBackBtnCallback(level.length, currentRegionID);
  };

  // Handles bar chart rotation.
  const isRotated = (): boolean => {
    return rotated;
  };
  // Displays value/label on bars.
  const displayBarLabel = (): boolean => {
    return barLabel;
  };

  /**
   * Set Custom classes.
   */
  const classList = useMemo((): string => {
    const _classes = [`${styles.box}`, 'paper-box'];
    if (classes.length) {
      classes.forEach((_class: string) => {
        _classes.push(_class);
      });
    }
    return _classes.join(' ');
  }, [classes]);

  return (
    <div className={styles.drilldownBarChart}>
      <Paper square elevation={0} className={classList}>
        <div className={styles.heading}>
          <h2>
            {title}
            <span>{subTitle}</span>
          </h2>
          <Button
            className={styles.button}
            text="Back"
            icon="chevronleft"
            visible={level.length > 0}
            onClick={onBackButtonClick}
          />
        </div>
        {!loading ? (
          hasData ? (
            <>
              <Chart
                id={`drilldown-chart-${generateRandomString()}`}
                key={redrawKey}
                customizePoint={customizePoint}
                onPointClick={onPointClick}
                className={
                  level < 3
                    ? `${styles.drilldownChart} pointer-on-bars`
                    : `${styles.drilldownChart}`
                }
                dataSource={chartData}
                rotated={isRotated()}
                animation={animate}
                palette={palette.chart}
              >
                <CommonAxisSettings allowDecimals={false} />
                {sideBySide ? (
                  <Series type="bar" valueField={valueFieldTwo}>
                    <Label
                      visible={displayBarLabel()}
                      customizeText={customizeTextCallback}
                    />
                  </Series>
                ) : null}

                <Series type="bar" valueField={valueFieldOne}>
                  <Label
                    visible={displayBarLabel()}
                    customizeText={customizeTextCallback}
                  />
                </Series>

                <ArgumentAxis>
                  <Tick visible={false} />
                  <Title text={argumentAxisTitle} />
                  <Label visible={true} alignment={rotated ? 'left' : 'center'}>
                    <Font color={palette.font} weight="700" />
                  </Label>
                </ArgumentAxis>
                <ValueAxis visible={false}>
                  <Grid visible={true} />
                  <Label
                    visible={true}
                    customizeText={customizeTextCallback}
                    alignment="left"
                  >
                    <Font color={palette.font} weight="600" />
                  </Label>
                  <Tick visible={false} />
                  <Title text={valueAxisTitle}>
                    <Font color={palette.secondaryFont} />
                  </Title>
                </ValueAxis>
                <Legend visible={false} />
                <Export enabled={false} />
                <Tooltip
                  enabled={displayToolTip}
                  location="edge"
                  customizeTooltip={toolTipCallback}
                />
                <LoadingIndicator enabled={false} />
              </Chart>
            </>
          ) : (
            <div className={styles.notFound}>
              <p>No data found.</p>
            </div>
          )
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner active={true} background={'clear'} />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default DrillDownChart;
