import * as Sentry from '@sentry/react';
import { AuthContext } from 'auth';
import { useContext } from 'react';

interface SentryErrorBoundryProps {
  showDialog: boolean;
  children: JSX.Element;
}

const SentryErrorBoundary = (props: SentryErrorBoundryProps): JSX.Element => {
  const { showDialog, children } = props;
  const { currentUser } = useContext(AuthContext);

  return (
    <Sentry.ErrorBoundary
      dialogOptions={{
        user: {
          email: currentUser?.email,
          name: currentUser?.firstName + ' ' + currentUser?.lastName,
        },
      }}
      showDialog={showDialog}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
