import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Spinner } from 'auth';
import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export,
  Font,
  Label,
  Legend,
  Series,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import { renameObjectKey } from 'helpers/object';
import useColorPalette from 'hooks/useColorPalette';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  checkDateToWeekDayList,
  currentMonthDisplay,
  getWeekDateStartPosition,
  weekDates,
} from '../../../helpers/date';
import { removeHoverHashing } from '../../../helpers/hashing';
import { formatToDollar } from '../../../helpers/format';
import { PaletteConfig } from '../../../palette';
import { RootState } from '../../../store';

import styles from '../../../styles/components/charts/range-inventory.module.scss';
import '../../../styles/dev-extreme/select-box.scss';

const argumentTitleText = 'Days of Week';
const msWeek = 7 * 24 * 60 * 60 * 1000;

const WeeklyQuantity = ({
  redrawKey,
}: DashboardComponentProps): JSX.Element => {
  const calendar = useSelector((state: RootState) => state.calendar);
  const { data, hasData, isLoading, setDataQuery } = useData();
  const { themePalette } = useColorPalette();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentWeek, setCurrentWeek] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [dayOfWeek, setDayOfWeek] = useState<number>(0);
  const [displayDateBackBtn, setDisplayDateBackBtn] = useState<boolean>(false);
  const [displayMonth, setDisplayMonth] = useState('');
  const [loading, setLoading] = useState(true);
  const [week, setWeek] = useState<number>(0);
  const [chartSettings, setChartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    argField: 'dayOfWeek',
    month: calendar.props.month,
    query: 'weeklySalesByDayOld',
    week: calendar.props.week,
    valueFields: [
      { field: 'tyRetail' },
      { field: 'lyRetail' },
      { field: 'tyRetailOnHand' },
      { field: 'lyRetailOnHand' },
    ],
  });

  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );
  // const currentDashboard = useSelector(selectCurrentDashboard);
  // const layoutKey = currentDashboard?.layoutHash || '';

  /**
   * Handles forward button for moving date to next month.
   */
  const dateForward: MouseEventHandler<HTMLElement> = useCallback(() => {
    if (chartSettings.week === null) return;

    setLoading(true);
    setAnimate(true);

    let nextWeek: number = chartSettings.week + 1;
    nextWeek = nextWeek <= 52 ? nextWeek : 1;
    setChartSettings({
      ...chartSettings,
      week: nextWeek,
    });

    let nextDate = new Date(date.getTime() + msWeek);
    if (nextWeek === 1) {
      nextDate = getWeekDateStartPosition(chartData, dayOfWeek, 'end');
    }
    setDate(nextDate);
  }, [chartData, chartSettings, dayOfWeek]);

  /**
   * Handles back button for moving date to previous sales.
   */
  const dateBack: MouseEventHandler<HTMLElement> = useCallback(() => {
    setLoading(true);
    setAnimate(true);

    let prevWeek: number = week - 1;
    prevWeek = prevWeek !== 0 ? prevWeek : 52;
    setChartSettings({
      ...chartSettings,
      week: prevWeek,
    });

    let prevDate = new Date(date.getTime() - msWeek);
    if (prevWeek === 52) {
      prevDate = getWeekDateStartPosition(chartData, dayOfWeek, 'start');
    }
    setDate(prevDate);
  }, [chartData, chartSettings, dayOfWeek]);

  /**
   * Handles returning back to todays week.
   */
  const displayTodaysWeek: MouseEventHandler<HTMLElement> = useCallback(() => {
    setAnimate(true);
    setLoading(true);
    setDate(currentDate);
    setChartSettings({
      ...chartSettings,
      week: currentWeek,
    });
  }, [currentDate, currentWeek]);

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendar.loaded && retailerLoaded) {
      setDataQuery(chartSettings);
    }
  }, [calendar.loaded, retailerLoaded, chartSettings]);

  /**
   * Set data here.
   */
  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    const weekDateList = weekDates(date, dayOfWeek - 1);
    const isCurrentWeek: boolean = checkDateToWeekDayList(weekDateList);
    const month: string = currentMonthDisplay(weekDateList);
    setDisplayMonth(month);
    setDisplayDateBackBtn(!isCurrentWeek);

    let updatedData: any[] = [];
    data.forEach((dataItem: any, index: number) => {
      const object = renameObjectKey(chartSettings.argField, 'arg', dataItem);
      object.arg = weekDateList[index];
      updatedData = [...updatedData, object];
    });

    setChartData(updatedData);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId]);

  useEffect(() => {
    if (calendar.loaded) {
      const _currentDate = new Date(calendar.props.saleDate);
      setCurrentDate(_currentDate);
      setDate(_currentDate);
      setDayOfWeek(calendar.props.dayOfWeek);
      setWeek(calendar.props.week);
      setCurrentWeek(calendar.props.week);
    }
  }, [calendar]);

  const customizeText = (amount: any): string => {
    return formatToDollar(amount.value);
  };

  const customizeToolTip = (arg: any): CustomToolTipType => {
    return {
      text: `<div>${formatToDollar(arg.valueText)}</div>`,
    };
  };

  const colorPalette = useMemo(() => {
    return themePalette.barChart as PaletteConfig;
  }, [themePalette]);

  return (
    <div className={styles.rangeInventory}>
      <div className={styles.heading}>
        <h2>
          Weekly Quantity<span>{displayMonth}</span>
        </h2>

        <div className={styles.controls}>
          {displayDateBackBtn ? (
            <div className={styles.today}>
              <button onClick={displayTodaysWeek}>
                Return to Current Week
              </button>
            </div>
          ) : null}

          <div className={styles.arrows}>
            <button
              onClick={dateBack}
              disabled={isLoading}
              className={isLoading ? styles.disabled : ''}
            >
              <ArrowBackIos />
            </button>
            <button
              onClick={dateForward}
              disabled={isLoading}
              className={isLoading ? styles.disabled : ''}
            >
              <ArrowForwardIos />
            </button>
          </div>
        </div>
      </div>

      {!loading ? (
        hasData ? (
          <Chart
            id="weekly-quantity-chart"
            key={redrawKey}
            dataSource={chartData}
            customizePoint={removeHoverHashing}
            rotated={false}
            animation={animate}
            palette={colorPalette.chart}
          >
            <CommonAxisSettings allowDecimals={false} />
            <CommonSeriesSettings
              argumentField="arg"
              type="bar"
              hoverMode="allArgumentPoints"
              selectionMode="allArgumentPoints"
            />
            <Series
              type="bar"
              argumentField="arg"
              axis="onHand"
              valueField="tyRetailOnHand"
              name="OnHand TY"
            />
            <Series
              type="bar"
              argumentField="arg"
              axis="onHand"
              valueField="lyRetailOnHand"
              name="OnHand LY"
            />

            <Series
              type="line"
              argumentField="arg"
              axis="sales"
              valueField="lyRetail"
              name="Sales LY"
              width={4}
            />
            <Series
              type="line"
              argumentField="arg"
              axis="sales"
              valueField="tyRetail"
              name="Sales TY"
              width={4}
            />

            <ArgumentAxis>
              <Tick visible={false} />
              <Title text={argumentTitleText}>
                <Font color={colorPalette.secondaryFont} size={12} />
              </Title>
              <Label>
                <Font color={colorPalette.font} weight={700} />
              </Label>
            </ArgumentAxis>

            <ValueAxis
              name="sales"
              position="right"
              valueMarginsEnabled={false}
            >
              <Title text="Sales (Line Chart)">
                <Font color={colorPalette.secondaryFont} />
              </Title>
              <Label customizeText={customizeText}>
                <Font color={colorPalette.font} weight={700} />
              </Label>
              <Tick visible={false} />
            </ValueAxis>

            <ValueAxis name="onHand" showZero={true} position="left">
              <Title text="On Hand Dollars (Bar Chart)">
                <Font color={colorPalette.secondaryFont} />
              </Title>
              <Label customizeText={customizeText}>
                <Font color={colorPalette.font} weight={700} />
              </Label>
              <Tick visible={false} />
            </ValueAxis>

            <Legend visible={false} />
            <Export enabled={false} />
            <Tooltip
              enabled={true}
              location="edge"
              customizeTooltip={customizeToolTip}
            />
          </Chart>
        ) : (
          <div className={styles.notFound}>
            <p>No data found.</p>
          </div>
        )
      ) : (
        <Spinner active={true} background={'clear'} />
      )}
    </div>
  );
};

export default WeeklyQuantity;
