import { Paper } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import MonthlyChangeInInventory from './MonthlyChangeInInventory';
import WeeklyChangeInInventory from './WeeklyChangeInInventory';
import YearlyChangeInInventory from './YearlyChangeInInventory';

import styles from '../../../styles/components/charts/range-inventory.module.scss';
import '../../../styles/material-ui/paper.scss';

const RangeInventory = ({
  redrawKey,
}: DashboardComponentProps): JSX.Element => {
  const { dateRange } = useSelector((state: RootState) => state.query);
  const [classList] = useState<string[]>([styles.box, 'paper-box']);

  const Component = useMemo(() => {
    switch (dateRange) {
      case 'Week-To-Date':
        return WeeklyChangeInInventory;
      case 'Year-To-Date':
        return YearlyChangeInInventory;
      default:
        return MonthlyChangeInInventory;
    }
  }, [dateRange]);

  return (
    <div className={styles.rangeInventory}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <Component redrawKey={redrawKey} />
      </Paper>
    </div>
  );
};

export default RangeInventory;
