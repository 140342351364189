import { CalendarState } from 'store/slices/calendarSlice';
import { dedupeArray } from 'helpers/array';
import { lowercaseFirstLetter } from 'helpers/string';
import { getCorrectDate, getPastDate } from 'helpers/date';
import { subtractValues } from 'helpers/numbers';

export default class QueryGenerator {
  calendar: CalendarState;
  config: DashboardItemConfig;
  availableConditions: any = {
    equal: 'eq',
    notEqual: 'neq',
    greaterThan: 'gt',
    greaterThanOrEqual: 'gte',
    lessThan: 'lt',
    lessThanOrEqual: 'lte',
  };

  displayOptions: any = {
    top: 'first',
    bottom: 'last',
  };

  constructor(config: DashboardItemConfig, calendar: CalendarState) {
    this.calendar = calendar;
    this.config = config;
  }

  fields(): string {
    if (this.config.selectedFields.length === 0) return '';

    const fields = this.config.selectedFields;
    let fieldNames = fields.map((obj) => obj.field);
    fieldNames =
      this.config.selectedArgField !== ''
        ? [...fieldNames, this.config.selectedArgField]
        : [...fieldNames];

    return dedupeArray(fieldNames).join(' ');
  }

  comparison(): string {
    const fuzzyDateOffset = this.config.comparisonFuzzyDate;

    if (
      (!this.config.hasComparison && typeof fuzzyDateOffset === 'undefined') ||
      fuzzyDateOffset === '0'
    )
      return '';

    const begin = `comparison(offset: { ${fuzzyDateOffset}: -1 }) {`;
    const end = '}';

    let comparisonFields: string[] = [];
    if (this.config.selectedFields.length > 0) {
      const fields = this.config.selectedFields;
      for (const item of fields) {
        if (typeof item.comparison === 'undefined' || !item.comparison)
          continue;
        comparisonFields = [...comparisonFields, item.field];
      }
    }

    return comparisonFields.length > 0
      ? `${begin} ${comparisonFields.join(' ')} ${end}`
      : '';
  }

  displayLimit(): string {
    const displayLimit = this.config.displayLimit;
    if (typeof displayLimit === 'undefined') return '';
    const displayBits = displayLimit.split('|');
    let direction = displayBits[0];
    direction = this.displayOptions[direction];
    const amount = displayBits[1];

    return `${direction}: ${amount}`;
  }

  limit(): string {
    let result = '';
    const limit = this.config.limit;
    if (typeof limit === 'undefined') return '';
    const limitBits = limit.split('|');

    switch (limitBits[0]) {
      case 'toDate':
        if (limitBits[1] !== '') {
          result = `toDate: { ${limitBits[1]}: true }`;
        }
        break;
      case 'trailingDays':
        if (limitBits[1] !== '') {
          const offsetDay = getPastDate(limitBits[1]);
          result = `saleDate: { gte: "${offsetDay}" } and: { saleDate: { lte: "${getCorrectDate(
            new Date()
          )}" } }`;
        }
        break;
      case 'trailingMonths':
        if (limitBits[1] !== '') {
          const offsetMonth = subtractValues(
            this.calendar.props.month,
            limitBits[1]
          );

          result = `month: { gte: ${offsetMonth} } and: { month: { lte: ${this.calendar.props.month} } }
          `;
        }
        break;
    }
    return result;
  }

  queryName(): string {
    return this.config.selectedQuery;
  }

  order(): string {
    const order = this.config.order;
    if (order === '') return '';
    const orderBits = order.split('|');
    const field = orderBits[0];
    const direction = orderBits[1];

    if (field === '0') return '';
    return `order: { ${field}: ${direction} }`;
  }

  where(): string {
    const where = this.config.whereFilter;
    const limit = this.limit();
    const whereCount = where.count;
    if (whereCount === 0 && limit === '') return '';

    let args = '';
    for (const i in where.items) {
      const item = where.items[i];
      const field = item.field;
      const previousItem = where.items[Number(i) - 1];
      const previousItemIsCondition =
        typeof previousItem !== 'undefined'
          ? previousItem.field === 'and' || previousItem.field === 'or'
          : false;

      const condition = this.availableConditions[item.condition];
      const isNumber = !isNaN(Number(item.value));
      const value = isNumber ? item.value : `"${item.value}"`;
      switch (field.toLowerCase()) {
        case 'and':
          args = `${args} ${field}: {`;
          break;
        case 'or':
          args = `${args} ${field}: {`;
          break;
        default:
          args = !previousItemIsCondition
            ? `${args} ${field}: { ${condition}: ${value} }`
            : `${args} ${field}: { ${condition}: ${value} } }`;
          break;
      }
    }

    // Add limit date to end of where args.
    args =
      limit !== '' && !limit.includes('toDate') ? `${args} ${limit}` : args;

    if (args === '') return '';
    return `where: {${args} }`;
  }

  result(): string {
    let query = '';

    const queryName = this.queryName();
    if (queryName === '') {
      console.log('QueryGenerator: No query selected');
      return '';
    }

    const fields = this.fields();
    if (fields === '') {
      console.log('QueryGenerator: No fields selected');
      return '';
    }

    const where = this.where();
    const order = this.order();
    const displayLimit = this.displayLimit();
    const limit = this.limit();
    const toDate = limit.includes('toDate') ? limit : '';

    query = `query ${queryName} {
        ${lowercaseFirstLetter(queryName)}(
          ${toDate}
          ${where}
          ${order}
          ${displayLimit}
        ) {
          edges {
            node {
              ${fields}
              ${this.comparison()}
            }
          }
        }
      }`;

    // console.log('Generated Graphql Query: ', query);

    return query;
  }
}
