import { useQuery } from '@apollo/client';
import GraphQLData from 'data/graph/data';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  setCalendarData,
  setCalendarDataLoaded,
} from 'store/slices/calendarSlice';
import {
  setRetailerDataLoaded,
  setRetailerList,
} from 'store/slices/retailerSlice';

interface UseInitialDataAPI {
  retailers: RetailerType[];
  calendar: ICalendar;
  loaded: boolean;
}

function useInitialData(): UseInitialDataAPI {
  const dispatch = useDispatch();
  const { retailerList } = useSelector((state: RootState) => state.retailer);
  const { props: calendarData } = useSelector(
    (state: RootState) => state.calendar
  );

  const [loaded, setLoaded] = useState(false);

  const graphQuery = GraphQLData.dataControls() as DocumentNode;
  useQuery(graphQuery, {
    onCompleted,
    onError: (error: any) => {
      console.log(error);
      throw new Error(
        `Error fetching initial retailer and calendar data: ${JSON.stringify(
          error
        )}`
      );
    },
  });

  async function onCompleted(data: any): Promise<void> {
    if (data === undefined) {
      throw new Error(
        'No data returned when fetching calendars and retailers.'
      );
    }

    const retailers: RetailerType[] = data.retailers.edges.map((edge: any) => {
      return {
        id: edge.node.id,
        name: edge.node.name,
      };
    });

    const calendarSource = data.calendar.edges[0].node;
    // Set Calendar Data.
    const _calendarData: ICalendar = {
      year: calendarSource.year,
      dayOfYear: calendarSource.dayOfYear,
      month: calendarSource.month,
      week: calendarSource.week,
      dayOfWeek: calendarSource.dayOfWeek,
      saleDate: calendarSource.saleDate,
      isRestated: calendarSource.isRestated,
    };

    dispatch(setRetailerList(retailers));
    dispatch(setRetailerDataLoaded(true));
    dispatch(setCalendarData(_calendarData));
    dispatch(setCalendarDataLoaded(true));

    setLoaded(true);
  }

  return {
    retailers: retailerList,
    calendar: calendarData,
    loaded,
  };
}

export default useInitialData;
