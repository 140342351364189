import SaveControls from 'components/DashboardLayout/SaveControls';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import silverfernLogo from '../../assets/logos/silverfern-logo.svg';
import { AuthContext, Dropdown } from '../../auth';
import DataControls from '../DataControls';
import ProductTitle from './ProductTitle';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styles from '../../styles/components/subcomponents/navbar.module.scss';

function Navbar(): JSX.Element {
  const { currentUser, redirectToLogout } = useContext(AuthContext);
  const { hideNav } = useSelector((state: RootState) => state.query);
  const navigate = useNavigate();

  let name: string;
  const firstName = currentUser?.firstName;
  if (typeof firstName !== 'undefined' || firstName !== null) {
    name = firstName as string;
  } else {
    name = '';
  }

  const { isEditing, isCreating } = useSelector(
    (state: RootState) => state.dashboard
  );

  function didClickDropdownItem(index: number): void {
    switch (index) {
      case 0:
        logOut();
        break;
      default:
        console.error('Unexpected dropdown index selection: ', index);
    }
  }

  function logOut(): void {
    redirectToLogout();
  }

  return (
    <>
      {!hideNav ? (
        <div className={styles.navbar}>
          <div className={styles.navbarContentWrapper}>
            <div className={styles.logo}>
              <img alt="Silver Fern" src={silverfernLogo} />
            </div>

            <ProductTitle />
            {(isEditing || isCreating) && (
              <SaveControls editType={isEditing ? 'edit' : 'create'} />
            )}
            <DataControls layout="horizontal" />
            <Dropdown
              className={styles.dropdown}
              title={`Welcome ${name}!`}
              itemTitles={['Log Out']}
              didClickItem={didClickDropdownItem}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Navbar;
