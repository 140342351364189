import React, { useRef, useState } from 'react';

import { ArrowDropDownOutlined } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import styles from '../../styles/auth/components/ui/dropdown.module.scss';

interface DropdownProps {
  className?: string;
  title: React.ReactNode;
  name?: string;
  itemTitles: string[];
  didClickItem: (index: number) => void;
}

export default function Dropdown({
  className = '',
  title,
  name,
  itemTitles,
  didClickItem,
}: DropdownProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const popperRef = useRef<any | null>(null);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = (event: any): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function onItemClick(itemTitle: string): void {
    const index = itemTitles.indexOf(itemTitle);
    didClickItem(index);
  }

  function handleListKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={`${styles.dropdown} ${className}`}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          variant="outlined"
          className={styles.menuButton}
          onClick={handleToggle}
        >
          {title}
          <ArrowDropDownOutlined
            className={`${styles.icon} ${open ? styles.rotate : ''}`}
          ></ArrowDropDownOutlined>
        </Button>
        <Popper
          open={open}
          ref={popperRef}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              ref={popperRef}
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper ref={popperRef}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {itemTitles.map((itemTitle, index) => (
                      <MenuItem
                        key={index}
                        className={styles.menuItem}
                        onClick={() => {
                          onItemClick(itemTitle);
                        }}
                      >
                        {itemTitle}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
