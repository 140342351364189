import { Paper } from '@mui/material';
import { BarGauge, Geometry, Label } from 'devextreme-react/bar-gauge';
import useColorPalette from 'hooks/useColorPalette';
import { useMemo, useState } from 'react';
import { PaletteConfig } from '../../palette';

import styles from '../../styles/components/charts/labor.module.scss';

const Labor = ({ redrawKey }: DashboardComponentProps): JSX.Element => {
  const { themePalette } = useColorPalette();
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-t-large',
  ]);

  const colorPalette = useMemo(() => {
    return themePalette.gauge as PaletteConfig;
  }, [themePalette]);

  return (
    <div className={styles.labor}>
      <Paper square elevation={0} className={classList.join(' ')}>
        <div className={styles.heading}>
          <h2>Estimated Labor</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.topDisplay}>
            <div className={styles.amount}>
              <p>
                <span>1,926</span>
                <span>hrs</span>
                <span>/</span>
                <span>wk</span>
              </p>
            </div>
            <div className={styles.indicator}>
              <BarGauge
                id="labor-guage"
                className={styles.guage}
                startValue={0}
                endValue={100}
                values={[75]}
                palette={colorPalette.chart}
                backgroundColor={colorPalette.background}
                redrawOnResize={true}
                relativeInnerRadius={0.6}
              >
                <Label visible={false} />
                <Geometry startAngle={180} endAngle={0} />
              </BarGauge>
            </div>
          </div>
          <div className={styles.bottomDisplay}>
            <div className={styles.total}>
              <p>
                <span>3.67k</span>
                <span>total hrs</span>
              </p>
            </div>
            <span className={styles.divider}></span>
            <div className={styles.remaining}>
              <p>
                <span>622</span>
                <span>remaining hrs</span>
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Labor;
