import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CalendarState {
  props: ICalendar;
  loaded: boolean;
}

const initialState: CalendarState = {
  props: {
    year: 0,
    dayOfYear: 0,
    month: 0,
    week: 0,
    dayOfWeek: 0,
    saleDate: '',
    isRestated: false,
  },
  loaded: false,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendarData: (state, action: PayloadAction<ICalendar>) => {
      state.props = action.payload;
    },
    setCalendarDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
  },
});

export const { setCalendarData, setCalendarDataLoaded } = calendarSlice.actions;
export default calendarSlice.reducer;
