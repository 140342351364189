import { DocumentNode, gql } from '@apollo/client';
import legacyData, { LegacyCallableGraphQLDataTypes } from './legacy-data';

export interface GraphQLDataTypes extends CallableGraphQLDataTypes {
  currentYear: Maybe<number>;
  date: Maybe<Date>;
  hasComparison: boolean;
  introspectiveQueryName: string;
  month: Maybe<number>;
  regionID: Maybe<string>;
  retailerID: Maybe<string>;
  week: Maybe<number>;
  weekCount: Maybe<number>;
}

export interface CallableGraphQLDataTypes
  extends LegacyCallableGraphQLDataTypes {
  dataControls(): DocumentNode;
  generatedQuery(query: string): DocumentNode;
  loadQueries(): DocumentNode;
  loadQueryFields(): DocumentNode;
}

const GraphQLData: GraphQLDataTypes = {
  currentYear: null,
  date: new Date(),
  hasComparison: false,
  introspectiveQueryName: '',
  month: null,
  regionID: null,
  retailerID: null,
  week: null,
  weekCount: null,
  dataControls: (): DocumentNode => {
    const setDate = GraphQLData.date;
    const _date = setDate !== null ? new Date(setDate) : new Date();
    const dd = _date.getDate();
    const mm = _date.getMonth() + 1;
    const yyyy = _date.getFullYear();
    const saleDate = `${yyyy}-${mm}-${dd}`;

    return gql`
      query {
        retailers {
          edges {
            node {
              id
              name
            }
          }
        }
        calendar(where: {saleDate: {eq: "${saleDate}"}}) {
          edges {
            node {
              year
              dayOfYear
              month
              dayOfMonth
              weekOfYear
              weekOfMonth
              dayOfWeek
              saleDate
              isRestated
            }
          }
        }
      }`;
  },
  generatedQuery: (query: string): DocumentNode => {
    return gql`
      ${query}
    `;
  },
  loadQueries: (): DocumentNode => {
    return gql`
      query loadQueries {
        __type(name: "Query") {
          name
          __typename
          fields {
            name
            args {
              name
              type {
                inputFields {
                  name
                }
              }
            }
          }
        }
      }
    `;
  },
  loadQueryFields: (): DocumentNode => {
    const name = GraphQLData.introspectiveQueryName;
    return gql`
      query {
        __type(name: "${name}") {
          fields {
            name
            type {
              name
            }
            args {
              name
              type {
                ofType {
                  inputFields {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `;
  },
  ...legacyData,
};

export const hasDataQuery = (selectedFunction: string): boolean => {
  const functionList = Object.keys(GraphQLData);
  return functionList.indexOf(selectedFunction) !== -1;
};

export default GraphQLData;
