import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { searchObject } from 'helpers/object';
import { sortObjectArrayByPropertyName } from 'helpers/array';
import { SelectChangeEvent } from '@mui/material';
import { Spinner } from 'auth';
import ArgumentSelect from 'components/Fields/ArgumentSelect';
import AvailableFields from 'components/Fields/AvailableFields';
import ComparisonFuzzyDates from 'components/Fields/ComparisonFuzzyDates';
import DisplayFilter from 'components/Fields/DisplayFilter';
import LimitFilter from 'components/Fields/LimitFilter';
import OrderFilter from 'components/Fields/OrderFilter';
import QueryList from 'components/Fields/QueryList';
import SelectedFields, { Field } from 'components/Fields/SelectedFields';
import SelectedValueFieldEditor from 'components/Modals/SelectedFieldEditor';
import SFMiniModal from 'components/Modals/SFMiniModal';
import useChartConfig from 'hooks/useChartConfig';
import WhereFilter from 'components/Fields/WhereFilter';

import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';

import styles from '../../../styles/components/modals/dashboard-configuration.module.scss';

const argumentFieldWhiteList = [
  'dayOfMonth',
  'dayOfWeek',
  'marketDescription',
  'month',
  'regionDescription',
  'week',
];

interface DashboardConfigEditorProps {
  enabledArgFields: boolean;
  itemConfiguration: DashboardItemConfig;
  didUpdateConfiguration: (updatedConfig: DashboardItemConfig) => void;
}

const DashboardConfigEditor = ({
  enabledArgFields = false,
  itemConfiguration,
  didUpdateConfiguration,
}: DashboardConfigEditorProps): JSX.Element => {
  const { data, setDataQuery } = useData();
  const { getQueryList: graphqlQueryList } = useChartConfig();

  const argSelectFields =
    typeof itemConfiguration.argFields !== 'undefined'
      ? itemConfiguration.argFields
      : [];
  const selectedFields = itemConfiguration.selectedFields;

  const [disabled, setDisabled] = useState<boolean>(true);
  // Store the field that is being edited so we can pass it to the MiniModal.
  const [fieldForEdit, setFieldForEdit] = useState<Maybe<Field>>(null);
  const [miniModalOpen, setMiniModalOpen] = useState(false);
  const [introspectiveQueryName, setIntrospectiveQueryName] = useState<string>(
    itemConfiguration.introspectiveQueryName || '0'
  );
  const [filters, setFilters] = useState<Maybe<any[]>>(null);

  const chartFieldQueryConfig = useMemo((): GraphQLQueryConfig | null => {
    if (introspectiveQueryName === '0') return null;

    return {
      ...defaultGraphQLQueryConfig,
      introspectiveQueryName,
      query: 'loadQueryFields',
    };
  }, [introspectiveQueryName]);

  /**
   * Query data here.
   */
  useEffect(() => {
    if (chartFieldQueryConfig !== null) {
      // Save the introspectiveQueryName to the itemConfiguration. So
      // the component will load fields on reload from loadQueryFields.
      didUpdateConfiguration({
        ...itemConfiguration,
        introspectiveQueryName,
      });

      setDataQuery(chartFieldQueryConfig);
    }
  }, [chartFieldQueryConfig]);

  /**
   * Set available fields when data is loaded.
   */
  useEffect(() => {
    if (data.length === 0) return;

    // Filter Out Selected Fields from fieldList.
    let fieldList: any[] = [];
    for (const item of data) {
      if (searchObject('field', item.field, selectedFields).length > 0)
        continue;
      fieldList = [...fieldList, item];
    }
    const comparison = searchObject('name', 'comparison', fieldList);

    // Set Arg fields.
    let argFieldList: any[] = [];
    for (const validField of argumentFieldWhiteList) {
      if (searchObject('name', validField, fieldList).length > 0) {
        argFieldList = [...argFieldList, validField];
      }
    }

    didUpdateConfiguration({
      ...itemConfiguration,
      argFields: argFieldList,
      fields: sortObjectArrayByPropertyName(fieldList, 'name'),
      comparisonOptions:
        comparison.length > 0 ? comparison[0].inputFields[0].options : [],
      hasComparison: comparison.length > 0,
    });
  }, [data]);

  /**
   * Set filters when selectedQuery changes.
   */
  useEffect(() => {
    const selectedQuery = itemConfiguration.selectedQuery;
    const filterList = graphqlQueryList.args;

    if (selectedQuery === '0' || Object.keys(filterList).length === 0) {
      setFilters(null);
      return;
    }

    setFilters(graphqlQueryList.args[selectedQuery]);
  }, [itemConfiguration.selectedQuery, graphqlQueryList]);

  useEffect(() => {
    setDisabled(selectedFields.length === 0);
    if (selectedFields.length === 0) {
      didUpdateConfiguration({
        ...itemConfiguration,
        displayLimit: 'top|10',
        comparisonFuzzyDate: '0',
        limit: '0|',
        order: '',
        selectedArgField: '',
        whereFilter: {
          count: 0,
          items: [],
        },
      });
    }
  }, [selectedFields]);

  /**
   * Checks itemConfiguration.selectedFields to see
   * if any of the fields have a comparison.
   */
  const hasComparisonUsage = (): boolean => {
    if (selectedFields.length === 0) return false;
    return selectedFields.some((field) => field.comparison === true);
  };

  /**
   * Set `Field` for editing in the minimodal and open it
   */
  function onSelectedValueFieldClick(field: Field): void {
    setFieldForEdit(field);
    setMiniModalOpen(true);
  }

  /** Close minimodal and clear `field` that was being edited */
  function onMiniModalClose(): void {
    setMiniModalOpen(false);
    setFieldForEdit(null);
  }

  function onValueFieldEdit(updatedField: Field): void {
    setFieldForEdit(updatedField);
  }

  function onValueFieldEditSave(): void {
    // If there is no field being edited, do nothing
    if (!fieldForEdit) {
      return;
    }

    const updatedFields = [...itemConfiguration.selectedFields];

    // Find the index of the field that is being edited
    const fieldIndex = updatedFields.findIndex(
      (field) => field.field === fieldForEdit.field
    );

    // Replace the field with the updated field
    updatedFields[fieldIndex] = fieldForEdit;

    // Finally persist updated field to dashboard configuration
    didUpdateConfiguration({
      ...itemConfiguration,
      selectedFields: updatedFields,
    });

    setMiniModalOpen(false);
    setFieldForEdit(null);
  }

  /**
   * Move the value in the list from startIndex to endIndex.
   */
  function reorder(list: any[], startIndex: number, endIndex: number): any[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result: DropResult): void {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedValueFields = reorder(
      selectedFields,
      result.source.index,
      result.destination.index
    );

    didUpdateConfiguration({
      ...itemConfiguration,
      selectedFields: updatedValueFields,
    });
  }

  function handleArgChange(e: any): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      selectedArgField: e.target.value,
    });
  }

  function onAvailableFieldsClick(e: any): void {
    const { field, fieldName } = e.target.dataset;

    // Adds new field to valuesFields array.
    if (typeof field === 'undefined' && typeof fieldName === 'undefined') {
      return;
    }

    const fields = [
      ...itemConfiguration.selectedFields,
      {
        field,
        name: fieldName,
      },
    ];

    const updatedFields = itemConfiguration.fields.filter(
      (availableField: any) => availableField.name !== field
    );

    didUpdateConfiguration({
      ...itemConfiguration,
      fields: updatedFields,
      selectedFields: fields,
    });
  }

  const handleConfigUpdateForKey: (
    key: keyof DashboardItemConfig
  ) => ChangeEventHandler<HTMLInputElement> = (key) => (e) => {
    didUpdateConfiguration({
      ...itemConfiguration,
      [key]: e.target.value,
    });
  };

  function onQueryListChange(event: SelectChangeEvent<string>): void {
    const queryName = event.target.value as string;

    const currentQueryName = itemConfiguration.selectedQuery;
    if (currentQueryName === queryName || typeof queryName === 'undefined')
      return;

    // Sets query and clears state.
    didUpdateConfiguration({
      ...itemConfiguration,
      displayLimit: 'top|10',
      comparisonFuzzyDate: '0',
      fields: [],
      limit: '0|',
      order: '',
      selectedArgField: '',
      selectedFields: [],
      selectedQuery: queryName,
      whereFilter: {
        count: 0,
        items: [],
      },
    });

    setIntrospectiveQueryName(queryName);
  }

  function handleRemoveQueryField(e: any): void {
    const item = e.target;
    const dataSet = item.closest('div').dataset;
    const field: any = dataSet.field;
    const source: any = dataSet.source;

    let fieldList = [
      ...itemConfiguration.fields,
      {
        name: field,
        inputFields: [],
      },
    ];
    fieldList = sortObjectArrayByPropertyName(fieldList, 'name');

    const _selectedArgField = itemConfiguration.selectedArgField;
    let _selectedFields = itemConfiguration.selectedFields;

    if (source === 'value') {
      _selectedFields = _selectedFields.filter((valueField: any) => {
        return valueField.field !== field;
      });
    }

    didUpdateConfiguration({
      ...itemConfiguration,
      fields: fieldList,
      selectedArgField: _selectedArgField,
      selectedFields: _selectedFields,
    });
  }

  function onComparisonFuzzyDateChange(event: SelectChangeEvent<string>): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      comparisonFuzzyDate: event.target.value as FuzzyDate,
    });
  }

  function handleFilterUpdate(value: WhereFilter): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      whereFilter: value,
    });
  }

  function onOrderChange(value: string): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      order: value,
    });
  }

  function onDisplayFilterChange(value: DisplayOptions): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      displayLimit: value,
    });
  }

  function onLimitChange(value: any): void {
    didUpdateConfiguration({
      ...itemConfiguration,
      limit: value,
    });
  }

  return (
    <div className={styles.dashboardConfiguration}>
      <div className={styles.heading}>
        <div className={styles.title}>
          <label htmlFor="chartTitle">Title:</label>
          <input
            type="text"
            id="chartTitle"
            name="chartTitle"
            value={itemConfiguration.chartTitle}
            onChange={handleConfigUpdateForKey('chartTitle')}
          />
        </div>
      </div>

      {graphqlQueryList.queries.length > 0 ? (
        <div className={styles.content}>
          <div className={styles.section}>
            <QueryList
              onChange={onQueryListChange}
              value={itemConfiguration.selectedQuery || '0'}
            />

            <AvailableFields
              onClick={onAvailableFieldsClick}
              fields={itemConfiguration.fields}
            />
          </div>

          <div className={styles.section}>
            <SelectedFields
              fields={itemConfiguration.selectedFields}
              onDragEnd={onDragEnd}
              onClickRemove={handleRemoveQueryField}
              onSelectedFieldClick={onSelectedValueFieldClick}
            />

            {itemConfiguration.hasComparison && (
              <ComparisonFuzzyDates
                disabled={!hasComparisonUsage || disabled}
                onChange={onComparisonFuzzyDateChange}
                options={itemConfiguration.comparisonOptions}
                value={itemConfiguration.comparisonFuzzyDate}
              />
            )}

            {argSelectFields.length && enabledArgFields ? (
              <ArgumentSelect
                disabled={disabled}
                onChange={handleArgChange}
                fields={argSelectFields}
                value={itemConfiguration.selectedArgField}
              />
            ) : null}
          </div>

          <div className={styles.section}>
            <WhereFilter
              disabled={disabled}
              filters={filters}
              filterConfig={itemConfiguration.whereFilter}
              onFilterUpdate={handleFilterUpdate}
            />
          </div>

          <div className={styles.section}>
            <LimitFilter
              disabled={disabled}
              fields={data}
              filters={filters}
              onChange={onLimitChange}
              value={itemConfiguration.limit}
            />

            <OrderFilter
              disabled={disabled}
              selectedFields={itemConfiguration.selectedFields}
              onChange={onOrderChange}
              value={itemConfiguration.order}
            />

            <DisplayFilter
              disabled={disabled}
              onChange={onDisplayFilterChange}
              value={itemConfiguration.displayLimit}
            />
          </div>
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner active={true} background={'clear'} />
        </div>
      )}

      <SFMiniModal open={miniModalOpen} onClose={onMiniModalClose}>
        {/* Doing this null check so we can pass the field without a Maybe<> wrapper */}
        {fieldForEdit !== null && (
          <SelectedValueFieldEditor
            field={fieldForEdit!}
            onFieldEdit={onValueFieldEdit}
            onSubmit={onValueFieldEditSave}
          />
        )}
      </SFMiniModal>
    </div>
  );
};

export default DashboardConfigEditor;
