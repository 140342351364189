import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ParamState {
  dashboard: string;
  hide: boolean;
  range: RangeType;
  retailer: string;
}

const initialState: ParamState = {
  dashboard: '',
  hide: false,
  range: 'Month-To-Date',
  retailer: '0',
};

export const paramSlice = createSlice({
  name: 'param',
  initialState,
  reducers: {
    setParameterState: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { key, value } = payload;
      const _key = key as ValidParamType;

      switch (_key) {
        case 'dashboard':
          state.dashboard = value;
          break;
        case 'hide':
          state.hide = value === true || value === 'true';
          break;
        case 'retailer':
          state.retailer = value;
          break;
        case 'range':
          state.range = value;
          break;
      }
    },
  },
});

export const { setParameterState } = paramSlice.actions;
export default paramSlice.reducer;
