import Layout from 'components/DashboardLayout/Layout';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeState } from 'store/slices/themeSlice';
import Modal from '../components/Modals/Modal';
import { RootState } from '../store';
import styles from '../styles/pages/page-wrapper.module.scss';

interface PageWrapperProps {
  product: SFDashboardProduct;
}

const PageWrapper = ({ product }: PageWrapperProps): JSX.Element => {
  const dispatch = useDispatch();
  const currentThemeState = useSelector((state: RootState) => state.theme);
  const { hideNav } = useSelector((state: RootState) => state.query);

  /**
   * Dispatch title from PageWrapper theme prop.
   * We do this if the user enters the app from a different
   * route other than the default router, theme and title
   * display correctly.
   */
  useLayoutEffect(() => {
    dispatch(
      setThemeState({
        ...currentThemeState,
        title: product.name,
        selectedTheme: product.code,
      })
    );
  }, [product, currentThemeState]);

  return (
    <section
      className={`${styles.pageWrapper} ${
        hideNav ? styles.smallTopMargin : styles.largeTopMargin
      }`}
    >
      <div className={styles.content}>
        <Layout navHidden={hideNav} />
      </div>
      <Modal />
    </section>
  );
};

export default PageWrapper;
