import { AuthContext } from 'auth';
import Config from 'config';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setAccessibleDashboardProducts } from 'store/slices/productSlice';
import useProductIDs from './useProductIDs';

interface UseAccessibleProductsAPI {
  products: Maybe<SFDashboardProduct[]>;
  loaded: boolean;
}

/** Returns a list of products (ids and names) that are both supported
 * by ANALYZE, and to which the user has access.
 */
function useAccessibleProducts(): UseAccessibleProductsAPI {
  const supportedProducts = Config.supportedWorksuiteProducts;

  /** List of products to which the current user has access */
  const { productAccess: userProductAccess } = useContext(AuthContext);

  const { productsWithIDs, loaded: loadedProductsWithIDs } = useProductIDs();

  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();
  const accessibleProducts = useSelector(
    (state: RootState) => state.product.accessibleDashboardProducts
  );

  // Once products with ids have been loaded from network, filter them
  // for products that are supported, and to which the user has access.
  // Then store those in redux for availability throughout the app.
  useEffect(() => {
    if (
      loadedProductsWithIDs &&
      productsWithIDs !== null &&
      userProductAccess !== null
    ) {
      const _accessibleProducts = productsWithIDs.filter(
        (product) =>
          supportedProducts.includes(product.code) &&
          userProductAccess.includes(product.code)
      );

      dispatch(setAccessibleDashboardProducts(_accessibleProducts));
      setLoaded(true);
    }
  }, [productsWithIDs, loadedProductsWithIDs]);

  return {
    products: accessibleProducts,
    loaded,
  };
}

export default useAccessibleProducts;
