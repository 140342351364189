import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import styles from '../../styles/components/subcomponents/product-title.module.scss';

export default function ProductTitle(): JSX.Element {
  const themeTitle = useSelector((state: RootState) => state.theme.title);

  return (
    <div className={styles.productTitle}>
      <p>
        {themeTitle}
        <sup>&#8482;</sup>
      </p>
    </div>
  );
}
