import { Paper } from '@mui/material';
import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Grid,
  Label,
  Legend,
  Series,
  Size,
  Tick,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import { renameObjectKey } from 'helpers/object';
import useData, {
  defaultGraphQLQueryConfig,
  GraphQLQueryConfig,
} from 'hooks/useData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '../../auth';
import { RootState } from '../../store';

import styles from '../../styles/components/charts/weekly-comp.module.scss';
import '../../styles/dev-extreme/points.scss';
import '../../styles/material-ui/paper.scss';

const displayColor = {
  positive: '#98c23e',
  negative: '#a53b1b',
};

const WeeklyComp = ({ redrawKey }: DashboardComponentProps): JSX.Element => {
  const calendar = useSelector((state: RootState) => state.calendar);
  const { data, hasData, isLoading, setDataQuery } = useData();
  const [animate, setAnimate] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any[]>([]);
  const [currentComp, setCurrentComp] = useState<string>('0%');
  const [lineColor, setLineColor] = useState<any>(displayColor.positive);
  const [loading, setLoading] = useState(true);
  const [classList] = useState<string[]>([
    styles.box,
    'paper-box',
    'paper-box-bg',
    'paper-box-l',
  ]);

  const retailerLoaded = useSelector(
    (state: RootState) => state.retailer.loaded
  );
  const currentRetailerId = useSelector(
    (state: RootState) => state.retailer.currentRetailerId
  );
  // const currentDashboard = useSelector(selectCurrentDashboard);
  // const layoutKey = currentDashboard?.layoutHash || '';

  const [chartSettings] = useState<GraphQLQueryConfig>({
    ...defaultGraphQLQueryConfig,
    argField: 'weekIndex',
    query: 'trailingWeeklySalesOld',
    valueFields: [
      { field: 'retailCompPct' },
      { field: 'lyRetail' },
      { field: 'tyRetail' },
    ],
    weekCount: 6,
  });

  /**
   * Calculate Comp Weighted Average.
   */
  const compWeightedAverage = (dataArray: any[]): number => {
    let tySum = 0;
    let lySum = 0;
    dataArray.forEach((item: any) => {
      tySum = tySum + item.tyRetail;
      lySum = lySum + item.lyRetail;
    });
    return ((tySum - lySum) / lySum) * 100;
  };

  /**
   * Query data here.
   */
  useEffect(() => {
    if (calendar.loaded && retailerLoaded) {
      setDataQuery(chartSettings);
    }
  }, [calendar.loaded, retailerLoaded, chartSettings]);

  useEffect(() => {
    if (!data.length) {
      setChartData([]);
      return;
    }

    const updatedData = data.reduce((accumulator: any, item: any) => {
      if (
        item.retailCompPct === 0 &&
        item.lyRetail === 0 &&
        item.tyRetail === 0
      ) {
        return accumulator;
      }

      const percentage: number = Math.round(item.retailCompPct * 100) / 100;
      const object = renameObjectKey(chartSettings.argField, 'arg', item);
      object.comp = percentage;
      delete object.retailCompPct;

      return [...accumulator, object];
    }, []);

    /**
     * Percentage is where we are getting the 0 from.
     * Need to talk to Stephen regarding how we need to handle the zeros found in data.
     */
    const percentage = updatedData[updatedData.length - 1].comp;
    const average = compWeightedAverage(updatedData);
    const _lineColor =
      Math.sign(average) === -1 ? displayColor.negative : displayColor.positive;
    const _currentComp = `${percentage} %`;
    setCurrentComp(_currentComp);
    setLineColor(_lineColor);
    setChartData(updatedData);
    setLoading(isLoading);

    // Turn off animation after first data layout animation
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    // If data changes, turn animation back on and clear timeout
    // so next animation isn't interrupted by previous animation's timeout.
    return () => {
      setAnimate(true);
      clearTimeout(timeout);
    };
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setAnimate(true);
  }, [currentRetailerId]);

  const customizeToolTip = (arg: any): CustomToolTipType => {
    return {
      text: `<div>${arg.valueText}%</div>`,
    };
  };

  return (
    <div className={styles.weeklyComp}>
      <Paper square elevation={0} className={classList.join(' ')}>
        {!loading ? (
          hasData ? (
            <>
              <h2>Weekly Comp</h2>
              <div className={styles.container}>
                <div className={styles.comp}>
                  <p>
                    <span>{currentComp}</span>
                    <span>Current Wk</span>
                  </p>
                </div>
                <div className={styles.chart}>
                  <Chart
                    id="weekly-comp"
                    key={redrawKey}
                    dataSource={chartData}
                    animation={animate}
                  >
                    <Size height={50} />
                    <CommonSeriesSettings
                      argumentField="arg"
                      type="spline"
                      hoverMode="allArgumentPoints"
                    />
                    <Series
                      type="splinearea"
                      valueField="comp"
                      color={lineColor}
                      name="comp"
                    >
                      <Label visible={false} />
                    </Series>

                    <ArgumentAxis>
                      <Tick visible={false} />
                      <Label visible={false} />
                    </ArgumentAxis>
                    <ValueAxis>
                      <Grid visible={false} />
                      <Label visible={false} />
                      <Tick visible={false} />
                    </ValueAxis>

                    <Tooltip
                      enabled={true}
                      location="edge"
                      customizeTooltip={customizeToolTip}
                    />
                    <Legend visible={false} />
                  </Chart>
                  <span>Previous {chartData.length.toString()} Wks</span>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.notFound}>
              <p>No data found.</p>
            </div>
          )
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner active={true} background={'clear'} />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default WeeklyComp;
