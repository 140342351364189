import { useSnacks } from 'auth';
import useDashboard, { DashboardActions } from 'hooks/useDashboard';
import useModal, { ModalActions } from 'hooks/useModal';
import useSyncedDashboardAPI from 'hooks/useSyncedDashboardAPI';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setIsCreating, setIsEditing } from 'store/slices/dashboardSlice';
import styles from '../../../styles/components/modals/content/confirm-delete.module.scss';

const ConfirmDelete = (): JSX.Element => {
  const dispatch = useDispatch();
  const dashboard: DashboardActions = useDashboard();
  const { openSnack } = useSnacks();
  const modal: ModalActions = useModal();
  const { currentDashboardId } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { deleteDashboard } = useSyncedDashboardAPI();

  /**
   * Set delete prop in dashboardState. This
   * confirms deletion of a dashboard layout.
   */
  async function handleConfirm(): Promise<void> {
    if (currentDashboardId === null) {
      return;
    }
    try {
      await deleteDashboard(currentDashboardId);
      openSnack('Dashboard deleted!', 'success');
      dispatch(setIsEditing(false));
      dispatch(setIsCreating(false));
      modal.close();
    } catch (e) {
      openSnack(
        'There was an error deleting the dashboard. If this issue persists, please contact your Silver Fern client partner.',
        'error'
      );
    }
  }

  /**
   * Cancel and close modal.
   */
  const handleCancel = useCallback(() => {
    modal.close();
  }, [modal]);

  return (
    <div className={styles.confirmDelete}>
      <h2 className={styles.title}>Delete Dashboard?</h2>

      <div className={styles.buttonContainer}>
        <button onClick={handleConfirm}>Confirm</button>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};
export default ConfirmDelete;
